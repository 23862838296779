import { ConfigureUI } from '@/configure/ConfigureUI';
import { FCDropdown } from '@/configure/FCDropdown';
import { t } from '@/i18n';
import { changeFocusable, makeFocusableAll, removeFocusable } from '@/utils/accessibility';
import { createEventRemovalFn } from '@/utils/browser';
import { mq } from '@/utils/MediaQuery';
// import { createPrintButton } from './print';
import { showErrorDialog } from './dialog';

// const PARENT_CONTAINER = '.configure-container';

const SNAPSHOTS_CONTAINER = '.configure-snapshots';

export async function createMoreActionsMenu(configure: ConfigureUI): Promise<void> {
  // Show the menu when ConfigureUI finishes loading
  configure.on('analytics:configureLoaded', () => showMoreActionsButton(configure));

  const fcDropdown = FCDropdown().init(configure.dom.querySelector<HTMLElement>('[data-fc-dropdown-container]'));
  await Promise.all([
    // createShareButton(configure),
    createStartOverButton(configure, fcDropdown, '.configure-reset-recipe-container', mq.getQuery('greater-than-md')),
    createStartOverButton(configure, fcDropdown, '.configure-out-reset-recipe-container', mq.getQuery('lower-than-md')),
    //createRandomizeButton(configure),
    //createPrintButton(configure, 'Print'),
    createSnapshots(configure, fcDropdown)
  ]);
  fcDropdown.element.addEventListener('fcdropdown:opened', function (event: Event & { detail: { level: number } }) {
    if (event.detail.level !== 0) return;
    console.log('fcdropdown:opened', event.target);
    const dropdown = fcDropdown.element as HTMLElement;
    const close: HTMLElement | null = dropdown.querySelector('button.fc-dropdown-active');
    close?.focus();

    changeFocusable('.fc-snapshots-take-entry-icon', '.fc-snapshots-take-wrapper', configure, dropdown);
    changeFocusable('.fc-snapshots-view-button', '.fc-snapshots-view-wrapper', configure, dropdown);
    changeFocusable(
      '.configure-reset-recipe-container .fc-button-pair',
      '.configure-reset-recipe-container',
      configure,
      dropdown
    );
  } as EventListener);
}

function createStartOverButton(configure: ConfigureUI, fcDropdown: FCDropdown, container: string, mediaQuery?: string) {
  return configure.createComponent({
    type: 'button',
    container,
    mediaQuery,
    title: 'Start Over',
    tabIndex: 0,
    async onClick() {
      const confirmDialog = await configure.createDialog({
        type: 'confirmDialog',
        title: t('start_over'),
        text: t('start_over_desc'),
        showClose: true,
        uiSettings: {
          i18n: {
            okButtonLabel: `${t('yes')}, ${t('start_over')}`,
            cancelButtonLabel: t('cancel')
          }
        }
      });
      // close More Actions Panel if Reset dialog is opened
      confirmDialog.on('dialog:opened', () => fcDropdown?.closeActive(0));

      confirmDialog.on('dialog:ok', function () {
        configure.resetRecipe().catch((e) => {
          throw e;
        });
      });
    }
  });
}

// Adidas doesn't use randomize feature
// function createRandomizeButton(configure: ConfigureUI) {
//   return configure.createComponent({
//     type: 'randomizeRecipeButton',
//     container: '.configure-randomize-recipe-button'
//   });
// }

// Adidas doesn't use our share button, they have their own button
// async function createShareButton(configure: ConfigureUI) {
//   await configure.createComponents({
//     container: PARENT_CONTAINER,
//     components: [
//       {
//         type: 'button',
//         container: '.configure-share-button-container',
//         title: 'Share',
//         tabIndex: 0,
//         uiSettings: {
//           buttonTooltip: {
//             content: 'Share',
//             position: 'top'
//           }
//         }
//       }
//     ]
//   });
// }

async function createSnapshots(configure: ConfigureUI, fcDropdown: FCDropdown): Promise<void> {
  const btnUUID = `fc-adi-snapshot-${Date.now()}`;

  const snapshots = await configure.createComponent({
    type: 'snapshots',
    container: SNAPSHOTS_CONTAINER,
    uiSettings: {
      snapshots: {
        i18n: {
          takeButtonLabel: t('ma_view_snapshots'),
          viewButtonLabel: t('ma_view_snapshots'),
          hideButtonLabel: t('ma_view_snapshots'),
          headerText: t('ma_capture_design'),
          headerInstructionsText: t('ma_capture_design'),
          callToActionContent: /*html*/ `<a href="#" id="${btnUUID}" class="fc-outline-target" tabindex="0">
            ${t('ma_take_a_snapshot')} `
        }
      }
    }
  });

  // Find the Snapshots menu container and add the event listener
  // The btn is recreated every time, so it's better to add the listener to the container
  const snapshotsContainer: HTMLElement | null = configure.dom.querySelector(SNAPSHOTS_CONTAINER);
  snapshotsContainer?.addEventListener('click', saveSnapshotHandler);

  // Remove the listener on destroy
  configure.once('destroy:start', createEventRemovalFn(snapshotsContainer, 'click', saveSnapshotHandler));

  /** Handles "Save snapshot" on the menu */
  async function saveSnapshotHandler(e: Event) {
    // Only continue if the actual target is the button
    if ((e.target as HTMLElement).id !== btnUUID) return;
    e.preventDefault();

    try {
      await configure.saveSnapshot();
    } catch (err) {
      void showErrorDialog(configure, t('error'), t('err_add_snapshot'));
      console.error('Error while saving snapshot:', (err as Error).message);
    }
  }

  if (fcDropdown) {
    // close dropdown on the same level if snapshots was opened
    snapshots.on('snapshots:open', function (err) {
      if (err) throw err;

      const takeSnapshot: HTMLElement | undefined | null = snapshotsContainer?.querySelector(
        '.fc-snapshots-call-to-action-wrapper a'
      );

      takeSnapshot?.focus();

      removeFocusable('.fc-snapshots-list', configure, snapshotsContainer);
      makeFocusableAll('.fc-snapshots-entry', configure, snapshotsContainer);
      fcDropdown.closeActive(1);
    });

    // close snapshots if parent dropdown was closed
    fcDropdown.element.addEventListener('fcdropdown:activeClosed', function (event: CustomEvent<{ level: number }>) {
      if (event.detail.level === 0) snapshots.trigger('snapshots:close');
    } as EventListener);

    // close snapshots if dropdown on the same lvl was opened
    fcDropdown.element.addEventListener('fcdropdown:opened', function (event: Event & { detail: { level: number } }) {
      if (event.detail.level === 1) snapshots.trigger('snapshots:close');
    } as EventListener);
  }
}

/**
 * Shows the "More Actions" button below the product display (snapshots, sharing, start over, etc)
 */
function showMoreActionsButton(configure: ConfigureUI) {
  const moreActionsElement = configure.dom.querySelector<HTMLElement>('.configure-dropdown-menu');
  if (moreActionsElement) moreActionsElement.style.display = 'block';
}
