import { DisplayWebGlEventBus } from '@/configure/components/webgl';
import { ConfigureUI } from '@/configure/ConfigureUI';
import { ConfigureAdidas } from '@/ConfigureAdidas';
import { isEnterOrSpace } from '@/utils/accessibility';
import { initDisplayLoader, setDisplayLoaderProgress } from './webgl-display-loader';

const LOADER_FINISHED_CLASS = 'loaded';

export async function createWebGLDisplay(
  configure: ConfigureUI,
  wrapper: ConfigureAdidas,
  containerSelector: string
): Promise<void> {
  try {
    const container = configure.dom.querySelector<HTMLElement>(containerSelector);
    if (!container) throw new Error(`Display Container "${containerSelector}" not found`);

    initDisplayLoader(configure);

    configure.dom.container.classList.add('is-webgl');
    const displayWebgl = await configure.createComponent({
      type: 'displayWebgl',
      container,
      webglOverrides: {
        // If the performance flag is set, skip the initial animation
        controls: { animateOnInitialModelLoad: wrapper.params.performance !== true }
      }
    });

    configure.setDisplay(displayWebgl);
    initDisplayWebGL(configure, displayWebgl, container);
  } catch (err) {
    configure.dom.container.classList.remove('is-webgl');
    throw err;
  }
}

function initDisplayWebGL(configure: ConfigureUI, displayWebgl: DisplayWebGlEventBus, displayContainer: HTMLElement) {
  // Remove listeners on destroy
  displayWebgl.once('display:webgl:destroy', () => displayWebgl.off());

  displayWebgl.on('display:webgl:load-progress', function (progress) {
    setDisplayLoaderProgress(configure, progress);
    if (progress === 100) {
      //setAddToCartButtonStatus(configure, true);

      // Add the loaded class to fade in the product model
      displayContainer.classList.add(LOADER_FINISHED_CLASS);

      window.dispatchEvent(new Event('resize'));
    } else {
      //setAddToCartButtonStatus(configure, false);
    }
  });

  addARSupport(configure, displayWebgl);

  addFullScreenSupport(configure, displayWebgl);
}

function addARSupport(configure: ConfigureUI, displayWebgl: DisplayWebGlEventBus) {
  let arLink: HTMLAnchorElement | undefined;
  displayWebgl.on('display:webgl:usdzModelExportReady', function (fileURL) {
    if (!arLink) {
      arLink = document.createElement('a');
      const img = document.createElement('img');
      configure.dom.container.append(arLink);
      img.src = "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'/>";
      arLink.rel = 'ar';
      arLink.download = 'model.usdz';
      arLink.style.display = 'none';
      arLink.appendChild(img);
    }
    arLink.href = fileURL;
    arLink.click();
  });

  const arButton: HTMLButtonElement | null = configure.dom.querySelector('.configure-ar-btn');
  if (!arButton) return;

  const arSupport = window.location.href.indexOf('ar=true') > 0 && false; //isARSupported();
  if (arSupport) {
    configure.dom.addEventListener('.configure-ar-btn', 'click', () =>
      displayWebgl.trigger('display:webgl:exportToUsdz')
    );
  } else {
    arButton.style.display = 'none';
  }
}

function addFullScreenSupport(configure: ConfigureUI, displayWebgl: DisplayWebGlEventBus) {
  configure.dom.addEventListener('.configure-full-screen-btn', 'click', toggleFullScreen);
  configure.dom.addEventListener('.configure-full-screen-btn', 'keydown', (e: KeyboardEvent) => {
    if (isEnterOrSpace(e)) {
      toggleFullScreen();
    }
  });

  function handleEscFullScreen(e: KeyboardEvent) {
    if (e.key === 'Escape') toggleFullScreen();
  }

  function toggleFullScreen() {
    const containerWrapper = configure.dom.querySelector('.configure-container-wrap');
    if (!containerWrapper) return;

    containerWrapper.classList.toggle('full-screen-on');
    if (containerWrapper.classList.contains('full-screen-on')) {
      document.addEventListener('keydown', handleEscFullScreen);
    } else {
      document.removeEventListener('keydown', handleEscFullScreen);
    }
    displayWebgl.trigger('display:webgl:resizeCanvas');
  }
}
