import { ConfigureUI } from '@/configure/ConfigureUI';
import { findAndAddAttributeSets } from '@/configure/extension/attribute_set';
import { AttributeSet, AttributeSetOpts } from '@/configure/extension/attribute_set/AttributeSet';
import { ConfigureAttribute } from '@/configure/model/ConfigureAttribute';
import { isFalseAV, isTrueAV } from '../boolean-avs';

/** Configuration of the AttributeSets */
const ATTRIBUTE_SET_CONFIG: AttributeSetOpts = {
  aliasPrefix: 'p13n',
  parentInfo: { name: 'toggle', isActive: isTrueAV, isInactive: isFalseAV }
};

/**
 * Stores the configure Attribute Sets, so other parts of the application can check
 * if a CA is part of one of the sets.
 *
 * **NOTE: this doesn't support 2 instances at the same time**
 */
let attributeSets: AttributeSet[] = [];

/**
 * Implements the "attribute sets" feature.
 *
 * This feature allows multiple separate CAs to work as a set:
 * The product will contain **N** attributes with the same prefix and structure and they can be completed in
 * order (first [attr]_1, then [attr]_2 and so on).
 *
 * When one is removed, all the following attributes in the set are "moved up" one position, so no "holes" are
 * created in the set.
 *
 * @param configure ConfigureUI instance
 */
export function implementAttributeSets(configure: ConfigureUI): void {
  attributeSets = findAndAddAttributeSets(configure, ATTRIBUTE_SET_CONFIG);
  if (attributeSets.length > 0) {
    console.log('[Attribute Sets] Found the following sets: ' + attributeSets.map((as) => as.name).toString());
  }

  // Just in case we clear the list when the instance is destroyed
  configure.once('destroy:start', () => {
    console.log('[Attribute Sets] Clearing the attributeSets list');
    attributeSets = [];
  });
}

/**
 * Indicates whether the provided CA is part of any of the configured Attribute Sets.
 *
 * Useful to prevent other logic from conflicting with the AttributeSet,
 * that "moves up" items when previous ones are removed.
 */
export function isPartOfAttributeSet(ca: ConfigureAttribute): boolean {
  return attributeSets.some((set) => set.isPart(ca));
}
