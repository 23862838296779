/* eslint-disable */
/**
 * Creates a nested accordion component that displays attribute selectors in expandable/collapsible panels and grouped by attribute group data:
 *
 * > Group 1
 * > Group 2
 * > Group 3
 *  > Attribute 1
 *  > Attribute 2
 *    > details
 *  > Attribute 3
 * > Group 4
 *
 * This component creates the next component into the dom.
 *
 * <div class="fc-nested-accordion-container">
 *   <div class="fc-nested-accordion-header"></div>
 *   <div class="fc-nested-accordion-body"></div>
 *   <div class="fc-nested-accordion-footer"></div>
 * </div>
 *
 * After creating the previous element, creates a configure ui accordion into fc-nested-accordion-body end the groups buttons in fc-nested-accordion-header.
 *
 * <div class="fc-nested-accordion-container">
 *   <div class="fc-nested-accordion-header"></div>
 *     <div class="fc-grouper">Group 1</div>
 *     <div class="fc-grouper">Group 2</div>
 *     <div class="fc-grouper">Group 3</div>
 *     <div class="fc-grouper">Group ...</div>
 *     <div class="fc-grouper">Group N</div>
 *   <div class="fc-nested-accordion-body">
 *     <fc-accordion/> // Configure UI accordion
 *   </div>
 *   <div class="fc-nested-accordion-footer"></div>
 * </div>
 *
 * Every time user clicks on a grouper, all the attributes (fc-accordion-panel) into fc-accordion than belongs to that grouper are displayed.
 * The rest of the attributes are hidden.
 *
 * If the clicked grouper are into fc-nested-accordion-header, all the grouper placed below of that are moved to fc-nested-accordion-footer.
 *
 *  For example, if user clicks on Group 2, Group 3 to Group N are moved to fc-nested-accordion-footer:
 *
 *  <div class="fc-nested-accordion-container">
 *    <div class="fc-nested-accordion-header"></div>
 *      <div class="fc-grouper">Group 1</div>
 *      <div class="fc-grouper">Group 2</div>
 *    <div class="fc-nested-accordion-body">
 *      <fc-accordion/> // Configure UI accordion
 *    </div>
 *    <div class="fc-nested-accordion-footer">
 *      <div class="fc-grouper">Group 3</div>
 *      <div class="fc-grouper">Group 4</div>
 *      <div class="fc-grouper">Group ...</div>
 *      <div class="fc-grouper">Group N</div>
 *    </div>
 *  </div>
 *
 *  After doing that, fc-nested-accordion-body is expanded.
 *
 * If the clicked grouper are into fc-nested-accordion-footer, the clicked grouped and all the groupers above it are moved to fc-nested-accordion-header.
 *
 * From the previous example, if user click on Group 4, which is placed on footer, Group 3 and Group 4 are moved from footer to header:
 *
 *  <div class="fc-nested-accordion-container">
 *    <div class="fc-nested-accordion-header"></div>
 *      <div class="fc-grouper">Group 1</div>
 *      <div class="fc-grouper">Group 2</div>
 *      <div class="fc-grouper">Group 3</div>
 *      <div class="fc-grouper">Group 4</div>
 *    <div class="fc-nested-accordion-body">
 *      <fc-accordion/> // Configure UI accordion
 *    </div>
 *    <div class="fc-nested-accordion-footer">
 *      <div class="fc-grouper">Group ...</div>
 *      <div class="fc-grouper">Group N</div>
 *    </div>
 *  </div>
 *
 */

import { Embeddable, Responsive } from '@/configure/components';
import { AccordionEventBus, AccordionOptions } from '@/configure/components/accordion';
import { ConfigureUI } from '@/configure/ConfigureUI';
import { EventBusWrapper } from '@/configure/event/bus/EventBusWrapper';
import { AttributeGroup } from '@/configure/model/AttributeGroup';
import { ConfigureAttribute } from '@/configure/model/ConfigureAttribute';
import { arraysHaveSameValues, sum } from '@/configure/utils/array';
import { sleep } from '@/configure/utils/general';
import { hide, show, slideDown, slideUp } from '@/configure/utils/transitions';
import { Callback } from '@/configure/utils/types';
import { isEnterOrSpace } from '@/utils/accessibility';
import { waitForDom } from '@/utils/dom';

const FC_NESTED_PREFIX = 'fc-nested-accordion';
const FC_NESTED_CONTAINER = FC_NESTED_PREFIX + '-container';
const FC_NESTED_HEADER = FC_NESTED_PREFIX + '-header';
const FC_NESTED_FOOTER = FC_NESTED_PREFIX + '-footer';
const FC_NESTED_BODY = FC_NESTED_PREFIX + '-body';
const FC_GROUPER = 'fc-grouper';
const FC_ACCORDION_PANEL = 'fc-accordion-panel';

/**
 * Creates a nested accordion component that displays attribute selectors in expandable/collapsible panels and grouped by attribute group data.
 *
 * @param configure instance of ConfigureUI
 * @param opts Component creation parameters (including container and MediaQuery filter)
 */
export async function createNestedAccordion(
  configure: ConfigureUI,
  opts: AccordionOptions & Embeddable & Responsive
): Promise<AccordionEventBus> {
  const attributeGroups = configure.getProduct()?.attributeGroups;

  if (!attributeGroups || attributeGroups.length === 0) {
    console.warn('This product does not use attribute groups. Attribute group is necessary to build nested accordion.');
    return configure.createComponent({
      ...opts,
      type: 'accordion',
      nested: false
    });
  }

  const destroyHandlers: Callback[] = [];

  // On ConfigureUI destroy, clean up this component
  configure.once('destroy', () => {
    console.log('[Nested Accordion] Destroying...');
    for (const handler of destroyHandlers) handler();
  });

  // TODO:  Maybe, it could be done using 'created' or 'destroyed' of Configure Id.
  if (opts.mediaQuery) {
    const em: EventBusWrapper = new EventBusWrapper();

    const mediaQuery = window.matchMedia(opts.mediaQuery);

    const mqHandler = async (e: MediaQueryListEvent) => {
      if (e.matches) {
        em.delegate = await setup(configure, opts);
      } else {
        remove(configure, opts.container);
        em.delegate = null;
      }
    };

    mediaQuery.addEventListener('change', mqHandler);
    // Add handler to remove the listener
    destroyHandlers.push(() => mediaQuery.removeEventListener('change', mqHandler));

    if (mediaQuery.matches) {
      em.delegate = await setup(configure, opts);
    }

    return Promise.resolve(em.cast());
  } else {
    return setup(configure, opts);
  }
}

/**
 *
 * Remove nested accordion from dom.
 *
 * @param container
 */
function remove(configure: ConfigureUI, container: string | HTMLElement | null) {
  if (!container) {
    console.warn('Cannot remove nested accordion: container not found');
    return;
  }
  const accordionContainer: HTMLElement =
    container instanceof HTMLElement ? container : (configure.dom.querySelector(container) as HTMLElement);

  if (!accordionContainer) {
    console.warn('Cannot remove nested accordion: container %s not found', container?.toString());
    return;
  }
  accordionContainer.innerHTML = '';
}

/**
 * Setup nested accordion component.
 *
 * @param configure instance of ConfigureUI
 * @param opts Component creation parameters (including container and MediaQuery filter)
 */
async function setup(
  configure: ConfigureUI,
  opts: AccordionOptions & Embeddable & Responsive
): Promise<AccordionEventBus> {
  // Keep the active CA by group.
  const activeCaByGroup: Record<string, number> = {};

  createWrapper(opts.container);

  // Creates the regular accordion (nested = false) using the specified parameters but in the wrapper container just created
  const eventBus = await configure.createComponent({
    ...opts,
    type: 'accordion',
    nested: false,
    container: `.${FC_NESTED_BODY}`
  });
  const ignore = opts.uiSettings?.groups?.ignore ?? [];

  const accordion: HTMLElement = await getAccordion();
  const items: HTMLElement[] = Array.from(accordion.children) as HTMLElement[];
  const attributeGroups = configure.getProduct()?.attributeGroups.filter((item) => {
    return !ignore.includes(item.name.toUpperCase());
  });

  if (!attributeGroups || !configure.getProduct()?.allAttributes) return eventBus;

  let renderedCAs = configure.getRenderedAttributes().map((ca) => ca.id);

  const groupedAttributes = configure.getAttributesByGroup();

  if (!groupedAttributes) return eventBus;

  await setGroupDataOnItems(groupedAttributes, accordion);

  items.forEach(hide);

  await createGroups(attributeGroups);

  // CA focus on accordion
  configure.on('ca:focus', onCAFocus);

  configure.on('recipe:change', () => handleRecipeChange());
  configure.on('recipe:loaded', () => handleRecipeChange());

  // If there's only one group, expand it automatically
  if (attributeGroups.length === 1) await expandGroup(0);

  return eventBus;

  /**
   * Expands an accordion group by index
   */
  async function expandGroup(groupIndex: number) {
    const nestedAccordion: HTMLElement = configure.dom.querySelector(`.${FC_NESTED_CONTAINER}`) as HTMLElement;
    const groupers: HTMLElement[] = Array.from(nestedAccordion.querySelectorAll(`.${FC_GROUPER}`));
    await sleep(200);
    groupers[groupIndex]?.dispatchEvent(new MouseEvent('click'));
  }

  /**
   * Update nested accordion when the visible CA changes.
   * @param modifications
   */
  async function handleRecipeChange() {
    await waitForDom();
    const newRenderedCAs = configure.getRenderedAttributes().map((ca) => ca.id);
    if (arraysHaveSameValues(renderedCAs, newRenderedCAs)) return;

    renderedCAs = newRenderedCAs;

    // Update the group data on CA items.
    setGroupDataOnItems(groupedAttributes, accordion);

    // Update active group regarding to the new visible CA
    const grouper = configure.dom.querySelector<HTMLElement>(`.${FC_GROUPER}.active`);

    // Get the active group id (or undefined if no group is active)
    const activeGroup = grouper?.dataset['group'];

    // Make the items from the group visible and the others invisible
    // This will show or hide new items according to their group
    updateVisibility(activeGroup);

    for (const group in activeCaByGroup) {
      // If current active ca is not visible, it is update in activeCaByGroup
      if (activeCaByGroup[group] && !renderedCAs.includes(activeCaByGroup[group])) {
        if (activeGroup === group.toString() && configure.getActiveCA()) {
          activeCaByGroup[group] = configure.getActiveCA()?.id as number;
        } else {
          delete activeCaByGroup[group];
        }
      }
    }
  }

  /**
   * Expand the attributes clicked on WebGL product
   *
   * @param caId Clicked Attribute id
   */
  async function onCAFocus(data: { caId: number }) {
    const accordion: HTMLElement = await getAccordion();
    // Get the active panel
    const panel = accordion.querySelector(`.${FC_ACCORDION_PANEL}[data-ca="${data.caId}"]`);
    if (!panel) return;
    // Get the groupId from fc-accordion-panel
    const groupId = panel.getAttribute('data-group');
    //Get the grouper corresponding to that groupId
    const accordionContainer: HTMLElement = configure.dom.querySelector(`.${FC_NESTED_CONTAINER}`) as HTMLElement;
    const grouper = accordionContainer.querySelector(`.${FC_GROUPER}[data-group="${groupId}"]`) as HTMLElement;
    if (!grouper.classList.contains('active')) {
      // If Grouper is not opened, it will be displayed
      grouper.dispatchEvent(new Event('click'));
    }
    if (groupId) activeCaByGroup[groupId] = data.caId;
  }

  /**
   * Created the dom wrapper into container
   *
   * @param container Dom component where nested accordion is created
   */
  function createWrapper(container: string | HTMLElement | null) {
    if (!container) {
      console.warn('Cannot create nested accordion wrapper: container not found');
      return;
    }

    const accordionContainer: HTMLElement =
      container instanceof HTMLElement ? container : (configure.dom.querySelector(container) as HTMLElement);

    if (!accordionContainer) {
      console.warn('Cannot remove nested accordion: container %s not found', container?.toString());
      return;
    }

    accordionContainer.innerHTML = `
   <div class="${FC_NESTED_CONTAINER}">
     <div class="${FC_NESTED_HEADER}"></div>
     <div class="${FC_NESTED_BODY}"></div>
     <div class="${FC_NESTED_FOOTER}"></div>
   </div>`;
  }

  /**
   * Every AC in Configure UI accordion is displayed into a fc-accordion-panel html element.
   *
   * This method iterates every fc-accordion-panel and set the groupId into data-group
   * attribute. data-group is used for identify the group than belong the CA.
   *
   * @param groupedAttributes CA grouped by CA groups
   * @param accordion Configure UI accordion instance
   */
  async function setGroupDataOnItems(
    groupedAttributes: Map<number, Array<ConfigureAttribute | undefined>>,
    accordion: HTMLElement
  ): Promise<void> {
    for (const ca_id of groupedAttributes.keys()) {
      const cas = groupedAttributes.get(ca_id);

      if (cas) {
        for (const ca of cas) {
          if (ca) {
            const item = accordion.querySelector('.fc-accordion-panel-ca-' + CSS.escape(ca.alias));
            item?.setAttribute('data-group', ca_id.toString());
            item?.setAttribute('data-ca', ca.id.toString());
          }
        }
      }
    }
  }

  /**
   *
   * Return the accordion html element into the dom.
   *
   * @returns
   */
  async function getAccordion(): Promise<HTMLElement> {
    const accordion: HTMLElement | null = configure.dom.querySelector('.fc-accordion');
    if (accordion === null) {
      // If the accordion have't been created yet, wait for a moment and ask for the instance again.
      await sleep(0);
      return getAccordion();
    }
    return accordion;
  }

  /**
   * Creates the groups html buttons into header
   *
   * @param attributeGroups
   * @returns
   */
  function createGroups(attributeGroups: AttributeGroup[]) {
    const i18n = opts.uiSettings?.groups?.i18n;
    const header: HTMLElement | null = configure.dom.querySelector(`.${FC_NESTED_HEADER}`);
    if (!header) return;

    attributeGroups.forEach((ag: AttributeGroup, order) => {
      header.insertAdjacentHTML('beforeend', generateGrouperHtml(ag, order, i18n));
    });
    addToggleListeners();
  }

  /**
   * Add event listeners to group buttons
   */
  function addToggleListeners() {
    const nestedAccordion: HTMLElement = configure.dom.querySelector(`.${FC_NESTED_CONTAINER}`) as HTMLElement;
    const groupers: HTMLElement[] = Array.from(nestedAccordion.querySelectorAll(`.${FC_GROUPER}`));
    groupers.forEach((g) => {
      g.addEventListener('click', toggleGroup);
      g.addEventListener('keydown', onKeyDown);
    });
  }

  /**
   * Open the group on keyboard enter
   *
   * @param e
   */
  function onKeyDown(e: KeyboardEvent) {
    if (isEnterOrSpace(e)) {
      e.preventDefault();
      toggleGroup(e);
    }
  }

  /**
   * Remove event listeners from group buttons
   */
  function removeToggleListeners() {
    const nestedAccordion: HTMLElement = configure.dom.querySelector(`.${FC_NESTED_CONTAINER}`) as HTMLElement;
    const groupers: HTMLElement[] = Array.from(nestedAccordion.querySelectorAll(`.${FC_GROUPER}`));
    groupers.forEach((g) => {
      g.removeEventListener('click', toggleGroup);
      g.removeEventListener('keydown', onKeyDown);
    });
  }

  /**
   * Displays the clicked group.
   *
   * Every time user clicks on a grouper, all the attributes (fc-accordion-panel) into fc-accordion than belongs to that grouper are displayed.
   * The rest of the attributes are hidden.
   *
   * If the clicked grouper are into fc-nested-accordion-header, all the grouper placed below of that are moved to fc-nested-accordion-footer.
   * If the clicked grouper are into fc-nested-accordion-footer, the clicked grouped and all the groupers above it are moved to fc-nested-accordion-header.
   *
   * @param event
   * @returns
   */
  async function toggleGroup(event: Event): Promise<void> {
    event.stopPropagation();
    const accordion = await getAccordion();
    const target: HTMLElement = (event.currentTarget || event.target) as HTMLElement; //Current grouper
    const groupId = target.getAttribute('data-group'); //Get the group id
    const sOrder = target.getAttribute('data-order'); // Get the group order
    const header = configure.dom.querySelector(`.${FC_NESTED_HEADER}`);
    const footer = configure.dom.querySelector(`.${FC_NESTED_FOOTER}`);
    const container: HTMLElement = configure.dom.querySelector(`.${FC_NESTED_BODY}`) as HTMLElement;
    if (!groupId || !sOrder || !header || !footer || !container) return;

    removeToggleListeners();

    if (container.classList.contains('active')) {
      // Hide configure ui accordion accordion
      container.classList.remove('active');
      await slideUp(container);
    }

    const order = Number(sOrder);
    const hGroupers: HTMLElement[] = Array.from(header.querySelectorAll(`.${FC_GROUPER}`));
    const fGroupers: HTMLElement[] = Array.from(footer.querySelectorAll(`.${FC_GROUPER}`));

    const isTargetActive = target.classList.contains('active');

    if (isTargetActive) {
      // If clicked group was active, hides all items and move all the groupers from footer to header.
      target.classList.remove('active');
      fGroupers.forEach((current) => {
        footer.removeChild(current);
        header.appendChild(current);
      });
      const hiddenItems: HTMLElement[] = Array.from(accordion.querySelectorAll(`.fc-accordion-panel`));
      hiddenItems.forEach(hide);
    } else {
      // If clicked group wasn't active, disable the past active group.
      header.querySelector('.active')?.classList.remove('active');
      target.classList.add('active');
      if (hGroupers.includes(target)) {
        // If target grouper is in the header, move the
        // groupers under target to the footer.
        for (let i = order + 1; i < hGroupers.length; i++) {
          const node = hGroupers[i];
          if (node) {
            header.removeChild(node);
            footer.appendChild(node);
          }
        }
      } else {
        // If target grouper is in the footer, move the
        // groupers above target and the target to the header.
        let i = 0;
        do {
          const node = fGroupers[i];
          if (node) {
            footer.removeChild(node);
            header.appendChild(node);
          }

          if (fGroupers[i] === target) {
            break;
          }
          i++;
        } while (true); /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
      }

      const { visibleItems } = updateVisibility(groupId);

      // Display the accordion
      container.classList.add('active');
      if (visibleItems.length > 0) openActiveCA(groupId, visibleItems[0]?.getAttribute('data-ca')!);
      await slideDown(container, sum(visibleItems, 'clientHeight'));
    }
    addToggleListeners();
    target.focus();
  }

  /**
   * Makes all the accordion items from the provided group visible and hides the ones that don't
   * belong to that group.
   * If no group is passed, all items are hidden (group "-1" doesnt exist)
   */
  function updateVisibility(activeGroupId: string = '-1'): { visibleItems: HTMLElement[]; hiddenItems: HTMLElement[] } {
    //Show all the CA than belong to the selected group
    const visibleItems: HTMLElement[] = Array.from(
      accordion.querySelectorAll(`.fc-accordion-panel[data-group="${activeGroupId}"]`)
    );
    visibleItems.forEach(show);

    // Hide all the CA than don't belong to the selected group
    const hiddenItems: HTMLElement[] = Array.from(
      accordion.querySelectorAll(`.fc-accordion-panel:not([data-group="${activeGroupId}"])`)
    );
    hiddenItems.forEach(hide);

    return { visibleItems, hiddenItems };
  }

  /**
   * Open the active CA for the group.
   *
   * @param groupId
   * @param defaultCA
   */
  function openActiveCA(groupId: string, defaultCA: string | null) {
    // Get the active CA for the current group
    let activeCa = activeCaByGroup[groupId];
    if (!activeCa) {
      // if not active CA, set the default CA into the group
      if (defaultCA) {
        // Set the active CA for the current group
        activeCa = Number(defaultCA);
        activeCaByGroup[groupId] = activeCa;
      }
    }
    // Open the accordion on CA
    if (activeCa) configure.openMenuOption(activeCa);
  }

  /**
   *
   * Creates the Grouper HTML template.
   *
   * @param ag
   * @param order
   * @returns
   */
  function generateGrouperHtml(
    ag: AttributeGroup,
    order: number,
    i18n: Record<string, Record<string, string>> = {}
  ): string {
    const groupKey = ag.name.toLowerCase();
    const groupAlias = i18n[groupKey]?.['alias'] ?? groupKey;

    return /*html*/ `
      <div class='${FC_GROUPER} ${FC_GROUPER}-${groupAlias} fc-outline-target'
        data-order='${order}' data-group='${ag.id}' data-alias='${groupAlias}' data-name='${ag.name}' tabindex="0">
        <div class='${FC_GROUPER}-row'>
          <div class='${FC_GROUPER}-icon'>
            <span class='${FC_GROUPER}-${groupAlias}-icon'></span>
          </div>
          <div class='${FC_GROUPER}-title'>${i18n[groupKey]?.['title'] ?? ag.name}</div>
          <div class='fc-grouper-min-icon'></div>
          <div class='fc-grouper-max-icon'></div>
        </div>
        <div class='${FC_GROUPER}-row'>
          <div class='${FC_GROUPER}-description'>${i18n[groupKey]?.['desc'] ?? ''}</div>
        </div>
      </div>
    `;
  }
}
