/**
 * DOM utils methods
 */
import { ConfigureUI } from '@/configure/ConfigureUI';
import { AttributeValue } from '@/configure/model/AttributeValue';
import { ConfigureAttribute } from '@/configure/model/ConfigureAttribute';
import { AttributeValuePair } from '@/configure/types/configureui-types';
import { sleep } from '@/configure/utils/general';
import { filterByTruthy } from '@/utils/array';

interface UpdateDomForCAOpts {
  predicate: (ca: ConfigureAttribute) => boolean; // Test to be executed for every item recursively
  update: (ca: ConfigureAttribute, av?: AttributeValue) => void; //Executed when predicate is true
  useFullRecipe?: boolean;
  evaluateSubAttributes?: boolean; // Predicate is evaluated recursively for every subAttribute
}

/**
 * Execute `update` method every time `predicate` is true for a given CA and it's subAttributes.
 *
 * @param configure
 * @param opts
 */
export function updateDOMForCA(configure: ConfigureUI, opts: UpdateDomForCAOpts): void {
  // CA focus on accordion or pager
  configure.on('ca:focus', (data) => onCAFocus(data.caId));

  // Recipe load or change
  configure.on('recipe:loaded', handleRecipeChange);
  configure.on('recipe:change', (changes) =>
    handleRecipeChange(opts.useFullRecipe ? configure.getRecipe('json') : changes)
  );

  // Update upcharge on mediaChange
  configure.on('mediaQuery:change', () => {
    const activeCA = configure.getActiveCA();
    if (activeCA) void onCAFocus(activeCA.id);
  });

  /**
   * Get the ca on focus and invoke update
   *
   * @param caId
   * @returns
   */
  async function onCAFocus(caId: number): Promise<void> {
    const ca = configure.getAttribute({ id: caId });
    if (!ca) return;
    await waitForDom();
    update(ca, ca.values.find(filterByTruthy('selected')));
  }

  /**
   * Handles the recipe load or change.
   * Checks if any of the changes include a CA, invoke updates if it exists.
   *
   * @param modifications
   */
  async function handleRecipeChange(modifications: AttributeValuePair[]) {
    await waitForDom();
    modifications.forEach(({ ca, av }) => {
      const fullCA = configure.getAttribute({ id: ca.id });
      if (!fullCA) return;
      update(fullCA, av);
    });
  }

  /**
   * Invoke predicate only when predicate return true.
   *
   * If evaluateSubAttributes is enabled, it will execute the update recursevily for every subAttribute
   * @param ca
   * @param av
   * @returns
   */
  function update(ca: ConfigureAttribute, av: AttributeValue | undefined) {
    if (opts.predicate(ca)) opts.update(ca, av);

    if (!ca.subAttributes || !opts.evaluateSubAttributes) return;
    ca.subAttributes?.forEach((ca: ConfigureAttribute) => update(ca, ca.values.find(filterByTruthy('selected'))));
  }
}

/**
 * Wait for DOM changes to be applied before continuing.
 *
 * This is useful for example when querying or modifying elements created by React.
 */
export async function waitForDom(): Promise<void> {
  await sleep(0);
}
