import { AttributeGroup } from '@/configure/model/AttributeGroup';
import { filterByTruthy } from '@/utils/array';
import {
  ComponentEventBus,
  ComponentOptions,
  ConfigureComponentEventBus,
  ConfigureComponentOptions,
  ConfigureComponents,
  Embeddable,
  Responsive,
  Typable
} from './components';
import { ComponentManager } from './components/ComponentManager';
import {
  ConfirmDialogEventBus,
  ConfirmDialogOptions,
  DialogEventBus,
  DialogOptions,
  HtmlOptions
} from './components/dialog';
import { DisplayWebGlEventBus } from './components/webgl';
import { newConfigureInstance } from './configure-factory';
import { ConfigureEventMap, ConfigureUIEvent } from './event';
import { EventManager } from './event/EventManager';
import { eventToPromise } from './event/bus/promise';
import { BasicEventBus } from './event/bus/types';
import { DomManager } from './extension/DomManager';
import { HookManager } from './extension/hooks/HookManager';
import { HookOpts } from './extension/hooks/HookOpts';
import { ConfigureHooks, HooksMap, HooksWithAttributeMap } from './extension/hooks/hook-types';
import { AttributeValue } from './model/AttributeValue';
import { ConfigureAttribute, getInitialRecipeItemsForCA } from './model/ConfigureAttribute';
import { ConfigureProduct } from './model/ConfigureProduct';
import {
  AddToCartInfo,
  AddToCartOptions,
  AttributeValuePair,
  ConfigureAnalyticsCustomEventData,
  ConfigureAnalyticsEvent,
  ConfigureFCParams,
  ConfigureInitParams,
  ConfigurePreferences,
  CreateComponentsOptions,
  FormatPriceOptions,
  GetTemplateOptions,
  GetTemplateOptionsResult,
  GetUpchargesOptions,
  MediaQueryOptions,
  PrintElementOptions,
  ProductFacetsDetails,
  RecipeFormat,
  SaveRecipeOptions,
  SaveRecipeResult,
  SaveSnapshotResult,
  SelectValueOptions,
  SetRecipeOptions,
  SetUgcImageOptions,
  UISettings
} from './types/configureui-types';
import { promisify } from './utils/promise';
import { GenericCallback } from './utils/types';
import { ConfigureWebGL } from './webgl';

const DEFAULT_ID = 'default';

export class ConfigureUI {
  /** ID of the instance */
  id: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  #configureApi: any;
  #apiReady: Promise<void>;
  #domReady!: Promise<void>;
  #modelReady!: Promise<void>;
  #webglReady!: Promise<void>;
  #displayReady!: Promise<void>;
  queryParams: Partial<ConfigureInitParams> = {};
  #hookManager: HookManager;
  #eventManager: EventManager;
  #domManager: DomManager;
  #componentManager: ComponentManager;
  #destroying: boolean = false;

  #activeCA?: ConfigureAttribute;

  constructor(params: ConfigureInitParams) {
    this.id = params.id ?? DEFAULT_ID;

    // Creates the DOM Manager
    this.#domManager = new DomManager(params.container);

    if (params.api) {
      // If the ConfigureUI API is specified in the parameters, there's no need to initialize it
      this.#configureApi = params.api;
      this.#apiReady = Promise.resolve();
    } else {
      // No API specified, perform the initialization here
      this.#apiReady = this.buildApi(params);
    }

    this.#hookManager = new HookManager(this);
    this.#eventManager = new EventManager(this);
    this.#componentManager = new ComponentManager(this);

    // After initializing, add the required event listeners, hooks, etc
    void this.internalPostInit(params);
  }

  /** Manually set the API ready promise to resolved after handling the error */
  markAsReady(): void {
    this.#apiReady = Promise.resolve();
  }

  private async buildApi(params: ConfigureInitParams): Promise<void> {
    const { api, error } = await newConfigureInstance(params);

    // ConfigureUI API is always returned, even when there's an error
    // so promisify wont work in this case
    this.#configureApi = api;
    if (error) throw error;
  }

  /**
   * Access the real configure-ui api.
   *
   * **Warning: Use it only as a last resort**
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getApi(): any {
    return this.#configureApi;
  }

  /**
   * Methods that **MUST** be used to interact with the DOM in the context of the Configurator.
   * This includes, querying and adding event listeners
   */
  get dom(): Omit<DomManager, 'destroy'> {
    return this.#domManager;
  }

  get destroying(): boolean {
    if (this.#destroying) console.log('[ConfigureUI] destroy() called, aborting initialization');
    return this.#destroying;
  }

  /**
   * Internal initialization (adding event listeners and hooks, modifying properties)
   */
  private async internalPostInit(params: ConfigureInitParams) {
    // Promise that resolves to the ConfigureUI API when it's loaded
    const apiPromise = this.#apiReady.then(() => this.getApi());

    if (this.destroying) return;

    // These promises need to be created sync, but will wait for the API to be loaded and the appropiate event
    this.#domReady = eventToPromise(apiPromise, 'dom:ready');
    this.#modelReady = eventToPromise(apiPromise, 'model:ready');
    this.#webglReady = eventToPromise(apiPromise, 'webgl:ready');
    this.#displayReady = eventToPromise(apiPromise, 'display:ready');

    this.on('ca:focus', (data) => this.onCAFocus(data.caId));

    if (params.logEvents) {
      this.on('all', (...args) => console.debug('[ConfigureUI Events]', ...args));
    }

    // The following code needs the api to be loaded
    await apiPromise;

    if (this.destroying) return;

    // Trigger the API ready event
    this.trigger('api:ready');
  }

  /**
   * Sets this instance's product display.
   *
   * This allows to add lifecycle events that only the display can provide (model loading, ready, destroy finish)
   */
  setDisplay(display: DisplayWebGlEventBus): void {
    this.#eventManager.addDisplayLifecycleEvents(display);
  }

  private onCAFocus(caId: number): void {
    this.#activeCA = this.getAttribute({ id: caId });
  }

  /**
   * Cleans up this instance.
   *
   * @param apiLoaded if true, it will clean up everything including the ConfigureUI API.
   * Otherwise, only the parts that don't include calls to the API
   */
  private async cleanUp(apiLoaded: boolean) {
    console.log('[ConfigureUI] Cleaning up...');

    // Remove all custom DOM related modifications (event listeners, added nodes)
    const finishPromises = [this.#domManager.destroy()];

    if (apiLoaded) {
      // Remove all event listeners (API will have already removed them, but we have to clear the array of handlers)
      this.#eventManager.destroy();
      // Remove the hooks
      this.#hookManager.destroy();

      // Remove listeners from components eventBuses and trigger "destroy" on each
      finishPromises.push(this.#componentManager.destroy());
    }

    await Promise.all(finishPromises);
  }

  /**
   * Destroys this instance, cleaning up everything
   * @returns a promise that is resolved after the instance is destroyed
   */
  async destroy(): Promise<void> {
    this.#destroying = true;

    // If there was an error while initializing, there will be no API or the ready promise will have failed,
    // We only need to destroy the parts that don't use the ConfigureUI API
    try {
      if (!this.getApi()) throw new Error('ConfigureUI was not loaded');
      await this.ready('api');
    } catch {
      await this.cleanUp(false);
      console.log('[ConfigureUI] Destroy without API Finished');
      return;
    }

    // Promise that will resolve when the instance has finished being destroyed
    const destroyFinished = eventToPromise(this.getApi(), 'destroy:finish');

    // Listen to Configure "destroy"
    const destroy = eventToPromise(this.getApi(), 'destroy:start')
      // When done, cleanup the wrapper's state and wait for destroy:finish (display destroy)
      .then(() => {
        return Promise.all([this.cleanUp(true), destroyFinished]);
      });

    // Call API destroy
    console.log('[ConfigureUI] Destroy Started');
    this.#configureApi.run('destroy');

    // wait until the instance is FULLY destroyed (including wrapper's state and webgl display)
    await destroy;
    console.log('[ConfigureUI] Destroy Finished');
  }

  getFcParams(): ConfigureFCParams {
    return this.#configureApi.run('getFcParams') as ConfigureFCParams;
  }

  setUgcImage(options: SetUgcImageOptions): Promise<AttributeValuePair[]> {
    return promisify((cb) => this.#configureApi.run('setUgcImage', options, cb) as void);
  }

  removeUgcImage(options: SetUgcImageOptions): Promise<AttributeValuePair[]> {
    return promisify(
      (cb) => this.#configureApi.run('selectValue', { ca: options.ca, av: { clipArt: '' } }, cb) as void
    );
  }

  resetAttribute(ca: ConfigureAttribute): void {
    const defaultValues = getInitialRecipeItemsForCA(ca);
    if (defaultValues.length === 0) return;

    if (defaultValues.length === 1) {
      void this.selectValue(defaultValues[0]!);
    } else {
      void this.setRecipe(defaultValues);
    }
  }

  resetAttributes(cas: ConfigureAttribute[]): void {
    const recipeChanges = [];
    for (const ca of cas) recipeChanges.push(...getInitialRecipeItemsForCA(ca));
    if (recipeChanges.length > 0) void this.setRecipe(recipeChanges);
  }

  /**
   * This method returns the base configuration for the product and customer as it was published in the Fluid Retail admin.
   * @param path Config path
   * @returns
   */
  getConfig(path: string): Promise<string>;
  getConfig(): Promise<unknown>;
  getConfig(path?: string): Promise<string> {
    return promisify((cb) => this.#configureApi.run('getConfig', path, cb) as void);
  }

  get created(): boolean {
    return !!this.#configureApi;
  }

  /**
   * Returns a promise that resolves when the specified entity is loaded and ready to be used:
   * - `api`: ConfigureUI API is loaded. Components, hooks and listeners may be created.
   * - `dom`: All the implementation components and DOM have been created.
   * - `webgl`: WebGL Display has been initialized and the model will start loading
   * - `model`: Product model is displayed (load progress == 100). User interactions may begin.
   * - `display`: Product model is loaded and its initial animation is done.
   *
   *  ConfigureUI **CANNOT** be destroyed until the display is ready.
   */
  ready(entity: 'api' | 'dom' | 'model' | 'webgl' | 'display'): Promise<void> {
    switch (entity) {
      case 'api':
        return this.#apiReady;
      case 'dom':
        return this.#domReady;
      case 'model':
        return this.#modelReady;
      case 'webgl':
        return this.#webglReady;
      case 'display':
        return this.#displayReady;
      default:
        return Promise.reject(new Error(`${entity} is not a valid parameter`));
    }
  }

  isWebGl(): boolean {
    return this.#configureApi.run('isWebGl');
  }

  getUISettings(): UISettings {
    return this.#configureApi.run('getUiSettings');
  }

  getProduct(): ConfigureProduct | undefined {
    return this.#configureApi.run('getProduct');
  }

  getTemplateOptions(options: GetTemplateOptions): Promise<GetTemplateOptionsResult> {
    return promisify((cb) => this.#configureApi.run('getTemplateOptions', options, cb));
  }

  getUpcharges(options?: GetUpchargesOptions): Record<string, number> {
    return this.#configureApi.run('getUpcharges', options);
  }

  /**
   *
   * @returns Return the current active Configure Attribute
   */
  getActiveCA(): ConfigureAttribute | undefined {
    return this.#activeCA;
  }

  getRecipe(format: 'compact'): Record<string, string>;
  getRecipe(format: 'human'): Record<string, string>;
  getRecipe(format: 'custom' | 'json'): AttributeValuePair[];
  getRecipe(format: 'custom', attributeKey: string): Record<string, AttributeValue>;
  getRecipe(format: 'custom', attributeKey: string, valueAttribute: string): Record<string, string>;
  getRecipe(
    format?: RecipeFormat,
    data?: unknown,
    data2?: unknown
  ): Record<string, AttributeValue> | Record<string, string> | AttributeValuePair[] {
    return this.#configureApi.run('getRecipe', format, data, data2);
  }

  setRecipe(changes: SetRecipeOptions): Promise<AttributeValue[]> {
    return promisify((cb) => this.#configureApi.run('setRecipe', changes, cb));
  }

  setFallbackRecipe(changes: SetRecipeOptions): Promise<AttributeValue[]> {
    return promisify((cb) => this.#configureApi.run('setFallbackRecipe', changes, cb));
  }

  getAttributeOrThrow(options: { [P in keyof ConfigureAttribute]?: ConfigureAttribute[P] }): ConfigureAttribute {
    return this.#configureApi.run('getAttribute', options);
  }

  getAttribute(options: { [P in keyof ConfigureAttribute]?: ConfigureAttribute[P] }): ConfigureAttribute | undefined {
    try {
      return this.#configureApi.run('getAttribute', options);
    } catch (e) {
      // Detect error when CA is not found and return undefined instead
      if ((e as Error).message?.includes('could not find')) return undefined;
      throw e;
    }
  }

  getQuantity(): number {
    return this.#configureApi.run('getQuantity');
  }
  setQuantity(quantity: number): void {
    this.#configureApi.run('setQuantity', quantity);
  }

  formatPrice(value: number, options?: FormatPriceOptions): string {
    return this.#configureApi.run('formatPrice', value, options);
  }

  selectValue(attrValuePair: SelectValueOptions): Promise<AttributeValuePair[]> {
    return promisify((cb) => this.#configureApi.run('selectValue', attrValuePair, cb));
  }

  /* - - - - - - - - - - - - - - - - - - - - HOOKS  - - - - - - - - - - - - - - - - - - - - - - - */

  openMenuOption(caId: number): Promise<BasicEventBus> {
    return promisify((cb) => this.#configureApi.run('openMenuOption', { caId: caId }, cb));
  }

  registerHookForAttribute<T extends keyof HooksWithAttributeMap>(
    attributeAlias: string,
    hook: T,
    handler: HooksWithAttributeMap[T]
  ): void;
  registerHookForAttribute(attributeAlias: string, hook: keyof HooksWithAttributeMap, handler: GenericCallback): void {
    void this.#apiReady.then(() => this.#hookManager.addHook({ attributeAlias, hook, handler }));
  }

  registerHook<T extends ConfigureHooks>(hook: T, handler: HooksMap[T]): void;
  registerHook(hook: ConfigureHooks, handler: GenericCallback): void {
    void this.#apiReady.then(() => this.#hookManager.addHook({ hook, handler }));
  }
  registerHookWithOpts<T extends ConfigureHooks>(opts: HookOpts<T>): void {
    void this.#apiReady.then(() => this.#hookManager.addHook(opts));
  }

  registerMediaQueries(mqs: string[]): Promise<Record<string, ComponentEventBus>> {
    const mediaQueries: Record<string, MediaQueryOptions> = {};
    mqs.forEach((mq) => {
      const api = this.#configureApi;
      mediaQueries[mq] = {
        components: [],
        onChange(matches: boolean) {
          if (matches) api.trigger('mediaQuery:change', mq);
        }
      };
    });
    return this.createComponents({
      container: 'body',
      components: [],
      mediaQueries
    });
  }

  createComponent<T extends ConfigureComponents>(
    options: Typable<T> & ConfigureComponentOptions<T> & Embeddable & Responsive
  ): Promise<ConfigureComponentEventBus<T>>;
  createComponent(options: ComponentOptions & Embeddable & Responsive): Promise<ComponentEventBus> {
    return this.#componentManager.createComponent(options);
  }

  createComponents(options: CreateComponentsOptions): Promise<Record<string, ComponentEventBus>> {
    return this.#componentManager.createComponents(options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setComponentOptions(options: Record<string, any>): void {
    this.#configureApi.run('setComponentOptions', options);
  }

  async createDialog(options: DialogOptions & ConfirmDialogOptions): Promise<ConfirmDialogEventBus & DialogEventBus>;
  async createDialog(options: DialogOptions & HtmlOptions): Promise<DialogEventBus>;
  async createDialog(options: DialogOptions): Promise<ConfirmDialogEventBus & DialogEventBus> {
    const dialog: ConfirmDialogEventBus & DialogEventBus = await promisify((cb) =>
      this.#configureApi.run('createDialog', options, cb)
    );
    // If an AbortController was specified, use it when closing the dialog
    if (options.abortController) {
      dialog.on('dialog:closeRequest', () => options.abortController?.abort());
    }
    return dialog;
  }

  closeDialogs(): void {
    this.#configureApi.run('closeDialogs');
  }

  /** Reset a recipe to its initially loaded state. */
  resetRecipe(): Promise<void> {
    return promisify((cb) => this.#configureApi.run('resetRecipe', cb));
  }

  /**
   * Saves the current recipe on `https://prod.fluidconfigure.com/api`.
   */
  saveRecipe(opts: SaveRecipeOptions = {}): Promise<SaveRecipeResult> {
    return promisify((cb) => this.#configureApi.run('saveRecipe', opts, cb));
  }

  addToCart(opts: AddToCartOptions = {}): Promise<AddToCartInfo> {
    return promisify((cb) => this.#configureApi.run('addToCart', opts, cb));
  }

  /**
   * This method saves the current design on the browser's localStorage (end user).
   *
   * It triggers `snapshot:saved`
   *
   * Once the recipe is saved on the server, the localStorage["fc-snapshots"] will contain the following structure:
   *
   * ```json
   * [
      {
        "id": 123,
        "url": "test-image-url",
        "conciseRecipe": "1,0,0,0,9,9,0,0,9,0,9,0,0,28",
        "product": 20331,
        "time": 1457105539629
      }
    ]
   * ```
   *
   * All configure products will be saved on the same localStorage,
   * but only the products matching the current product ID will be available on the getSnapshots API method and on the snapshots UI component.
   */
  saveSnapshot(): Promise<SaveSnapshotResult> {
    return promisify((cb) => this.#configureApi.run('saveSnapshot', cb));
  }

  getPreferences(): ConfigurePreferences {
    return this.#configureApi.run('getPreferences');
  }

  /**
   * Return the group of a ConfigureAttribute instance.
   *
   * @param ca Configure Attribute instance
   */
  getCAGroup(ca: ConfigureAttribute): AttributeGroup | null {
    const attributeGroups = this.getProduct()?.attributeGroups;
    if (!attributeGroups) return null;
    for (const group of attributeGroups) {
      if (group.attributes.includes(ca.id)) {
        return group;
      }
    }
    return null;
  }

  /**
   *
   * @returns Return current currency
   */

  getCurrency(): string {
    return this.#configureApi.run('getCurrency');
  }

  /**
   * Return a hash which groups the CA by attribute groups
   *
   * @param attributeGroups list of Groups
   * @param configure instance of ConfigureUI
   */
  // TODO: Maybe It could set attributeGroups as class variable
  // and unset on configure UI destroy event
  getAttributesByGroup(): Map<number, Array<ConfigureAttribute>> {
    const attributeGroups = this.getProduct()?.attributeGroups;
    if (!attributeGroups) return new Map();
    const groupedAttributes = new Map<number, Array<ConfigureAttribute>>();
    attributeGroups.forEach((ag: AttributeGroup) => {
      const cas = ag.attributes.map((attr_id) => this.getAttributeOrThrow({ id: attr_id }));
      groupedAttributes.set(ag.id, cas);
    });
    return groupedAttributes;
  }

  /**
   * Return an array of rendered CA.
   * @returns Array<ConfigureAttribute>
   */
  getRenderedAttributes(): Array<ConfigureAttribute> {
    return Object.keys(this.getProduct()?.allAttributes ?? {})
      .map((id) => this.getAttributeOrThrow({ id: Number(id) }))
      .filter(filterByTruthy('canBeRendered'));
  }

  /**
   * Sends a DOM element to the printer
   * @param element The DOM element to print
   * @param options print settings
   */
  printElement(element: string | HTMLElement, options?: PrintElementOptions): Promise<HTMLIFrameElement> {
    return promisify((cb) => this.#configureApi.run('printElement', element, options, cb));
  }

  /** Force hiding all tooltips (global) */
  hideTooltips(): void {
    this.#configureApi.run('hideTooltips');
  }

  /** Returns a representation of the product facets with values. */
  getProductFacetsDetails(): ProductFacetsDetails {
    return this.#configureApi.run('getProductFacetsDetails');
  }

  /**
   * Returns the WebGL Viewer Instance
   */
  getConfigureWebgl(): Promise<ConfigureWebGL> {
    return promisify((cb) => this.#configureApi.run('getConfigureWebgl', cb));
  }

  /**
   * Builds the Share URL for this recipe id.
   *
   * It's done by using the current URL and setting or replacing the current recipe query param
   */
  getShareURL(recipeId: number | string): string {
    const url = new URL(window.location.href);
    url.searchParams.set('recipe', recipeId.toString());
    return url.toString();
  }

  /**
   * Saves the current recipe to the server and uses the id to build the share URL
   */
  async saveAndGetShareURL(): Promise<string> {
    const recipe = await this.saveRecipe();
    return this.getShareURL(recipe.id);
  }

  /**
   * Given a recipe item, returns the same item but fully loaded.
   *
   * The `recipe:change` event returns the ca and av but without some of the properties (metadata, facets, etc)
   */
  getFullRecipeItem({ ca, av }: AttributeValuePair): AttributeValuePair {
    // Get the full CA (including its values)
    ca = this.getAttribute(ca) ?? ca;
    return {
      ca,
      // Find the full AV in the full CA
      av: ca.values?.find((v) => v.id === av?.id) ?? av
    };
  }

  /**
   * Trigger an analytics event on the configure API instance.
   *
   * It's a "fire and forget" method, so it returns nothing.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analytics(name: Exclude<ConfigureAnalyticsEvent, 'customEvent'>, data?: any): void;
  analytics(name: 'customEvent', data?: ConfigureAnalyticsCustomEventData): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analytics(name: ConfigureAnalyticsEvent, data?: any): void {
    this.#configureApi.run('analytics', name, data);
  }

  on<T extends ConfigureUIEvent, K = never>(eventName: T, handler: ConfigureEventMap<K>[T]): void {
    void this.#apiReady.then(() => this.#eventManager.on(eventName, handler));
  }
  once<T extends ConfigureUIEvent, K = never>(eventName: T, handler: ConfigureEventMap<K>[T]): void {
    void this.#apiReady.then(() => this.#eventManager.once(eventName, handler));
  }
  off<T extends ConfigureUIEvent, K = never>(eventName: T, handler?: ConfigureEventMap<K>[T]): void {
    void this.#apiReady.then(() => this.#eventManager.off(eventName, handler));
  }
  trigger<T extends ConfigureUIEvent>(eventName: T, ...args: Parameters<ConfigureEventMap[T]>): void {
    void this.#apiReady.then(() => this.#eventManager.trigger(eventName, ...args));
  }
}
