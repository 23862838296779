import { clearArray } from './configure/utils/array';
import { Callback } from './configure/utils/types';
import { ImageEventPayload } from './features/image-gallery';
import { createEventRemovalFn } from './utils/browser';

/** Adidas Implementation Events */
type ConfigureAdidasEventMap = {
  /* Triggered when the customer want to upload an image. */
  'image-upload': ImageEventPayload;

  /* Triggered when the customer want to select one from the library. */
  'image-library': ImageEventPayload;

  /* Triggered when the customer want to select one from the library. */
  'image-resize': ImageEventPayload;

  /* Triggered when an analytics event occurs. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analytics: { eventName: string; payload: any };

  /** Triggered when this instance may be destroyed */
  destroyable: void;
};

export type ConfigureAdidasEvents = keyof ConfigureAdidasEventMap;

/**
 * ConfigureUI Implementation Event Dispatcher
 */
export class ConfigureEventDispatcher {
  #dispatcher: EventTarget = new EventTarget();
  #removers: Callback[] = [];

  removeAllEventListeners(): void {
    for (const remove of this.#removers) remove();
    clearArray(this.#removers);
  }

  /**
   * Adds a handler that is executed when the customer is done customizing.
   * The recipe has been saved and its ID and URL are provided in the payload.
   */
  addEventListener<T extends keyof ConfigureAdidasEventMap>(
    type: T,
    callback: (e: CustomEvent<ConfigureAdidasEventMap[T]>) => void,
    options?: AddEventListenerOptions
  ): void {
    this.#dispatcher.addEventListener(type, callback as EventListener, options);

    this.#removers.push(createEventRemovalFn(this.#dispatcher, type, callback, options));
  }

  dispatchEvent(event: Event): boolean {
    return this.#dispatcher.dispatchEvent(event);
  }
  removeEventListener(
    type: keyof ConfigureAdidasEventMap,
    callback: EventListenerOrEventListenerObject | null,
    options?: EventListenerOptions | boolean
  ): void {
    this.#dispatcher.removeEventListener(type, callback as EventListener, options);
  }
}
