import { ConfigureUI } from '@/configure/ConfigureUI';
import { ConfigureAdidas } from '@/ConfigureAdidas';
import { MENU_GROUPS } from '@/constants';
import { tDynamic } from '@/i18n';

interface MenuGroupConfig {
  display: Record<string, { alias: string; desc: string }>;
  ignore: string[];
}

/**
 * Creates the configuration for the menu groups (nested accordion and pager side menu)
 */
export function getMenuGroupsConfig(configure: ConfigureUI, wrapper: ConfigureAdidas): MenuGroupConfig {
  const groups = configure.getProduct()?.attributeGroups ?? [];
  const result: MenuGroupConfig = { display: {}, ignore: [...getSectionsSettings(wrapper).ignored] };

  for (let i = 0; i < groups.length; i++) {
    if (i < 2) {
      // The first two groups must be shown in the nested accordion and pager side menu
      result.display[groups[i]!.name.toLowerCase()] = {
        alias: MENU_GROUPS[i] ?? '',
        desc: tDynamic(`ly_${MENU_GROUPS[i]}_desc`, '')
      };
    } else {
      // The rest must be ignored
      result.ignore.push(groups[i]!.name);
    }
  }

  return result;
}

/**
 * Process the settings for menu sections/groups (ie. style and personalise)
 */
export function getSectionsSettings(wrapper: ConfigureAdidas) {
  const sections: Record<string, boolean> = wrapper.params.menuSections ?? { personalize: true, style: true };

  // Make it work for both spellings
  if (sections['personalise']) sections['personalize'] = true;

  // Get the list of ignored sections in the current language
  const ignored = MENU_GROUPS.filter((menu) => sections[menu] !== true).map((s) =>
    tDynamic(`ly_${s}`, {}, s).toUpperCase()
  );

  return { ignored, includeAll: ignored.length === 0 };
}
