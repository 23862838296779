import { AttributeValue } from '../../model/AttributeValue';
import { ConfigureAttribute, getDefaultValueForCA } from '../../model/ConfigureAttribute';
import { ConfigureProduct } from '../../model/ConfigureProduct';
import {
  AttributeSet,
  AttributeSetItem,
  AttributeSetOpts,
  ParentAttributeInfo,
  SubAttributeInfo
} from './AttributeSet';

/**
 * Builds the internal representation of the AttributeSet using the specified configuration and the Product CAs
 * @param product Configure Product
 * @param attributeSetName AttributeSet name
 * @param parentInfo info of the parent CA
 * @returns the AttributeSet if any items are found, `null` otherwise
 */
export function buildAttributeSet(
  product: ConfigureProduct,
  attributeSetName: string,
  opts: AttributeSetOpts
): AttributeSet | null {
  // Regex used to find the associated CAs of the AttributeSet
  const REGEX = getAttributeSetAliasRegex(opts, attributeSetName);

  // Initialize the AttributeSet
  const attributeSet: AttributeSet = {
    ...opts,
    name: attributeSetName,
    count: 0,
    items: {},
    subattributes: [],
    isPart: (ca: ConfigureAttribute) => REGEX.test(ca.alias)
  };

  // Process the product attributes
  for (const ca of product.attributes) {
    const item = parseSetItem(ca, REGEX, opts.parentInfo);
    // If the CA is part of the CA, add it to the set
    if (item) {
      attributeSet.items[item.parentCA.alias] = item;
      attributeSet.count++;
    }
  }

  // No set attributes found, nothing to process
  if (attributeSet.count === 0) {
    console.warn('No %s attribute found for %s', opts.parentInfo.name, attributeSet.name);
    return null;
  }

  // Every item in the set (1, 2, 3, etc) should have the same default value and subattributes
  const firstItem = Object.values(attributeSet.items)[0]!;

  // Create an array with the parent attribute and all the subattributes without the set prefix (eg. toggle, color, text, etc)
  attributeSet.subattributes = [
    { name: opts.parentInfo.name, defaultValue: () => firstItem.inactiveAvName },
    ...getSetSubattributes(attributeSet.name, firstItem.parentCA)
  ];

  console.log('Attribute Set info: %o', attributeSet);

  return attributeSet;
}

/**
 * Generates the Regex to find CAs that belong to an AttributeSet
 * @param parentInfo info of the parent attribute to look for (eg toggle)
 * @param attributeSetName Optional. If specified, the regex will only look for the AttributeSet with the this name.
 * Otherwise it will look for all the AttributeSets
 */
export function getAttributeSetAliasRegex(opts: AttributeSetOpts, attributeSetName = '(\\w+)'): RegExp {
  return new RegExp(`${getAttributeSetPrefix(opts)}${attributeSetName}_(\\d+)\\.${opts.parentInfo.name}$`);
}

/**
 * Creates the appropiate alias prefix. If it's defined, it appends the separator char (.)
 *
 * Otherwise, it returns an empty string, so nothing is preppended.
 */
export function getAttributeSetPrefix(opts: AttributeSetOpts): string {
  return opts.aliasPrefix ? opts.aliasPrefix + '.' : '';
}

/**
 * Parses the CA alias to retrieve the set name and position
 */
function parseSetItem(ca: ConfigureAttribute, REGEX: RegExp, parentInfo: ParentAttributeInfo): AttributeSetItem | null {
  const matches = REGEX.exec(ca.alias);
  if (matches?.length !== 2) return null;

  // Find the active and inactive AVs for the current locale
  const activeName = ca.values.find(parentInfo.isActive)?.name;
  const inactiveName = ca.values.find(parentInfo.isInactive)?.name;

  if (!activeName || !inactiveName) {
    console.warn(
      '[AttributeSets] CA with alias %s matches the regex but contains no AVs for active or inactive state',
      ca.alias
    );
    return null;
  }

  return {
    parentCA: ca,
    position: Number(matches[1]),
    active: false,
    activeAvName: activeName,
    inactiveAvName: inactiveName
  };
}

/**
 * Process the AttributeSet Item Parent CA to get its subattributes, including name and default value.
 */
function getSetSubattributes(attributeSetName: string, parentCA: ConfigureAttribute): SubAttributeInfo[] {
  if (!parentCA.subAttributes) return [];
  return parentCA.subAttributes.flatMap((sca) => {
    // Get the attribute name, which starts next to the first "." after the set name and position
    const start = sca.alias.indexOf(`.${attributeSetName}_`) + 1;
    const name = sca.alias.substring(sca.alias.indexOf('.', start) + 1);

    const result = getSetSubattributes(attributeSetName, sca);

    if (sca.selectorType !== 'none') {
      // Since the default value may change (due to selectable prop), we use a fn instead of a fixed value
      // The fn will have the required CA in its lexical scope
      const defaultValue = () => {
        // Get the default value name for that attribute or empty text for text CAs
        const value = getDefaultValueForCA(sca);
        return (value as AttributeValue)?.name ?? value;
      };
      result.push({ name, defaultValue });
    }

    return result;
  });
}
