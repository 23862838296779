import { ConfigureUI } from '@/configure/ConfigureUI';
import { ConfigureAttribute, getInitialRecipeItemsForCA } from '@/configure/model/ConfigureAttribute';
import { AVRecipeValue } from '@/configure/types/configureui-types';
import { ALIAS_SUFFIXES } from '@/constants';

/**
 * Functions that resets the CAs, taking into account special logic for Adidas products.
 *
 * It delegates most of the logic to the generic `Configure.resetAttributes()`, but in the case there's
 * both an outline and an outlineColor CAs to reset, it performs the resets in two steps to prevent an error due to
 * visibility rules: the outlineColor must be "None Color"
 */
export function resetAttributes(configure: ConfigureUI, cas: ConfigureAttribute[]): void {
  // Get the default values for the CAs and all their sub CAs
  const recipeChanges: Array<[string, AVRecipeValue]> = [];
  for (const ca of cas) recipeChanges.push(...getInitialRecipeItemsForCA(ca));

  // If nothing to change, return
  if (recipeChanges.length === 0) return;

  // Get the outline colors from the changes, only when the outline is also being modify
  const outlineColors = findOutlineColors(recipeChanges);

  if (outlineColors.length === 0) {
    // If no outline color, change everything in one pass (default behavior)
    void configure.setRecipe(recipeChanges);
  } else {
    // If there's outline colors, remove them from the changes and run a first pass with the other CAs
    const firstPass = recipeChanges.filter(([key]) => !outlineColors.includes(key));
    void configure.setRecipe(firstPass);

    // After the first pass, call this fn again with only the outline colors
    // Since the outline toggle is "off" by this point, the default values should be correctly set to "None"
    resetAttributes(
      configure,
      outlineColors.map((alias) => configure.getAttributeOrThrow({ alias }))
    );
  }
}

/**
 * Given the list of changes,
 * find the outline colors, but only in the corresponding outline toggle is in the list as well
 */
function findOutlineColors(items: Array<[string, unknown]>): string[] {
  // Get the list of aliases being resetted
  const aliases = items.map(([key]) => key);

  return (
    aliases
      // Get only the outline colors from the list
      .filter((alias) => alias.endsWith(ALIAS_SUFFIXES.OUTLINE_COLOR))

      // Get only the outlineColors if their corresponding toggle is also being resetted
      .filter((outlineColorAlias) => {
        // Build the corresponding alias toggle from the outline color alias
        const prefix = outlineColorAlias.replace(ALIAS_SUFFIXES.OUTLINE_COLOR, '');
        const toggleAlias = prefix + ALIAS_SUFFIXES.OUTLINE;

        // Only return this outlineColor if its toggle is being resetted
        return aliases.includes(toggleAlias);
      })
  );
}
