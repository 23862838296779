/**
 * Convert a string from camel or pascal case to snake case
 */
export function camelToSnakeCase(str: string): string {
  if (str.length === 0) return '';
  return (
    str.charAt(0).toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
  );
}

/**
 * Convert a string from underscore case to camel case
 * @returns new string
 */
export function camelize(s: string): string {
  const camelized = s.replace(/(?:^|[-_])(\w)/g, (_, c) => c?.toUpperCase() ?? '');
  if (camelized.length === 0) return '';
  return camelized.charAt(0).toLowerCase() + camelized.slice(1);
}

/**
 * Convert the object keys from underscore case to camel case keys
 * @returns new object
 */
export function camelizeObject(obj: Record<string, string>): Record<string, string> {
  const camelizedO: Record<string, string> = {};
  for (const [key, value] of Object.entries(obj)) {
    const newKey = camelize(key);
    camelizedO[newKey] = value;
  }
  return camelizedO;
}

/**
 * Type to avoid "any" warning but be able to use it as interpolation contexto
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InterpolationVariables = any;

/**
 * Creates a new string using the template and the replacements map.
 * Values must be inside "{}" in the `template` string, and declared in the `variables` object
 * check __tests__ for reference
 *
 * @param template string containing the template with variables as {}.
 * @param variables map with the values of the variables
 *
 * @returns new string
 */
export function interpolate(template: string, variables: InterpolationVariables): string {
  return template.replace(/{(\w+)}/g, (fullMatch, group1) => variables?.[group1]?.toString() ?? fullMatch);
}
