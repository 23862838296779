import { ComponentEventBus, ComponentOptions, ConfigureComponents, Embeddable, Responsive } from '.';
import { ConfigureUI } from '../ConfigureUI';
import { EventBusWrapper } from '../event/bus/EventBusWrapper';
import { handleResponsiveEventBus, LifecycleEvent } from '../event/bus/lifecycle-events';
import { eventToPromise } from '../event/bus/promise';
import { EventBus } from '../event/bus/types';
import { createNestedAccordion } from '../extension/components/nested-accordion';
import { CreateComponentsOptions } from '../types/configureui-types';
import { promisify } from '../utils/promise';

// Display WebGL component requires special handling (due to destroy events)
const DISPLAY_TYPE = 'displayWebgl';

type Bus = EventBus<Record<ConfigureComponents, unknown[]>, { destroy: [] }>;

/**
 * Class responsible for registering and creating components.
 *
 * It forwards the request to configureUI API, after ensuring the container it's inside this ConfigureUI instance DOM tree.
 *
 * The main reason this class exists is to keep a record of the componentes created, so they can be destroyed
 * during clean up by calling `trigger("destroy")` and `off()` on each.
 */
export class ComponentManager {
  #configure: ConfigureUI;
  #buses: Record<string, Bus> = {};
  #destroyPromise: Promise<void>;

  constructor(configure: ConfigureUI) {
    this.#configure = configure;

    // Get a promise that resolves when ConfigureUI is done destroying
    this.#destroyPromise = this.getDestroyPromise();
  }

  private getDestroyPromise() {
    return eventToPromise(
      this.#configure.ready('api').then(() => this.#configure.getApi()),
      'destroy:finish'
    );
  }

  /**
   * Destroys the components.
   * For each registered component, it triggers "destroy" (undocumented but used in other implementations) and then
   * shutdowns the event bus.
   * Special handling is required for "WebGL Display" as its events are used to trigger the global "destroy:finish" event. So
   * it needs to be shutdown after that event happened.
   */
  async destroy(): Promise<void> {
    // Iterate, destroy and shutdown all components (except WebGL Display)
    for (const [type, bus] of Object.entries(this.#buses)) {
      if (type !== DISPLAY_TYPE) {
        bus.trigger('destroy');
        bus.off();
        delete this.#buses[type];
      }
    }

    // Wait for destroy:finish event
    await this.#destroyPromise;

    // Destroy and shutdown the WebGL Display
    const displayBus = this.#buses[DISPLAY_TYPE];
    if (displayBus) {
      displayBus.trigger('destroy');
      displayBus.off();
    }

    this.#buses = {};
    console.log('[ComponentMananger] Destroyed');
  }

  async createComponents(options: CreateComponentsOptions): Promise<Record<string, ComponentEventBus>> {
    // Scope each component to the DOM tree of this instance of ConfigureUI
    options.components.forEach((opt) => {
      if (opt.container) opt.container = this.getContainer(opt.container);
    });

    const buses: Record<string, ComponentEventBus> = await promisify((cb) =>
      this.#configure.getApi().run('createComponents', options, cb)
    );
    for (const [type, bus] of Object.entries(buses)) {
      this.#buses[type] = bus;
    }
    return buses;
  }

  async createComponent(options: ComponentOptions & Embeddable & Responsive): Promise<ComponentEventBus> {
    // Scope the component to the DOM tree of this instance of ConfigureUI
    options.container = this.getContainer(options.container);

    if (options.type === 'accordion' && options.nested) {
      return createNestedAccordion(this.#configure, options);
    } else if (hasMediaQuery(options)) {
      return this.createResponsiveComponent(options);
    } else {
      const bus: ComponentEventBus = await promisify((cb) =>
        this.#configure.getApi().run('createComponent', options, cb)
      );
      this.#buses[options.type] = bus;
      return bus;
    }
  }

  private async createResponsiveComponent(
    options: ComponentOptions & Embeddable & Required<Responsive>
  ): Promise<ComponentEventBus> {
    const bus: ComponentEventBus = await promisify((cb) => {
      const em = new EventBusWrapper<LifecycleEvent>();
      const api = this.#configure.getApi();
      this.#configure.getApi().run(
        'createComponents',
        {
          container: 'body',
          components: [],
          mediaQueries: {
            [options.mediaQuery]: {
              components: [options],
              onChange(matches: boolean, eventBuses: Record<string, unknown>) {
                handleResponsiveEventBus(em, eventBuses?.[options.type], matches);
                if (matches) {
                  api.trigger('mediaQuery:change', options.mediaQuery);
                }
              }
            }
          }
        },
        function (err: Error) {
          cb(err, em.cast());
        }
      );
    });

    this.#buses[options.type] = bus;
    return bus;
  }

  /**
   * Finds the component container.
   * If the parameter is an HTML Element, use it as is only if it's a child of the root container.
   * If it's selector, only search below the root element of the ConfigureUI instance
   */
  private getContainer(containerOrSelector: HTMLElement | string): HTMLElement {
    if (typeof containerOrSelector === 'string') {
      const el: HTMLElement | null = this.#configure.dom.container.querySelector(containerOrSelector);
      if (!el) {
        throw new Error(
          `Element ${containerOrSelector} not found in the DOM as child of ${this.#configure.dom.container.nodeName}#${this.#configure.dom.container.id}.${this.#configure.dom.container.className}`
        );
      }
      return el;
    } else {
      if (!this.#configure.dom.container.contains(containerOrSelector)) {
        throw new Error(
          `Element ${containerOrSelector.nodeName}#${containerOrSelector.id}.${containerOrSelector.className} is not a child of ${this.#configure.dom.container.nodeName}#${this.#configure.dom.container.id}.${this.#configure.dom.container.className}`
        );
      }
      return containerOrSelector;
    }
  }
}

function hasMediaQuery(
  opts: ComponentOptions & Embeddable & Responsive
): opts is ComponentOptions & Embeddable & Required<Responsive> {
  return !!opts.mediaQuery;
}
