import { ConfigureUI } from '@/configure/ConfigureUI';
import { t } from '@/i18n';
import { localizeLayout } from '@/layout';
import { enableAccessibility } from './accessibility';
import { toggleBodyBackdrop } from './loader';

/**
 * Adds an event listener to perform certain global actions after ConfigureUI loads
 */
export function addPostLoadingActions(configure: ConfigureUI): void {
  configure.on('analytics:configureLoaded', function () {
    // Hide tootltips on resize
    configure.dom.onResize(() => configure.hideTooltips());

    localizeLayout(configure);
    toggleBodyBackdrop(true);
    void enableAccessibility(configure);

    // Adjust the elements size and position
    window.dispatchEvent(new Event('resize'));
  });
}

export function modifyGeneralComponents(configure: ConfigureUI): void {
  configure.setComponentOptions({
    // set placehodler for text inputs inside attributeSelectors
    attributeSelector: {
      uiSettings: {
        placeholder: t('enter_your_text')
      }
    }
  });
}
