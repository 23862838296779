import { getElement } from './browser';

const DEFAULT_VERSION_ELEMENT = 'fc-library-version';

/**
 * Information regarding the library.
 *
 * Can be useful to display some label
 */
export interface BuildInfo {
  name: string;
  description: string;
  version: string;
  customerId: number;
  projectCode: string;
  displayName: string;
  buildDate: number;
}

const FALLBACK_INFO: BuildInfo = {
  name: 'yr-configurator',
  displayName: 'YR Configurator',
  version: '0.0.0',
  customerId: 0,
  projectCode: 'test',
  description: 'YR Configurator',
  buildDate: Date.now()
};

const getEdgeStyle = (color: string) => `background:#28b8d1;font-weight:bold;padding: 2px;color:${color}`;
const getMiddleStyle = (color: string) => `background:#28b8d1;font-weight:bold;padding: 2px 0;color:${color}`;

/**
 * Library information
 */
export const buildInfo: BuildInfo = JSON.parse(import.meta.env['BUILD_INFO'] ?? FALLBACK_INFO);

console.log(
  '%c%s %cv%s %c- Build date:%c %s',
  getEdgeStyle('#FFF'),
  buildInfo.displayName,
  getMiddleStyle('#FF0'),
  buildInfo.version,
  getMiddleStyle('#FFF'),
  getEdgeStyle('#FF0'),
  new Date(buildInfo.buildDate)
);

export function handleVersionParameter(el: HTMLElement | string): void {
  // Remove if it exists
  getElement(el).insertAdjacentHTML('beforeend', `<div class="${DEFAULT_VERSION_ELEMENT}">v${buildInfo.version}</div>`);
}
