import { addDOMEventListener, addDOMEventListenerByData, getElement } from '@/utils/browser';
import { createEventRemovalFn } from '@/utils/browser';
import { waitForDom } from '@/utils/dom';
import { DialogEventBus } from '../components/dialog';
import { clearArray } from '../utils/array';
import { Callback } from '../utils/types';

interface AddDOMEventListenerOpts extends AddEventListenerOptions {
  /**
   * Use this flag if the element may be added or removed at any time
   * The logic changes so that the listener is kept (by actually listening to a parent element event)
   */
  dynamic?: boolean;
  container?: string | HTMLElement;
}

const INTERNAL_CONTAINER_CLASS = 'configure-container';

/**
 * Class responsible for handling common DOM queries and modifications in the context of a ConfigureUI instance.
 *
 * It scopes the queries and listeners to the root container instead of document, performs common DOM related tasks
 * and registers the event listeners so it is easier to clean them up on destroy
 */
export class DomManager {
  #container: HTMLElement;
  #removers: Callback[] = [];

  constructor(container: string | HTMLElement) {
    this.#container = this.createInternalContainer(getElement(container));
  }

  /**
   * Creates the internal container element.
   * All Configurator UI will be created inside
   */
  private createInternalContainer(root: HTMLElement) {
    const container = document.createElement('div');

    container.classList.add(INTERNAL_CONTAINER_CLASS);
    // Added because css files use the ID as scope
    container.id = INTERNAL_CONTAINER_CLASS;
    root.append(container);
    return container;
  }

  get container(): HTMLElement {
    return this.#container;
  }

  async destroy(): Promise<void> {
    // Remove all DOM event listeners
    for (const remove of this.#removers) remove();
    clearArray(this.#removers);

    // Clear the DOM tree
    this.#container.innerHTML = '';
    // Remove the instance container
    this.#container.remove();

    // Wait for DOM to be updated
    await waitForDom();
    console.log('[DomManager] Destroyed');
  }

  querySelector<K extends keyof HTMLElementTagNameMap>(selectors: K): HTMLElementTagNameMap[K] | null;
  querySelector<K extends keyof SVGElementTagNameMap>(selectors: K): SVGElementTagNameMap[K] | null;
  querySelector<E extends Element = Element>(selectors: string): E | null;
  querySelector(selectors: string): Element | null {
    return this.#container.querySelector(selectors);
  }
  querySelectorAll<K extends keyof HTMLElementTagNameMap>(selectors: K): NodeListOf<HTMLElementTagNameMap[K]>;
  querySelectorAll<K extends keyof SVGElementTagNameMap>(selectors: K): NodeListOf<SVGElementTagNameMap[K]>;
  querySelectorAll<E extends Element = Element>(selectors: string): NodeListOf<E>;
  querySelectorAll(selectors: string): NodeListOf<Element> {
    return this.#container.querySelectorAll(selectors);
  }

  onResize(handler: (e: UIEvent) => void): void {
    // TODO Check if resize is triggered on the configure container instead of window
    window.addEventListener('resize', handler, { capture: true });
    this.#removers.push(createEventRemovalFn(window, 'resize', handler, { capture: true }));
  }

  addEventListener<E extends Event>(
    selector: string,
    eventName: string,
    handler: (e: E) => void,
    opts: AddDOMEventListenerOpts = {}
  ): void {
    if (opts?.dynamic) {
      const remover = addDOMEventListener(selector, eventName, handler, {
        container: this.container,
        ...opts
      });

      this.#removers.push(remover);
    } else {
      const container: ParentNode = opts?.container ? getElement(opts.container) : this.container;
      const el = container.querySelector(selector);
      if (!el) {
        console.warn(`Selector ${selector} not found on the DOM, did you forget to add {dynamic: true}?`);
        return;
      }
      el.addEventListener(eventName, handler as EventListener, opts);
      this.#removers.push(createEventRemovalFn(el, eventName, handler, opts));
    }
  }

  addEventListenerByData(
    selector: string,
    eventName: string,
    dataKey: string,
    handler: (e: Event, data: string, dataContainer: HTMLElement) => void,
    opts?: AddDOMEventListenerOpts
  ): void {
    const remover = addDOMEventListenerByData(selector, eventName, dataKey, handler, {
      container: this.container,
      ...opts
    });
    this.#removers.push(remover);
  }

  addDialogCloseButtonHandler(dialog: DialogEventBus, buttonUuid: string, layer: number = 0): void {
    // Find the recently create dialog button in the document
    // It must be a UUID and it will be located outside this instance's root element
    let button = document.querySelector(`#${buttonUuid}`);

    // If the element is not found on the DOM, try on the container as it may be detached
    if (!button) button = this.#container.querySelector(`#${buttonUuid}`);

    if (!button) {
      // throw new Error('Error creating Error Dialog: Close button not found');
      // Warn but allow the code to keep running instead of throwing an error
      console.warn('Cannot create Error dialog: Close button not found');
      return;
    }

    // Add the close button event listener (only once)
    button.addEventListener('click', () => dialog.trigger('dialog:closeRequest', layer), { once: true });
  }
}
