import { ConfigureUI } from '@/configure/ConfigureUI';
import { openSnapshotsDialog } from './snapshots-dialog';

/**
 * Adds the behavior to the "Download Snapshots" button
 */
export function implementSnapshotFeature(configure: ConfigureUI): void {
  const cta = configure.dom.querySelector('.configure-download-snapshots-container');

  cta?.addEventListener('click', () => openSnapshotsDialog(configure));
}
