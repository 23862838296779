import { t } from '@/i18n';
import { showErrorDialog } from './components/dialog';
import { ConfigureUI } from './configure/ConfigureUI';
import {
  AttributeValuePair,
  ConfigureErrorInvalidRecipe,
  ConfigureInitError,
  ErrorInvalidRecipeDetails
} from './configure/types/configureui-types';
import { Callback } from './configure/utils/types';
import { initI18n } from './i18n';

export async function handleConfigureInitError(configure: ConfigureUI, err: ConfigureInitError): Promise<void> {
  try {
    await initI18n(configure);
  } catch {
    console.warn('Could not load i18n in error hanlders');
  }
  return new Promise((resolve, reject) => {
    if (err.fcErrorType === 'recipe:invalid') {
      void handleInvalidRecipe(configure, resolve, (err as ConfigureErrorInvalidRecipe).fcErrorDetails);
    } else if (err.fcErrorType === 'recipe:notFound') {
      handleNotFoundRecipe(configure, resolve);
    } else {
      handleUnknownError(configure, reject);
    }
  });
}

// Show an error and set a recipe to the fallback values
async function handleInvalidRecipe(configure: ConfigureUI, resolve: Callback, errorDetails: ErrorInvalidRecipeDetails) {
  // Get the AV query instead of the full object to make Configure take into account product rules
  const fallbackRecipe = (errorDetails.fallbackRecipe as AttributeValuePair[]).map(({ ca, av }) => ({
    ca,
    av: { id: av.id }
  }));

  await configure.setFallbackRecipe(fallbackRecipe);
  void showErrorDialog(configure, t('err_invalid_recipe'), getInvalidRecipeMessage(configure, errorDetails), resolve);
}

// We'll show an error and show the product with the blank recipe
function handleNotFoundRecipe(configure: ConfigureUI, resolve: Callback) {
  void showErrorDialog(configure, t('err_recipe_not_found'), `<p>${t('err_fallback_recipe')}</p>`, resolve);
}

function handleUnknownError(configure: ConfigureUI, reject?: Callback<Error>) {
  if (configure.created) {
    void showErrorDialog(configure, t('err_unknown'), `<p>${t('err_unknown')}</p>`, () =>
      reject?.(new Error(t('err_unknown')))
    );
  } else {
    const container: HTMLElement | null = configure.dom.querySelector('.configure-container');
    if (container) container.style.alignItems = 'center';
    // var client = new ConfigureIdUtilities.HttpClient();

    // var searchParams = ConfigureIdUtilities.parseSearchString();
    // var refImplVersion = ConfigureIdUtilities.getRefImplVersion(searchParams);
    // var filePath = ConfigureIdUtilities.getFileUrl(refImplVersion, "unknow-error.html");

    // insert response to main page
    const wireFrameContainer = configure.dom.querySelector('.configure-container');
    if (wireFrameContainer)
      wireFrameContainer.innerHTML = /* html */ `
        '<div class="error-message"><h1 class="error-message_h1">${t('err_loading_product')}</h1></div>';
      `;

    reject?.(new Error('Unknown error'));
  }
}

function getInvalidRecipeMessage(configure: ConfigureUI, errorDetails: ErrorInvalidRecipeDetails) {
  const newRecipe = configure.getRecipe('custom', 'alias', 'name');
  let message = '<ul class="recipe-unavailable">';
  let removed, av;
  const oldConfiguration = errorDetails.recipeDocument.configuration;
  for (let i = 0; errorDetails.unavailable.length > i; i += 1) {
    removed = errorDetails.unavailable[i];
    const removedAlias = removed?.ca.alias ?? '';
    av = newRecipe[removedAlias];
    const oldStr = oldConfiguration[removedAlias];
    const newStr = av; //.av.name;
    message += `<li>${t('err_invalid_desc', {
      oldStr,
      newStr,
      ca: removedAlias,
      av
    })}</li>`;
  }
  message += '</ul>';
  return message;
}
