import { ConfigureUI } from '@/configure/ConfigureUI';
import { AttributeValue } from '@/configure/model/AttributeValue';
import {
  ConfigureAttribute,
  filterCARecursively,
  getSelectedValue,
  UgcConfigureAttribute
} from '@/configure/model/ConfigureAttribute';
import { findMetadataValue } from '@/configure/model/Metadata';
import { ConfigureEventDispatcher } from '@/ConfigureEventDispatcher';
import { ALIAS_SUFFIXES, PIXELS_PER_CM } from '@/constants';
import { filterByProperty } from '@/utils/array';
import { nextPowerOfTwo } from '@/utils/math';
import { AdidasImagePayload } from '.';
import { ImageAlignment, ImageDimensions } from './image-gallery-types';

const METADATA_WIDTH = 'width_cm';
const METADATA_HEIGHT = 'height_cm';

const METADATA_VERTICAL_ALIGNMENT = 'vertical_alignment';
const METADATA_HORIZONTAL_ALIGNMENT = 'horizontal_alignment';

const METADATA_KEYS = [METADATA_WIDTH, METADATA_HEIGHT];

/** Required information of the UGC and Size */
interface UgcAttributeInfo {
  ugc: UgcConfigureAttribute;
  size: ConfigureAttribute;
  location?: ConfigureAttribute;
}

export interface ImageGalleryResizeHelpers {
  getDimensionsForCA: (ugcCA: ConfigureAttribute) => ImageDimensions | undefined;
  getAlignmentForCA: (ugcCA: ConfigureAttribute) => ImageAlignment;
}

export function implementImageGalleryResize(
  dispatcher: ConfigureEventDispatcher,
  configure: ConfigureUI,
  adidasPayloads: Map<string, AdidasImagePayload>
): ImageGalleryResizeHelpers {
  // Process the product to retrieve the UGC CAs with their associated Size CAs
  const ugcList = buildUgcAttributeList(configure);

  // We need to dispatch an event when the size changes
  configure.on('recipe:change', (modifications) => {
    // Find the modified UGC item. In order to select a UGC item:
    // - EVERY modified CA must be part of the UGC group (either the toggle or any subattribute, eg location)
    // - None of them must be the Image CA (if it has just changed, why request it again?)
    // - One of them must be either the size or location CA (different location might have a different area
    // , so the same size might have padding in one and not in another).
    const item = ugcList.find((item) => {
      const toggleCaId = item.ugc.parentId;
      let hasSizeOrLocation = false;

      for (const { ca } of modifications) {
        const isNotPartOfUgcGroup = ca.id !== toggleCaId && ca.parentId !== toggleCaId;
        const isImageCA = ca.id === item.ugc.id;

        // If it's not part of the group or it's the image CA, don't trigger any resize event for this group
        if (isNotPartOfUgcGroup || isImageCA) return false;

        const isLocationCA = item.location ? ca.id === item.location.id : false;
        const isSizeCA = ca.id === item.size.id;

        // Is it the size or location CA?
        if (isSizeCA || isLocationCA) hasSizeOrLocation = true;
      }

      // Only return the item if it includes the size or location CA
      return hasSizeOrLocation;
    });

    // If no UGC item matches the criteria, do nothing
    if (!item) return;

    // Check if we have a payload associated with this UGC CA
    // If not, it has no image, no reason to call "resize"
    const payload = adidasPayloads.get(item.ugc.alias);
    if (!payload) return;

    // Get the CA again to ensure the "selected" prop is up to date
    const sizeCA = configure.getAttributeOrThrow({ id: item.size.id });
    const sizeAV = getSelectedValue(sizeCA);
    const sizePayload = sizeCA && sizeAV ? buildDimensions(sizeCA, sizeAV) : {};

    // Get the current selected location
    let alignmentPayload: ImageAlignment = {};
    if (item.location) {
      const locationCA = configure.getAttributeOrThrow({ id: item.location.id });
      alignmentPayload = buildAlignment(getSelectedValue(locationCA));
    }

    const customPayload = { ...sizePayload, ...alignmentPayload };

    // If no size or alignment data is present, do not trigger the event
    if (Object.keys(customPayload).length === 0) return;

    // Populate the AV with all the data, including metadata
    // av = ca.values.find(filterById(av.id)) ?? av;

    // Dispatch image-resize event
    dispatcher.dispatchEvent(
      new CustomEvent('image-resize', {
        detail: {
          caAlias: item.ugc.alias,
          imageLibraryId: payload.imageLibraryId,
          fileName: payload.fileName,
          ...customPayload
        }
      })
    );
  });

  /**
   * Returns the image dimensions for the specified UGC CA, including print sizes and actual sizes
   */
  function getDimensionsForCA(ugcCA: ConfigureAttribute): ImageDimensions | undefined {
    const item = ugcList.find((item) => item.ugc.id === ugcCA.id);
    if (!item) {
      console.error('[ImageGallery] UGC CA %s has no associated Size CA', ugcCA.alias);
      return;
    }

    // We get the CA again to ensure the "selected" prop is up to date
    const sizeCA = configure.getAttributeOrThrow({ id: item.size.id });
    const sizeAV = getSelectedValue(sizeCA);
    if (!sizeAV) {
      // If no size AV is chosen, we use a 1px by 1px image
      // so we can trigger the event anyway and the thumbnail is displayed
      console.warn('[ImageGallery] No size AV is selected for %s, using 1x1 image', item.ugc.alias);
      return { width: 1, height: 1, paddedHeight: 1, paddedWidth: 1 };
    }

    return buildDimensions(sizeCA, sizeAV);
  }

  /**
   * Returns the image alignment for the specified UGC CA
   */
  function getAlignmentForCA(ugcCA: ConfigureAttribute): ImageAlignment {
    const item = ugcList.find((item) => item.ugc.id === ugcCA.id);
    if (!item?.location) {
      console.error('[ImageGallery] UGC CA %s has no associated Location CA', ugcCA.alias);
      return {};
    }

    // We get the CA again to ensure the "selected" prop is up to date
    const locationCA = configure.getAttributeOrThrow({ id: item.location.id });
    const locationAV = getSelectedValue(locationCA);
    return buildAlignment(locationAV);
  }

  return { getDimensionsForCA, getAlignmentForCA };
}

export function buildUgcAttributeList(configure: ConfigureUI): UgcAttributeInfo[] {
  const result: UgcAttributeInfo[] = [];

  // Find the UGC CAs in the product
  const ugcCAs = findAllUgcAttributes(configure);

  // We only care about UGC Attributes with size siblings
  for (const ugc of ugcCAs) {
    // Find a sibling size
    const sizeCA = findSiblingCA(configure, ugc, isSizeCA);
    if (sizeCA) {
      const locationCA = findSiblingCA(configure, ugc, isLocationCA);
      result.push({ size: sizeCA, ugc: ugc, location: locationCA });
    }
  }

  return result;
}

/** Search for all the UGC attributes recursively */
function findAllUgcAttributes(configure: ConfigureUI): UgcConfigureAttribute[] {
  const attributes = configure.getProduct()?.attributes ?? [];
  return attributes.flatMap(
    (ca) => filterCARecursively(ca, filterByProperty('selectorType', 'ugc')) as UgcConfigureAttribute[]
  );
}

/**
 * Build the object containint the alignment for the provided location
 */
function buildAlignment(locationAV: AttributeValue | undefined): ImageAlignment {
  if (!locationAV) return {};

  const va = findMetadataValue(locationAV, METADATA_VERTICAL_ALIGNMENT) as
    | ImageAlignment['verticalAlignment']
    | undefined;
  const ha = findMetadataValue(locationAV, METADATA_HORIZONTAL_ALIGNMENT) as
    | ImageAlignment['horizontalAlignment']
    | undefined;

  const result: ImageAlignment = {};
  if (va) result.verticalAlignment = va;
  if (ha) result.horizontalAlignment = ha;

  return result;
}

/**
 * Get the max possible size of the provided CA
 *
 * The logic is to iterate all its "selectable" values and retrieve the max width or height from them.
 * That value is returned for both the width and the height, since it must be square.
 */
function getMaxSizes(ca: ConfigureAttribute): { width: number; height: number } {
  const max = ca.values
    .filter((av) => av.selectable)
    .map(getSizeForAV)
    .reduce((max, { width, height }) => Math.max(max, width, height), 0);

  return { width: max, height: max };
}

/**
 * Build the object containing the current and max sizes for the Attribute
 */
function buildDimensions(sizeCA: ConfigureAttribute, sizeAV: AttributeValue): ImageDimensions {
  // Get the largest square available for this CA (using the current selectable AVs)
  const maxCMs = getMaxSizes(sizeCA);

  const max = {
    width: Math.round(maxCMs.width * PIXELS_PER_CM),
    height: Math.round(maxCMs.height * PIXELS_PER_CM)
  };

  // Find factor to upscale the square to a power of two
  const scaling = {
    width: nextPowerOfTwo(max.width) / max.width,
    height: nextPowerOfTwo(max.height) / max.height
  };

  // Find the size of the selected AV
  const sizeCMs = getSizeForAV(sizeAV);

  const size = {
    width: Math.round(sizeCMs.width * PIXELS_PER_CM),
    height: Math.round(sizeCMs.height * PIXELS_PER_CM)
  };

  const dimensions = {
    width: Math.round(size.width * scaling.width),
    height: Math.round(size.height * scaling.height),
    paddedWidth: Math.round(max.width * scaling.width),
    paddedHeight: Math.round(max.height * scaling.height)
  };
  console.log(`[ImageGallery] Scaling: ${scaling.width}-${scaling.height}`);
  console.log('[ImageGallery] Original size %o', {
    width: size.width,
    height: size.height,
    paddedWidth: max.width,
    paddedHeight: max.height
  });
  console.log('[ImageGallery] New size %o', dimensions);

  return dimensions;
}

/**
 * Get the pixel size value of the current AV, by reading its metadata
 */
function getSizeForAV(sizeAV: AttributeValue) {
  return {
    width: parseFloat(findMetadataValue(sizeAV, METADATA_WIDTH) ?? '0'),
    height: parseFloat(findMetadataValue(sizeAV, METADATA_HEIGHT) ?? '0')
  };
}

/**
 * Given a UGC CA, find its associated size CA if it exists.
 * It must be a sibling (both have a common parent) and its values must contain the correct metadata
 */
function findSiblingCA(
  configure: ConfigureUI,
  ugcCA: ConfigureAttribute,
  predicate: (ca: ConfigureAttribute) => boolean
): ConfigureAttribute | undefined {
  // Find the parent CA
  if (!ugcCA.parentId) return undefined;
  const parentCA = configure.getAttribute({ id: ugcCA.parentId });
  if (!parentCA?.subAttributes) return undefined;

  // Find the size CA in the parent
  return parentCA.subAttributes.find(predicate);
}

/**
 * Determines if the provided CA is a Size CA.
 *
 * It is a Size CA if **all** its values are Size AVs.
 */
function isSizeCA(ca: ConfigureAttribute): boolean {
  return ca.values && ca.values.length > 0 && ca.values.every(isSizeAV);
}
/**
 * Determines if the provided CA is a Location CA.
 *
 * Its alias must end with ".location"
 */
function isLocationCA(ca: ConfigureAttribute): boolean {
  return ca.alias.endsWith(ALIAS_SUFFIXES.LOCATION);
}

/**
 * Determines if the AV is a Size AV.
 *
 * It is a Size AV if it contains all the required size metadata
 */
function isSizeAV(av: AttributeValue): boolean {
  if (!av.metadata) return false;

  // The AV must contain a metadata entry for every required key (METADATA_KEYS)
  return METADATA_KEYS.every((key) => av.metadata?.some((entry) => entry.key === key));
}
