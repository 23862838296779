import { AttributeValue, hasFacetValue } from '@/configure/model/AttributeValue';
import { BOOLEAN_AV_FACETS } from '@/constants';

/**
 * Determines if this AV has a boolean value of `True`, using the "Boolean" facet
 */
export function isTrueAV(av: AttributeValue): boolean {
  return hasFacetValue(av, BOOLEAN_AV_FACETS.FACET_ID, BOOLEAN_AV_FACETS.TRUE_ID);
}

/**
 * Determines if this AV has a boolean value of `False`, using the "Boolean" facet
 */
export function isFalseAV(av: AttributeValue): boolean {
  return hasFacetValue(av, BOOLEAN_AV_FACETS.FACET_ID, BOOLEAN_AV_FACETS.FALSE_ID);
}
