import { buildInfoTooltip } from '@/components/tooltip';
import { ConfigureUI } from '@/configure/ConfigureUI';
import { sleep } from '@/configure/utils/general';
import { makeFocusableAll } from '@/utils/accessibility';

export async function createPager(configure: ConfigureUI, container: string, mediaQuery?: string): Promise<void> {
  configure.on('analytics:configureLoaded', () => attachPagerUIEvent(configure));

  const pager = await configure.createComponent({
    type: 'pager',
    mediaQuery,
    showGroupName: false,
    menuByIndex: false,
    skipHeaders: true,
    skipHeadersPageParenthesis: true,
    container,
    uiSettings: {
      attributeValueTooltip: false,
      descriptionTooltip: {
        extended: true,
        content: buildInfoTooltip,
        position: 'left'
      }
    }
  });

  let overflow = document.body.style.overflow;

  // Fix background scroll bug when open side panel.
  pager.on('pager:openList', () => {
    overflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    const groupers = configure.dom.querySelectorAll('.fc-pager-grouper');
    if (!groupers) return;

    groupers.forEach((group) =>
      // Stop event propagation when user click on Group
      group.addEventListener('click', (e) => e.stopPropagation())
    );
  });

  // Reset scroll strategy after dialog is closed.
  pager.on('dialog:closed', () => (document.body.style.overflow = overflow));

  pager.on('dialog:opened', () => {
    makeFocusableAll('.fc-pager-attribute-list .fc-pager-pulldown-list [role="menuitemradio"]');
  });

  // We need to sync the index everytime it changes
  pager.on('attributeList:setId', updateIndexTotal);
  pager.on('attributeList:setIndex', updateIndexTotal);

  // Also when the recipe changes, as it may add new CAs and the total will change
  configure.on('recipe:change', updateIndexTotal);

  pager.on('component:created', async () => {
    // Waits for pager DOM to render and for step label to update
    await sleep(0);
    const [attributeListIndex, totalAttributes] = getStepAndTotalFromDOM();
    createIndex(container, attributeListIndex, totalAttributes);
  });

  /**
   * Sync the new step/total label with the native one from the pager
   */
  async function updateIndexTotal() {
    // Update the value on html element.
    const pageNumber = configure.dom.querySelector(`${container} .fc-pager-adi-page`);
    if (pageNumber) {
      // Waits for the step label to update before parsing it
      await sleep(0);
      const [attributeListIndex, totalAttributes] = getStepAndTotalFromDOM();
      pageNumber.innerHTML = getPageNumberTemplate(attributeListIndex, totalAttributes);
    }
  }

  /**
   * Finds the pager native "step/total" label, parse it and returns its data
   */
  function getStepAndTotalFromDOM(): [number, number] {
    // Parse the pager native label
    const text = configure.dom.querySelector('.fc-pager .fc-pager-header .fc-pager-page-number')?.textContent;
    if (!text) return [0, 0];
    return text.split('/').map(Number) as [number, number];
  }

  /**
   *
   * Legacy index from ConfigureID is placed under the hamburger icon.
   * This method put the pager string (page/total) between the pager arrows.
   *
   * <-- 2/5 -->
   *
   * We hides the original page number applying color:transparent to .fc-pager-page-number by CSS
   *
   * @param container Pager container
   * @param initialIndex The selected index on pager.
   * @param total Number of pages
   * @returns
   */
  function createIndex(container: string, initialIndex: number, total: number) {
    const pagerButtons = configure.dom.querySelector(`${container} .fc-pager-buttons`);
    if (!pagerButtons) return;

    const nextButton = configure.dom.querySelector(`${container} .fc-pager-next-wrapper`);
    if (!nextButton) return;

    const pageNumber = document.createElement('div');
    pageNumber.setAttribute('class', 'fc-pager-adi-page');
    pageNumber.innerHTML = getPageNumberTemplate(initialIndex, total);
    pagerButtons.insertBefore(pageNumber, nextButton);
  }
}

/**
 * Build and return the page html element: index/total
 *
 * @param index Current index
 * @param total Number of pages
 * @returns
 */
function getPageNumberTemplate(index: number, total: number) {
  return `<div class=fc-pager-page>${index}</div><div class=fc-pager-adi-page-divisor>/</div><div class=fc-pager-adi-page-pages>${total}</div>`;
}

// TODO: Analyze this event handler
function attachPagerUIEvent(configure: ConfigureUI) {
  // If an user clicks on Attribute Name a popup with all the attributes will open
  configure.dom.addEventListener('.fc-pager-page-name', 'click', function () {
    const button: HTMLElement | null = configure.dom.querySelector('.fc-pager-pulldown > .fc-button-pair');
    button?.click();
  });
}
