import { ConfigureEventDispatcher } from '@/ConfigureEventDispatcher';
import { ConfigureUI } from '@/configure/ConfigureUI';
import { AttributeValuePair } from '@/configure/types/configureui-types';

/**
 * this feature is to address ADIDAS-131 "bug", that is:
 * after user has changed a text av, recipe change of that av is delayed after it has checked the profanity filter
 * thus if clicking add-to-cart fast enough, av would be empty or outdated
 *
 * the solution is to send two events,
 * one: to disable add-to-cart when a validation starts
 * two: to enable it back when it has either validated, or reverted to previous value
 */

const TEXT_FIELD_INPUT_DATA_ATTRIBUTE = 'fc-text-field-input';

/**
 * Function that detects when the text validation finishes, either successfully (recipe:change) or with error (profanity dialog).
 * This is useful to enable/disable buttons while the validation is being executed (it calls a remote service).
 *
 * This will be implemented as an event in ConfigureUI in the future, but for now we need to create this logic.
 */
export async function textFieldValidate(
  dispatcher: ConfigureEventDispatcher,
  configure: ConfigureUI,
  hookContainer: HTMLElement
): Promise<void> {
  const input: HTMLInputElement | null = hookContainer.querySelector(
    `[data-custom-input='${TEXT_FIELD_INPUT_DATA_ATTRIBUTE}']`
  );
  if (!input) return;
  const caAlias = hookContainer?.dataset?.['ca'] ?? '';
  if (!caAlias) return;

  // console.log('caAlias :: ', caAlias, ' current input value :: ', input.value);

  // Notify validation started (to disable add to cart)
  dispatcher.dispatchEvent(new CustomEvent('text-validation-start'));

  // This promise will be resolved when the validation finished
  const validationPromise = new Promise((resolve) => {
    // Cleans up the event handlers and resolve the promise
    function finish(msg: string) {
      configure.off('recipe:change', handleSuccess);
      configure.off('dialog:opened', handleProfanity);
      resolve(msg);
    }

    // Handler for valid text (recipe:change)
    function handleSuccess(changes: AttributeValuePair[]) {
      if (changes.some((change) => change.ca.alias == caAlias)) {
        finish('Valid Text');
      }
    }

    // Handler for validation failed (when the profanity dialog is shown)
    const handleProfanity = () => finish('Profanity');

    //Add event listeners.
    // We use once for the dialog, because we only care about the first dialog shown
    // For recipe change, we may get many, so we use "on" instead.
    configure.on('recipe:change', handleSuccess);
    configure.once('dialog:opened', handleProfanity);

    // Just in case, add a time out to re-enable the button
    setTimeout(() => finish('Time Out'), 4000);
  });

  await validationPromise;

  // Notify validation finished (to enable add to cart again)
  dispatcher.dispatchEvent(new CustomEvent('text-validation-finish'));
}
