import { NodeCallback } from './types';

export function promisify<A>(fn: (cb: NodeCallback<Error, A>) => void): Promise<A> {
  return new Promise((resolve, reject) => {
    fn((err, callbackArgs) => {
      if (err) return reject(err);
      resolve(callbackArgs!);
    });
  });
}

/**
 * Calls a sync function and resolves with the result or rejects with the error.
 *
 * It's just a convenience function to wrap the call in try/catch and transform it into a promise
 * @param resolve Promise resolve method
 * @param reject Promise reject method
 * @param fn function to call
 */
export function handleAsPromise<T>(
  resolve: (result: T | PromiseLike<T>) => void,
  reject: (reason?: unknown) => void,
  fn: () => T
): void {
  try {
    resolve(fn());
  } catch (err) {
    reject(err);
  }
}

/**
 * Determine whether the given `promise` is a Promise.
 */
export function isPromise<T>(promise: T | PromiseLike<T>): promise is Promise<T> {
  return !!promise && typeof (promise as PromiseLike<T>).then === 'function';
}
