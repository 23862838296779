/**
 * Add upcharge label under every swatch or button element when the CA has upcharge greater than 0.
 */

import { ConfigureUI } from '@/configure/ConfigureUI';
import { AttributeValue } from '@/configure/model/AttributeValue';
import { ConfigureAttribute } from '@/configure/model/ConfigureAttribute';
import { t } from '@/i18n';
import { updateDOMForCA } from '@/utils/dom';

/** Selector parent of swatches/buttons */
const FC_ATTRIBUTE_SELECTOR = 'fc-attribute-selector';

/** Class used for marking an attribute selector has upcharge */
const FC_HAS_UPCHARGE = 'fc-has-upcharge';

export async function displayUpchargeOnAttributeValue(configure: ConfigureUI): Promise<void> {
  // Display upcharge when CA or CA's subAttributes has upcharge
  updateDOMForCA(configure, {
    predicate: hasUpcharge,
    update,
    evaluateSubAttributes: true
  });

  /** Return true when a value of ConfigureAttribute has upcharge greater than 0. */
  function hasUpcharge(ca: ConfigureAttribute): boolean {
    return ca.values?.some((value) => value.upcharge > 0) && !isUpchargeAdded(ca);
  }

  /**
   * Return true if charge is being displayed
   *
   * @param ca
   */
  function isUpchargeAdded(ca: ConfigureAttribute) {
    return configure.dom.querySelector(`[class^="${FC_ATTRIBUTE_SELECTOR}"].${FC_HAS_UPCHARGE}[data-id="${ca.id}"]`);
  }

  /**
   * Add upcharge under swatches or buttons
   *
   * @param ca
   */
  function update(ca: ConfigureAttribute) {
    switch (ca.selectorType) {
      case 'swatch':
        addUpchargeTo(ca, (value) => `.fc-attribute-value-swatch[data-value="${value.id}"] .fc-swatch-title`);
        break;
      case 'buttongroup':
        addUpchargeTo(ca, (value) => `.fc-button-selector[data-value="${value.id}"] .fc-button`);
        break;
    }
  }

  /**
   * Iterate every value of ConfigureAttribute, get the dom component where that value is displayed and add upcharge-label under it.
   *
   * @param ca ConfigureAttribute instance
   * @param childSelector return the query selector for the swatch/button component
   * @returns
   */
  function addUpchargeTo(ca: ConfigureAttribute, childSelector: (value: AttributeValue) => string) {
    // Get the attribute dom element
    const selector = configure.dom.querySelector(`[class^="${FC_ATTRIBUTE_SELECTOR}"][data-id="${ca.id}"]`);
    if (!selector) return;

    // Add has-upcharge class to attribute dom element
    selector.classList.add(FC_HAS_UPCHARGE);

    // Iterate every ca value
    ca.values.forEach((value) => {
      // Get the swatch or button element from the dom
      const element = configure.dom.querySelector(
        `[class^="${FC_ATTRIBUTE_SELECTOR}"][data-id="${ca.id}"] ${childSelector(value)}`
      );
      if (!element) return;

      // Add upcharge level
      element.innerHTML += /*html */ `
    <div class="upcharge-label">${value.upcharge === 0 ? t('free') : configure.formatPrice(value.upcharge)}</div>
    `;
    });
  }
}
