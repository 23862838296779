import { LOCALIZATION } from '@/constants';

/**
 * Retrieves the locale to be used in the configurator.
 *
 * If the locale is avaiable, it will be used.
 * Otherwise, the default locale for the provided language is used.
 *
 * If the language has no default, the global default locale is used
 */
export function resolveLocale(localeParam: string | undefined): string {
  const locale = findLocale(localeParam);
  console.log('[i18n] Using locale "%s"', locale);
  return locale;
}

function findLocale(localeParam: string | undefined): string {
  // If no locale was specified, use the global default
  if (!localeParam) return LOCALIZATION.defaultLocale;

  // If the locale is available, use it
  if (LOCALIZATION.locales.includes(localeParam)) return localeParam;

  // Try to find the default for the language
  const lang = localeParam.split('_')[0] ?? '';
  const defaultLocale = LOCALIZATION.defaults[lang];

  // Use the default for the language or, if it doesn't exist, use the global default
  return defaultLocale ?? LOCALIZATION.defaultLocale;
}
