import { ConfigureInitParams } from './types/configureui-types';

/** Configure Loader Fn, provided by "configureApp" event payload */
type ConfigureLoaderFn = (params: ConfigureInitParams, cb: (error: Error | undefined, api: unknown) => void) => void;

/** Configure Factory function global variable  */
declare global {
  interface Window {
    _configureFactory?: ConfigureLoaderFn;
  }
}

/**
 * The result of creating a ConfigureUI instance.
 */
interface ConfigureLoaderResult {
  /** If defined, the error that ocurred while creating COnfigureUI */
  error?: Error;

  /** The actual instance. It will be returned even when an error occurs */
  api: unknown;
}

/**
 * Creates a new instance of ConfigureUI, using the provided parameters.
 *
 * It returns a promise, as it needs to wait for the Configure Script to be ready and then for the instance to be created.
 * The result contains both the instance (api) and an error if something went wrong
 */
export async function newConfigureInstance(params: ConfigureInitParams): Promise<ConfigureLoaderResult> {
  const load = await configureLoader;
  return new Promise((resolve) => {
    load(params, (error: Error | undefined, api: unknown) => {
      // ConfigureUI API is always returned, even when there's an error, so a promise wil
      // so promisify wont work in this case
      resolve({ error, api });
    });
  });
}

/**
 * Retrieves the Configure loader Function.
 *
 * This function can be obtained in two ways:
 * - In a global variable. In that case, we retrieve it and delete the global variable.
 * - In the payload of the "configureApp" event, triggered when ConfigureUI script is ready.
 *    So we must listen for that event (only once) and obtain the loader fn, so we can then create as many instances of configure as we want.
 */
const configureLoader = new Promise<ConfigureLoaderFn>((resolve) => {
  // If the factory fn is defined in the global scope, the event has already been triggered.
  // Remove it from the global scope and return it
  if (window._configureFactory) {
    const factory = window._configureFactory;
    delete window._configureFactory;
    resolve(factory);
  } else {
    document.addEventListener(
      'configureApp',
      ((e: CustomEvent<{ configureApp: ConfigureLoaderFn }>) => {
        // Just in case, remove the global fn as well
        delete window._configureFactory;
        resolve(e.detail.configureApp);
      }) as EventListener,
      { once: true }
    );
  }
});
