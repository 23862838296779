import { isPromise } from '@/configure/utils/promise';
import { ConfigureEmptyEvents, ConfigureEventMap, ConfigureUIEvent } from '..';

interface EventOnce<T, P> {
  once(event: T, payload: P): void;
}

/**
 * Creates a promise that resolves the first time this event is dispatched
 * @param eventbus the event bus to listen on or a promise that will resolve to that event bus
 * @param name name of the event to listen to
 *
 * @returns a promise that will resolve to the event payload when it is dispatched the first time
 */
export function eventToPromise<T extends ConfigureEmptyEvents>(eventbus: BusOrPromise<T, void>, name: T): Promise<void>;
export function eventToPromise<T extends ConfigureUIEvent>(
  eventbus: BusOrPromise<T, EventPayload<T>>,
  name: T
): Promise<EventPayload<T>>;
export function eventToPromise(eventbus: BusOrPromise<string, unknown>, name: string): Promise<unknown> {
  return new Promise((resolve) => {
    if (isPromise(eventbus)) {
      void eventbus.then((bus) => bus.once(name, resolve));
    } else {
      (eventbus as EventOnce<string, unknown>).once(name, resolve);
    }
  });
}

type BusOrPromise<T, P> = EventOnce<T, P> | PromiseLike<EventOnce<T, P>>;
type EventPayload<T extends ConfigureUIEvent> = Parameters<ConfigureEventMap[T]>;
