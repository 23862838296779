import { ConfigureUI } from '@/configure/ConfigureUI';
/**
 * Utils methods for accessibility
 */

/**
 * Make target element focusable.
 *
 * A focusable element can be selected using tab key.
 *
 * @param target HTML Element
 * @param tabindex tabindex value. If the value is not set, the default value will be 0.
 * @returns the target
 */
export function makeElementFocusable(target: Element, tabindex = 0): Element {
  target.classList.add('fc-outline-target');
  target.setAttribute('tabindex', tabindex.toString());
  return target;
}

/**
 * Become focusable the first element into parent that matches the selector.
 *
 * If no matches are found, null is returned.
 *
 * If the parent is not specified, the selector is executed from document object.
 *
 * @param selector query selector
 * @param parent the container. I f it isn't specified, the query selector is executed from document
 * @param tabindex tabindex value. If the value is not set, the default value will be 0.
 * @returns
 */
export function makeFocusable(
  selector: string,
  configure?: ConfigureUI,
  parent?: Element | null,
  tabindex = 0
): Element | null {
  const container = parent ?? configure?.dom ?? document;
  const element: Element | null = container.querySelector(selector);
  if (!element) return null;
  makeElementFocusable(element, tabindex);
  return element;
}

/**
 * Become focusable all the elements into parent that matches the selector.
 *
 * If the parent is not specified, the selector is executed from document object.
 *
 * @param selector query selector
 * @param parent the container. I f it isn't specified, the query selector is executed from document
 * @param tabindex tabindex value. If the value is not set, the default value will be 0.
 * @returns the focusable elements
 */
export function makeFocusableAll(
  selector: string,
  configure?: ConfigureUI,
  parent?: Element | null,
  tabindex = 0
): NodeListOf<Element> | null {
  const container = parent ?? configure?.dom ?? document;
  const focusableElements: NodeListOf<Element> | null = container.querySelectorAll(selector);
  focusableElements.forEach((element) => {
    makeElementFocusable(element, tabindex);
  });

  return focusableElements;
}

/**
 * Disable focusable from target by removing fc-outline-target and setting tabindex to -1 if it not set as an argument.
 *
 * @param target Element to become not focusable
 * @param tabindex tab index value. by default it is -1
 * @returns the target element
 */
export function removeElementFocusable(target: Element, tabindex = -1): Element {
  target?.classList.remove('fc-outline-target');
  target?.setAttribute('tabindex', tabindex.toString());
  return target;
}

/**
 * Disable focusable from all elements in parent than matches selector.
 *
 * If the parent is not specified, the selector is executed from document object.
 *
 * @param selector query selector
 * @param tabindex tab index value. by default it is -1
 * @returns the target element
 */
export function removeFocusable(selector: string, configure?: ConfigureUI, parent?: Element | null): Element | null {
  const container = parent ?? configure?.dom ?? document;
  const element: Element | null = container.querySelector(selector);
  if (!element) return null;
  removeElementFocusable(element);
  return element;
}

/**
 * Disable focusable property from the first element into parent that matches the `from` selector.
 *
 * Enable focusable property from the first element into parent that matches the `to` selector.
 *
 * Add a Enter keydown lister in **to** element. Every time this event is triggered, it will trigger the click event in **from** element.
 *
 * @param from query selector for `from` element. Any query selector is allowed.
 * @param to query selector for `from` element. Any query selector is allowed.
 * @param parent the container. I f it isn't specified, the query selector is executed from document.
 * @param tabindex tab index value. by default it is -1.
 * @returns the toElement
 */

export function changeFocusable(
  from: string,
  to: string,
  configure?: ConfigureUI,
  parent?: Element | null,
  tabindex = 0
): Element | null {
  const container = parent ?? configure?.dom ?? document;
  const toElement: Element | null = container.querySelector(to);
  if (!toElement) return null;

  // Add a Enter keydown lister in to element. Every time this event is triggered, it will trigger the click event in from element.
  if (!toElement?.classList.contains('fc-outline-target') && toElement instanceof HTMLElement) {
    toElement?.addEventListener('keydown', (e) => {
      if (isEnterOrSpace(e) && fromElement instanceof HTMLElement) fromElement?.click();
    });
  }
  makeElementFocusable(toElement, tabindex);
  const fromElement = removeFocusable(from, configure, parent);

  toElement?.querySelector('.fc-outline-target')?.removeAttribute('tabIndex');
  return toElement;
}

export function isEnterOrSpace(e: Event): boolean {
  return e instanceof KeyboardEvent && ['Enter', 'Space'].includes(e.code);
}
