import { ConfigureUI } from '@/configure/ConfigureUI';
import { interpolate } from '@/configure/utils/text';
import { updateDOMForCA } from '@/utils/dom';

/** Class to identify the square text swatches */
const SQUARE_SWATCH_CLASS = 'fc-adi-square-button';

/** Selector to select the text inside the swatch */
const SWATCH_TEXT_SELECTOR_TEMPLATE = `.${SQUARE_SWATCH_CLASS} .fc-attribute-selector-button[data-id="{id}"] .fc-button-label`;

/** Amount of px to reduce the font size in each iteration */
const STEP_IN_PX = 2;

/**
 * Resize the font size of the text swatches (eg. Location) so that the the text fits inside the swatch box.
 *
 * Some languages (eg. German) have long words which don't fit inside the box using the regular font size.
 * So this function detects those swatches when they are rendered and reduce the font size in steps until it fits.
 */
export function fitSwatchesText(configure: ConfigureUI): void {
  const product = configure.getProduct();
  if (!product) return;

  // This var will store the original fontSize (taken from css)
  // Since retrieving it is a expensive operation, it will be done once and cached
  let classFontSize: number | undefined;

  // This helper fn will we triggered everytime the visible CAs change (on ca:focus and when a recipe change)
  updateDOMForCA(configure, {
    // Evaluate subattributes, since the one changing could be the parent toggle
    evaluateSubAttributes: true,
    // Only work on CAs with the square swatch class
    predicate: (ca) => ca.selectorClass?.includes(SQUARE_SWATCH_CLASS) ?? false,
    update(ca) {
      // Get all the spans containing the swatches text
      configure.dom.querySelectorAll<HTMLElement>(interpolate(SWATCH_TEXT_SELECTOR_TEMPLATE, ca)).forEach((el) => {
        // Get the CSS font size if we haven't done it yet
        if (!classFontSize) classFontSize = parseFloat(window.getComputedStyle(el).getPropertyValue('font-size'));

        // If no parent, nothing to do
        const parentWidth = el.parentElement?.offsetWidth ?? 0;
        if (parentWidth === 0) return;

        // Initial size
        let fontSize = classFontSize;

        // Reduce the font size, one step at a time, until the text span is narrower than its parent (the swatch box)
        while (el.offsetWidth > parentWidth) {
          fontSize -= STEP_IN_PX;
          el.style.setProperty('font-size', fontSize + 'px');
        }
      });
    }
  });
}
