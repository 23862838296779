/**
 * This file serves 3 purposes:
 *  1. Provides the default label when the key is not found on the fetched uiSettings i18n
 *  2. Types the keys argument to the function t (translate), to only accept the ones here
 *  3. Helps the audit of the json file submitted to admin studio by the implementation, it should match this
 */
export default {
  // default configure components labels
  addToCartButton_label: "I'm done customizing",

  // general use; literal translations;
  yes: 'yes',
  no: 'no',
  cancel: 'cancel',
  copy: 'copy',
  copied: 'copied',
  continue: 'continue',
  enter_your_text: 'Enter Your Text',
  please_note: 'Please note',
  start_over: 'Start Over',
  add_to_cart: 'Add to Cart',
  share: 'Share',
  free: 'Free',

  // Errors
  error: 'Error',
  err_unknown: 'Unknown error',
  err_add_to_cart: 'An error occured while adding the product to cart. Please try again later',
  err_add_snapshot: 'An error ocurred while saving snapshot. Please try again later',
  err_invalid_recipe: 'Invalid recipe configuration',
  err_invalid_desc: 'Changed invalid value for attribute <strong>{ca}</strong> to <strong>{av}</strong>',
  err_recipe_not_found: 'Recipe not found',
  err_fallback_recipe: 'Fallback recipe will be loaded',
  err_loading_product: 'Error loading product with sent parameters',
  err_got_it: 'Got it',
  err_desktop_only: 'This Configurator is only available on Desktop devices',

  // dialogs
  start_over_desc: 'Your current design will be lost. Are you sure?',
  share_copy_link: 'Copy the link below',

  // tooltips - these might be dynamic, read as key from studio (saved in product) with function tDynamic,
  tt_text_input: `<b>Adidas may not accept the name you provide</b> if you use words that we believe are inappropriate for posting on our products or if you use a celebrity name that Adidas does not have right to use. <br><br>
    If the personalization combination is declined, your order will be canceled and you will be notified via email. If you have further questions, please contact our Customer Service.`,

  // layout
  ly_style: 'Style',
  ly_style_desc: 'Express yourself with custom colors, graphics and more.',
  ly_personalize: 'Personalize',
  ly_personalize_desc: 'Make it your own with your team name, crest, and more.',
  ly_more_actions: 'More Actions',
  ly_rotate_zoom: 'Rotate and zoom',
  ly_share_design: 'Share your design',
  ly_share_design_link: 'Get a link',
  ly_download_snapshots: 'Snapshots',

  // used in features/league-rules
  lr_compliance: 'compliance',
  lr_instructions_1: 'To get started, make your compliance choice below',
  lr_instructions_2: 'to change the Compliance setting you have to click',

  // used in features/image-gallery
  ig_upload_image: 'Upload Image',
  ig_remove_image: 'Remove Image',
  ig_previously: 'Choose an image you have previously uploaded',
  ig_my_image_gallery: 'My Image Gallery',
  ig_rules: 'Or you can upload a new PDF or Adobe Illustrator file',
  ig_different_image: 'Select a different image from gallery',
  ig_selected_image: 'Selected Image',

  // more actions
  ma_view_snapshots: 'Scratch pad',
  ma_capture_design: 'Capture Your Design',
  ma_take_a_snapshot: 'Save a version </a> and review it while designing',

  // used in product personalization - toggle (features/personalization/toggle)
  pp_add: 'Add',
  pp_remove: 'Remove',
  pp_team_name_add: 'Add team name',
  pp_team_name_remove: 'Remove team name',
  pp_player_name_add: 'Add player name',
  pp_player_name_remove: 'Remove player name',
  pp_player_number_add: 'Add player number',
  pp_player_number_remove: 'Remove player number',
  pp_player_initials_add: 'Add player initials',
  pp_player_initials_remove: 'Remove player initials',
  pp_team_slogan_add: 'Add team slogan',
  pp_team_slogan_remove: 'Remove team slogan',
  pp_team_crest_add: 'Add team crest',
  pp_team_crest_remove: 'Remove team crest',
  pp_sponsor_logo_add: 'Add sponsor logo',
  pp_sponsor_logo_remove: 'Remove sponsor logo',
  pp_team_wordmark_add: 'Add team wordmark',
  pp_team_wordmark_remove: 'Remove team wordmark',
  pp_front_logo_add: 'Add front logo',
  pp_front_logo_remove: 'Remove front logo',
  pp_back_logo_add: 'Add back logo',
  pp_back_logo_remove: 'Remove back logo',

  // used in product personalization - font-preview (features/personalization/font-preview)
  pp_preview_font: 'Preview font',
  pp_preview_font_example: 'Impossible is nothing',

  // used in product personalization - location conflicts (features/personalization/location_conflicts)
  pp_location_av_conflict: 'This location is already occupied by <strong>{ca}</strong>', // ??
  pp_location_ca_conflict: 'All locations for <strong>{ca}</strong> are already occupied', // ??

  // used in generate snapshots dialog
  sn_dialog_title: 'Download Snapshots',
  sn_dialog_instructions: 'Select the views and click "Download"',
  sn_dialog_select_all: 'Select All',
  sn_dialog_settings_format: 'Format',
  sn_dialog_settings_size: 'Size',
  sn_dialog_file_suffix: 'Snapshots',
  sn_dialog_loading: 'Downloading...',
  sn_dialog_accept: 'Download',
  sn_view_current: 'Current',
  sn_view_front: 'Front',
  sn_view_back: 'Back',
  sn_view_right: 'Right',
  sn_view_left: 'Left',
  sn_view_hero: 'Hero',
  sn_view_default: 'Default',
  sn_view_catalog: 'Catalog',
  sn_view_side: 'Side',
  sn_view_left_leg: 'LeftLeg',
  sn_view_right_leg: 'RightLeg',
  sn_view_logo: 'Logo',
  sn_view_chest: 'Chest',
  sn_view_stripes: 'Stripes',

  // validation messages
  validation_error_dialog_title: 'Missing Details',
  validation_error_dialog_description:
    'Please add the missing information or remove the selected personalization option.',
  validation_error_missing_font: 'Did you forget to select a <strong>font</strong> for <strong>{group}</strong>?',
  validation_error_missing_color: 'Did you forget to select a <strong>color</strong> for <strong>{group}</strong>?',
  validation_error_missing_location:
    'Did you forget to select a <strong>location</strong> for <strong>{group}</strong>?',
  validation_error_missing_size: 'Did you forget to select a <strong>size</strong> for <strong>{group}</strong>?',
  validation_error_missing_application:
    'Did you forget to select an <strong>application type</strong> for <strong>{group}</strong>?',
  validation_error_missing_text: 'Did you forget to enter a <strong>value</strong> for <strong>{group}</strong>?',
  validation_error_missing_upload: 'Did you forget to upload an <strong>image</strong> for <strong>{group}</strong>?',
  validation_error_missing_generic: 'Did you forget to enter <strong>{ca}</strong> for <strong>{group}</strong>?'
};
