import { ConfigureUI } from '@/configure/ConfigureUI';

// Loader DOM parameters
const LOADER_CONTAINER_SELECTOR = '.webgl-loader-wrapper';
const LOADER_CIRCLE_CLASS = 'webgl-loader-bar';
const LOADER_SIZE = 40;
const LOADER_RATIO = 17.5;

const LOADER_FINISHED_CLASS = 'loaded';
const LOADER_SPINNER_CLASS = 'webgl-loader-spinner';

/**
 * When the model loading progress is `>=` to this value, the loader transforms into a spinner,
 * because the remaining loading time is not fully known.
 * - Using `0` makes the loader ALWAYS behave like a spinner
 * - Using `101` makes the loader use the % to fill the circle
 */
const LOADER_SPINNER_THRESHOLD = 101;

/**
 * Creates the Display Loader for WebGL products
 */
export function initDisplayLoader(configure: ConfigureUI): void {
  const loaderContainer = configure.dom.querySelector(LOADER_CONTAINER_SELECTOR);

  // If there's loader, create the DOM and initialize it
  if (loaderContainer) {
    loaderContainer.innerHTML = DisplayLoader(LOADER_SIZE, LOADER_RATIO);
    setDisplayLoaderProgress(configure, 0);
  }
}

function DisplayLoader(size: number, ratio: number) {
  const halfSize = size / 2;
  const circumference = Math.PI * (ratio * 2);

  return /* html */ `
  <div class="webgl-loader-content" data-pct="0">
    <svg
      class="webgl-loader-svg"
      width="${size}"
      height="${size}"
      viewPort="0 0 ${halfSize} ${halfSize}"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        class="webgl-loader-filler"
        r="${ratio}"
        cx="${halfSize}"
        cy="${halfSize}"
        fill="transparent"
        stroke-dashoffset="0"></circle>
      <circle
        class="${LOADER_CIRCLE_CLASS}"
        r="${ratio}"
        cx="${halfSize}"
        cy="${halfSize}"
        fill="transparent"
        stroke-dasharray="${circumference}"
        stroke-dashoffset="0"></circle>
    </svg>
  </div>
  `;
}

export function setDisplayLoaderIndeterminate(configure: ConfigureUI, visible: boolean): void {
  const classes = configure.dom.querySelector(LOADER_CONTAINER_SELECTOR)?.classList;
  if (!classes) return;
  classes.toggle(LOADER_FINISHED_CLASS, !visible);
  classes.toggle(LOADER_SPINNER_CLASS, visible);
}

export function setDisplayLoaderProgress(configure: ConfigureUI, progress: number): void {
  const containerClasses = configure.dom.querySelector(LOADER_CONTAINER_SELECTOR)?.classList;
  const content = configure.dom.querySelector(LOADER_CONTAINER_SELECTOR + ' div[data-pct]');
  const circle = configure.dom.querySelector<HTMLElement>('.' + LOADER_CIRCLE_CLASS);

  if (isNaN(progress)) progress = 100;
  if (!containerClasses || !circle) return;

  // If not complete, remove the loaded class to show the loader again (when loading a different model on AV change)
  if (progress < 100) containerClasses.remove(LOADER_FINISHED_CLASS);

  // If progress is greater than the threshold, the product is loaded but processing, so remove % and spin the loader
  containerClasses.toggle(LOADER_SPINNER_CLASS, progress >= LOADER_SPINNER_THRESHOLD);

  const r = Number(circle.getAttribute('r') ?? 0);
  const c = Math.PI * (r * 2);

  progress = Math.min(Math.max(progress, 0), 100);

  const pct = ((100 - progress) / 100) * c;

  circle.style.strokeDashoffset = pct.toString();

  if (content) content.setAttribute('data-pct', Math.round(progress).toString());

  // If done, hide the loader
  if (progress === 100) {
    containerClasses.add(LOADER_FINISHED_CLASS);
    containerClasses.remove(LOADER_SPINNER_CLASS);
  }
}
