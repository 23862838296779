import { ConfigureUI } from '@/configure/ConfigureUI';
import { downloadBlob } from '@/configure/utils/file';
import { camelToSnakeCase, camelize } from '@/configure/utils/text';
import { ConfigureWebGL, SnapshotSettings } from '@/configure/webgl';
import { SNAPSHOTS } from '@/constants';
import { t, tDynamic } from '@/i18n';
import { downloadZip } from 'client-zip';

const ALLOWED_VIEWS = SNAPSHOTS.VIEWS;

export interface GenerateSnapshotsOpts extends Omit<SnapshotSettings, 'cameraName'> {
  /**
   * List of views to generate snapshots for.
   *
   * - An empty array means "none"
   * - An `undefined` value means "all"
   */
  views?: string[];

  /* If `true`, the current state of the display is added to the list of snapshots to generate */
  includeCurrent?: boolean;

  /** Callback executed each time a snapshot is generated. Useful to display the progress when generating multiple images */
  onProgress?: (progress: { porcentage: number; completed: number; total: number }) => void;
}

export interface GenerateSnapshotOpts extends Omit<SnapshotSettings, 'cameraName'> {
  /* Name of the requested view. It's Optional. If `undefined`, the current state of the display is used */
  view?: string;
}

/**
 * Returns the list of cameras that can be used to get snapshots
 */
export async function getProductSnapshotCameras(configure: ConfigureUI): Promise<string[]> {
  const result: string[] = [];
  const camelCaseResult: string[] = [];

  const webgl = await configure.getConfigureWebgl();
  const allCameras = Object.keys(webgl.getCameras());

  for (const cameraName of allCameras) {
    // Get the camel case name
    const camelCaseCamera = camelize(cameraName);
    // If the camera is allowed (with exact case) and no variant of it was already added (either in snake case, camel case, etc), include it
    if (ALLOWED_VIEWS.includes(cameraName) && !camelCaseResult.includes(camelCaseCamera)) {
      result.push(cameraName);
      camelCaseResult.push(camelCaseCamera);
    }
  }
  return result;
}

/**
 * Download a single snapshot for a certain view.
 *
 * If no view is specified, the current state of the display is downloaded
 *
 * @param configure ConfigureUI instance
 * @param opts parameters used for `webgl.getSnapshot()`, but replacing `cameraName` with `view`
 * @returns a promise which will be resolved when the image has been downloaded
 */
export async function downloadSnapshot(configure: ConfigureUI, opts: GenerateSnapshotOpts = {}): Promise<void> {
  // If no view is specified, pass an empty array and set the "includeCurrent" flag to true
  const views = opts.view ? [opts.view] : [];
  return downloadSnapshots(configure, { ...opts, views, includeCurrent: opts.view === undefined });
}

/**
 * Downloads one or more snapshots of the product, one for each view specified.
 *
 * - If only one view is requested, the image will be downloaded directly.
 * - If more than one view is requested, a zip file containing all the files is downloaded.
 *
 * If the `views` array is not defined, ALL available views will be downloaded. Otherwise, the specified
 * views are downloaded. An empty array generates no image unless the "includeCurrent" flag is set
 *
 * @param configure ConfigureUI instance
 * @param opts parameters used for `webgl.getSnapshot()`, but replacing `cameraName` with `views` and adding
 * `includeCurrent` to include the current view
 */
export async function downloadSnapshots(configure: ConfigureUI, opts: GenerateSnapshotsOpts = {}): Promise<void> {
  try {
    // Get a list of all available cameras
    const cameras = await getProductSnapshotCameras(configure);

    // Default format
    if (!opts.format) opts.format = 'png';

    // If views is undefined, use ALL cameras unless the current camera is included
    if (!opts.views) {
      opts.views = opts.includeCurrent ? [] : cameras;
    }

    // If includeCurrent is true, add it to the list of cameras
    if (opts.includeCurrent) opts.views.unshift(getCurrentViewName());

    let completed = 0;

    const webgl = await configure.getConfigureWebgl();

    // Only allow CURRENT or cameras that exist in the product
    const validCameras = opts.views.filter((camera) => {
      if (camera === getCurrentViewName() || cameras.includes(camera)) return true;
      console.warn('Requested view "%s" not found, ignoring it', camera);
      return false;
    });

    // For each valid camera, generate the snapshot
    const entryPromises = validCameras.map((cameraName) =>
      getSnapshot(webgl, { ...opts, cameraName }).then((value) => {
        const total = opts.views?.length ?? 1;
        completed++;
        const porcentage = Math.round((100 * completed) / total);
        opts.onProgress?.({ total, completed, porcentage });
        return value;
      })
    );

    const entries = await Promise.all(entryPromises);

    const productName = configure.getProduct()?.name ?? 'Product';

    if (entries.length === 1) {
      // If only 1 entry, download the image directly
      downloadBlob(await entries[0]!.input.blob(), `${productName} - ${entries[0]!.name}`);
    } else if (entries.length > 1) {
      // If more than 1 entry, generate and download a zip file
      const zip = await downloadZip(entries).blob();
      downloadBlob(zip, `${productName} - ${t('sn_dialog_file_suffix')}.zip`);
    } else {
      // If no entries, warn and do nothing
      console.warn('No views were specified for the snapshots');
    }

    // If the user actually downloaded images, track the analytics event
    if (entries.length >= 1) {
      trackSnapshotAnalytics(configure, 'downloadImages', {
        selected_views: validCameras.map(camelize).join(';'),
        image_format: opts.format,
        image_size: `${opts.width ?? opts.height}x${opts.height ?? opts.width}`
      });
    }
  } catch (e) {
    throw new Error('Error while generating snapshots: ' + (e as Error).message);
  }
}

/**
 * Generate a single snapshot by calling webgl and then reads the blob URL
 * @param webgl
 * @param opts
 * @returns
 */
async function getSnapshot(webgl: ConfigureWebGL, opts: SnapshotSettings) {
  const extension = opts.format === 'jpeg' ? 'jpg' : opts.format;
  const name = `${getCameraLocalizedName(opts.cameraName ?? '')}.${extension}`;

  return (
    webgl
      // If the view is CURRENT, send undefined
      .getSnapshot({ ...opts, cameraName: opts.cameraName === getCurrentViewName() ? undefined : opts.cameraName })
      .then(fetch)
      .then((response) => {
        // Revoke the URL after using it
        URL.revokeObjectURL(response.url);
        return response;
      })
      .then((input) => ({ name, input }))
  );
}

/**
 * Tracks Configure Analytics events related to the "Download Snapshot" feature
 * @param configure
 * @param eventName custom event name
 * @param payload custom payload
 */
export function trackSnapshotAnalytics(
  configure: ConfigureUI,
  eventName: 'downloadImagesDialog' | 'downloadImages',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: Record<string, any>
): void {
  configure.analytics('customEvent', { name: eventName, payload });
}

/** Retrieves the localized name of the current view */
export function getCurrentViewName(): string {
  return 'current';
}

export function getCameraLocalizedName(cameraName: string): string {
  return tDynamic(`sn_view_${camelToSnakeCase(cameraName)}`);
}
