import { ConfigureUI } from '@/configure/ConfigureUI';
import { I18n } from '@/configure/i18n';
import { InterpolationVariables } from '@/configure/utils/text';
import defaultI18n from './defaults';

/** Allowed keys to use in the i18n's translate method */
export type I18nKeys = keyof typeof defaultI18n; //import('./defaults').default;

// Creates the i18n singleton using the implementation keys
// NOTE: this doesn't support 2 instances at the same time
let i18n = new I18n<I18nKeys>();

/** Initializes the i18n feature */
export function initI18n(configure: ConfigureUI): Promise<void> {
  // Create a new instance
  i18n = new I18n();

  // Clear the translations on destroy
  configure.once('destroy:start', () => i18n.destroy());

  // Load the translations
  return i18n.init(configure, defaultI18n);
}

/** Translates a text to the current locale */
export function t(key: I18nKeys, replacements?: InterpolationVariables, defaultT?: string): string {
  return i18n.t(key, replacements, defaultT);
}

/**
 * Same as `t` but allows dynamic keys (removes typescript key checking).
 *
 * **NOTE: Only use when the key is built dynamically, otherwise use `t` directly**
 */
export const tDynamic = t as (key: string, replacements?: InterpolationVariables, defaultT?: string) => string;
