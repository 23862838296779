import { ConfigureUI } from '@/configure/ConfigureUI';

/**
 * Adds the appropiate classes to the main Configure DOM container so external tools (eg. WebsitePulse)
 * can detect when certain events occur
 */
export function addPerformanceMarkerClasses(configure: ConfigureUI): void {
  configure.once('model:ready', () => configure.dom.container.classList.add('model-ready'));
  configure.once('display:ready', () => configure.dom.container.classList.add('display-ready'));
}

/**
 * Helper Class to track and measure code performance
 */
export class PerformanceMetrics {
  #marks: string[] = [];
  #measures: string[] = [];

  constructor(
    public prefix: string,
    public enabled: boolean = false
  ) {}

  /**
   * Logs performance metrics to the console
   */
  log(): void {
    if (!this.enabled) return;

    const measures = performance.getEntriesByType('measure').filter((m) => m.name.startsWith(this.prefix + ':'));
    measures.sort((a, b) => a.startTime + a.duration - (b.startTime + b.duration));

    measures.forEach((m) => {
      const name = m.name.replace(`${this.prefix}:measure:`, '').replaceAll(':', ' ');
      console.log(`[ConfigureAdidas] Timings: ${name}: Duration ${m.duration}ms (Started: ${m.startTime}ms)`);
    });
  }

  /**
   * Measure ConfigureUI metrics by listening to init events
   */
  measureConfigureUI(configure: ConfigureUI): void {
    if (!this.enabled) return;

    this.createMark('create');
    configure.once('api:ready', () => this.createMark('api:ready'));
    configure.once('dom:ready', () => this.createMark('dom:ready'));
    configure.once('model:ready', () => {
      this.createMark('model:ready');
      console.log('[ConfigureAdidas] Implementation Ready');
    });
    configure.once('display:ready', () => {
      this.createMark('display:ready');
      this.createMeasure('create', 'api:ready');
      this.createMeasure('create', 'dom:ready');
      this.createMeasure('create', 'model:ready');
      this.createMeasure('create', 'display:ready');
      if (this.hasMark('script:start')) this.createMeasure('script:start', 'display:ready');
    });
  }

  implementationCreate(): void {
    if (!this.enabled) return;

    this.createMark('implementation:create');
    console.log('[ConfigureAdidas] ConfigureAdidas Instance created');
  }

  implementationStart(): void {
    if (!this.enabled) return;

    this.createMark('implementation:start');
    console.log('[ConfigureAdidas] ConfigureAdidas Instance started');
  }
  implementationReady(): void {
    if (!this.enabled) return;

    this.createMark('implementation:ready');
    this.createMeasure('implementation:create', 'implementation:ready');
    this.createMeasure('implementation:start', 'implementation:ready');
    if (this.hasMark('script:start')) this.createMeasure('script:start', 'implementation:ready');
  }

  destroy(): void {
    this.#marks.forEach((m) => performance.clearMarks(m));
    this.#measures.forEach((m) => performance.clearMeasures(m));
  }

  private hasMark(name: string): boolean {
    const entries = performance.getEntriesByName(`${this.prefix}:${name}`);
    return entries.length > 0;
  }

  private createMark(name: string): void {
    const m = performance.mark(`${this.prefix}:${name}`);
    this.#marks.push(m.name);
  }

  private createMeasure(from: string, to: string): void {
    // If marks were cleared, do nothing
    if (this.#marks.length === 0) return;

    const m = performance.measure(
      `${this.prefix}:measure:from:${from}:to:${to}`,
      `${this.prefix}:${from}`,
      `${this.prefix}:${to}`
    );
    this.#measures.push(m.name);
  }
}
