import { ConfigureUI } from '@/configure/ConfigureUI';
import { AttributeValue } from '@/configure/model/AttributeValue';
import { ConfigureAttribute } from '@/configure/model/ConfigureAttribute';
import { ConfigureProduct } from '@/configure/model/ConfigureProduct';
import { filterByMetadataKey, findMetadataValue } from '@/configure/model/Metadata';
import { AttributeValuePair } from '@/configure/types/configureui-types';
import { KeysOfType } from '@/configure/utils/types';
import { isDefined } from '@/utils/general';

interface AssociationOpts {
  /* Key of the Metadata entry that specifies this AV ID in the association lists */
  idMetadataKey?: string;

  /** Key of the Metadata entry that specifies the associations */
  associatedValuesMetadataKey: string;

  /** AV property used in the metadata entry as Id */
  avProperty?: KeysOfType<AttributeValue, string | number>;

  /** Character used to separate the different AVs in the metadata entry */
  listSeparator: string;
}

type AssociationMap = Record<number, Array<{ id: number; associationId: string }>>;

/**
 * Class that process a list of association between AVs and allows to retrieve them and
 * find out currently selected AVs associations.
 *
 * To associate an AV with others, an entry must be added in the AVs metadata that contains the list of other AVs separated by
 * a special character.
 * Example: `metadata{ "conflicts_with": "center_front|center_chest_number|center_chest_logo|center_front_logo" }`
 */
export class ValueAssociationManager {
  #opts: AssociationOpts;
  #dispatcher: EventTarget = new EventTarget();

  /** Map that associates an AV (using its ID) with the others specified in its metadata */
  associationMap: AssociationMap = {};

  /** List of AVs (using their IDs) that are associated with the currently selected ones */
  selectionAssociatedAVs = new Set<number>();

  /** Attribute Value Pairs (using IDs) that are currently SELECTED in the recipe and which AV has associations */
  selectedAttributeValuePairs = new Set<AttributeValuePair<number, number>>();

  constructor(configure: ConfigureUI, opts: AssociationOpts) {
    this.#opts = opts;

    const product = configure.getProduct();
    if (!product) return;

    // Creates a map that associates an AV with the others specified in its metadata
    this.associationMap = this.buildAssociationMap(product);

    // Handle the recipe loading
    configure.on('recipe:loaded', (changes) => this.updateSelected(changes));

    // Handle the recipe changes
    configure.on('recipe:change', () => this.updateSelected(configure.getRecipe('json')));
  }

  private buildAssociationMap(product: ConfigureProduct): AssociationMap {
    // Get the list of required CAs (the ones which contains AVs with associations)
    const attributesWithAssociations = this.getProductCAsWithAssociations(product);

    /** Get all the values indexed by vendorId */
    const valuesMap: Record<string, AttributeValue> = this.getAllValuesWithAssociationId(product.attributes);

    // Iterates each AV of the CAs and finds the ones with the required metadata
    const associationMap: AssociationMap = {};
    for (const ca of attributesWithAssociations) {
      for (const av of ca.values) {
        const metadataValue = findMetadataValue(av, this.#opts.associatedValuesMetadataKey);
        // Split the string to get the list of associated AVs
        // If no entry is found, use an empty list
        const associatedAVs = metadataValue?.split(this.#opts.listSeparator) ?? [];

        // If the current AV contains an association ID and it's not in the list, add it.
        // A location will always conflict with others with the same id.
        const associationId = this.getValueId(av)?.toString();
        if (associationId && !associatedAVs.includes(associationId)) {
          associatedAVs.push(associationId);
        }

        const associatedValuesInfo = associatedAVs
          // Associate the id and association id (for logging purposes)
          .map((associationId) => ({
            id: valuesMap[associationId]?.id ?? 0,
            associationId
          }))
          // If the value ID is not defined, that AV is not used in this product, remove it from the map
          .filter((value) => isDefined(value.id));

        // If the AV contains conflicts in this product, add it to the map
        if (associatedValuesInfo.length > 0) {
          associationMap[av.id] = associatedValuesInfo;
        } else {
          console.warn(
            '[Location Conflict] AV %d contains the conflict metadata but none of the AVs are part of this product',
            av.id
          );
        }
      }
    }

    return associationMap;
  }

  /** Obtains the value association ID, either from a property or a metadata entry */
  private getValueId(av: AttributeValue) {
    if (this.#opts.avProperty) return av[this.#opts.avProperty];
    if (this.#opts.idMetadataKey) {
      return findMetadataValue(av, this.#opts.idMetadataKey);
    }
    return undefined;
  }

  private updateSelected(recipeItems: AttributeValuePair[]): void {
    // Clears the sets, as we are recreating them
    this.selectionAssociatedAVs.clear();
    this.selectedAttributeValuePairs.clear();

    recipeItems
      // For each selected value, if it has associations, add the other AVs to the "active" list
      .forEach(({ ca, av }) => {
        // Some recipe Items for some reason have no AV, we need to check.
        if (!av) return;

        const associatedAVs = this.associationMap[av.id];
        // If this AV has no associations, we don't care about it
        if (!associatedAVs) return;

        // Adds the AttributeValue pair to the list of selected.
        this.selectedAttributeValuePairs.add({ ca: ca.id, av: av.id });

        // Update the list of AVs associated with selected ones
        associatedAVs.forEach((av) => this.selectionAssociatedAVs.add(av.id));
      });

    // Dispatches the event to notify of the update, so the UI may be updated
    this.#dispatcher.dispatchEvent(new Event('update:selected'));
  }

  /**
   * Get the list of all CAs with associations
   */
  getProductCAsWithAssociations(product: ConfigureProduct): ConfigureAttribute[] {
    return product.attributes.flatMap((ca) => this.getCAsWithAssociations(ca));
  }

  /**
   * Given a CA, obtains the list of the CAs it contains with AV associations.
   * It includes itself and its subattributes.
   */
  getCAsWithAssociations(ca: ConfigureAttribute): ConfigureAttribute[] {
    const result: ConfigureAttribute[] = [];
    // Add this ca if it contains associations
    if (this.hasAssociationMetadata(ca)) result.push(ca);

    if (!ca.subAttributes) return result;
    // Add the subattributes with associations recursively
    result.push(...ca.subAttributes.flatMap((sca) => this.getCAsWithAssociations(sca)));
    return result;
  }

  /**
   * Determines whether this CA contains AVs with associations
   */
  private hasAssociationMetadata(ca: ConfigureAttribute | undefined): boolean {
    // If the association id is a metadata entry and it is defined in any AV, return true
    if (this.#opts.idMetadataKey && ca?.values?.some(filterByMetadataKey(this.#opts.idMetadataKey))) return true;

    // Return true if any AV contain association metadata
    return ca?.values?.some(filterByMetadataKey(this.#opts.associatedValuesMetadataKey)) ?? false;
  }

  addEventListener(type: 'update:selected', callback: (e: CustomEvent<Set<string>>) => void): void {
    this.#dispatcher.addEventListener(type, callback as EventListener);
  }
  removeEventListener(type: 'update:selected', callback: (e: CustomEvent<Set<string>>) => void): void {
    this.#dispatcher.removeEventListener(type, callback as EventListener);
  }

  /** Retrieves all the existing product's values that contain association ID, belonging to any CAs or subCAs */
  private getAllValuesWithAssociationId(attributes: ConfigureAttribute[]) {
    const valuesMap: Record<string, AttributeValue> = {};
    for (const ca of attributes) {
      for (const av of ca.values) {
        const avAssociationId = this.getValueId(av);
        if (avAssociationId) valuesMap[avAssociationId] = av;
      }
      if (ca.subAttributes) Object.assign(valuesMap, this.getAllValuesWithAssociationId(ca.subAttributes));
    }
    return valuesMap;
  }
}
