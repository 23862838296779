import { ConfigureUI } from '@/configure/ConfigureUI';
import { AttributeValue } from '@/configure/model/AttributeValue';
import { ConfigureAttribute, getSelectedValue } from '@/configure/model/ConfigureAttribute';
import { AttributeValuePair } from '@/configure/types/configureui-types';
import { sleep } from '@/configure/utils/general';
import { ALIAS_SUFFIXES } from '@/constants';
import { isFalseAV, isTrueAV } from '@/features/boolean-avs';
import { t } from '@/i18n';
import { changeFocusable, makeFocusable, makeFocusableAll } from '@/utils/accessibility';
import { filterByTruthy } from '@/utils/array';
import { isPartOfAttributeSet } from '.';
import { resetAttributes } from '../custom-reset-ca';

const TOGGLE_LABEL_SELECTOR_PREFIX = '#fc-label-text-';

// These are shared between instances but since all instances in a single pageload will
// have the same locale, these should always be the same
let addWord: string, removeWord: string;

/**
 * Implements the custom label for the Configurator toggle.
 *
 * It adds "Add"/"Remove" to the label, according to its state.
 *
 * Also it resets all the subattributes when a toggle is turned off.
 *
 * It is implemented by modifying the existing label (as its ID is known).
 * Since the label does not always exist, listening to 3 from ConfigureUI is required:
 * - When a CA is focused either on the accordion or on the pager, to update it
 * - When a recipe is initially loaded or changed,  since it can be done by code without user interaction
 *
 * @param configure ConfigureUI instance
 */
export function implementToggle(configure: ConfigureUI): void {
  // CA focus on accordion or pager
  configure.on('ca:focus', (data) => onCAFocus(configure, data.caId));
  configure.on('mediaQuery:change', () => {
    const activeCA = configure.getActiveCA();
    if (activeCA?.id) void onCAFocus(configure, activeCA.id);
  });

  // Recipe load or change
  configure.on('recipe:loaded', (changes) => handleRecipeChange(configure, changes));
  configure.on('recipe:change', (changes) => handleRecipeChange(configure, changes));

  // initialize default words to use in defaults, when value is not found
  addWord = t('pp_add');
  removeWord = t('pp_remove');
}

async function onCAFocus(configure: ConfigureUI, caId: number): Promise<void> {
  // We need to wait for the label to be created by React
  await sleep(0);

  // Get the CA and check if it's a toggle
  const ca = configure.getAttribute({ id: caId });
  if (!ca || !isToggleCA(ca)) return;

  // Update the toggle according to the CA selected value
  updateToggleLabel(configure, ca, ca.values.find(filterByTruthy('selected')));

  // Set tabindex=0 to active toggle
  changeFocusable(`input[name=checkbox${ca.id}]`, checkboxQuery(ca), configure);
  void updateFocusable(ca, configure);
}

/**
 * Handles the recipe load or change.
 * Checks if any of the changes include a toggle CA, updates the label if it exists and resets the subattributes when turned off
 */
function handleRecipeChange(configure: ConfigureUI, modifications: AttributeValuePair[]) {
  modifications
    .filter(({ ca }) => isToggleCA(ca))
    .forEach(({ ca, av }) => {
      // Sometimes the order of recipe:change events is wrong (eg using attribute sets),
      // So we ask configure for the current ca and selected av instead of relying on the modifications parameter
      ca = configure.getAttribute(ca) ?? ca;
      av = ca.values.find((av) => av.selected) ?? av;

      // Updates the toggle label
      updateToggleLabel(configure, ca, av);
      void updateFocusable(ca, configure);

      // If the toggle is turned off, reset all the subattributes, but ONLY if the CA is NOT part of an attribute set.
      // If it is, the logic changes as the items must "move up" instead of being resetted
      if (isFalseAV(av) && !isPartOfAttributeSet(ca)) {
        resetSubAttributes(configure, ca);
      }
    });
}

/**
 * Determines whether the CA is a toggle
 */
export function isToggleCA(ca: ConfigureAttribute): boolean {
  return ca.selectorType === 'checkbox' && ca.alias.endsWith(ALIAS_SUFFIXES.TOGGLE);
}

/**
 * Returns the list of toggle CAs that are "ON"
 */
export function getActiveToggles(configure: ConfigureUI): ConfigureAttribute[] {
  const product = configure.getProduct();
  if (!product) return [];

  return product.attributes.filter((ca) => {
    if (!isToggleCA(ca)) return false;
    const selectedValue = getSelectedValue(ca);
    return selectedValue && isTrueAV(selectedValue);
  });
}

/**
 * Updates the label if it exists (it may not)
 */
function updateToggleLabel(configure: ConfigureUI, ca: ConfigureAttribute, av?: AttributeValue) {
  const label = configure.dom.querySelector(TOGGLE_LABEL_SELECTOR_PREFIX + ca.id);
  if (label) label.textContent = getToggleLocalizedText(ca, av);
}

async function updateFocusable(ca: ConfigureAttribute, configure: ConfigureUI): Promise<void> {
  //Wiat for dom updated and hooks executed
  await sleep(100);

  //fc-outline-target is removed when toggle
  configure.dom.querySelector(checkboxQuery(ca))?.classList.add('fc-outline-target');
  //Change all tabindex that are not visible to -1
  configure.dom
    .querySelectorAll(`.fc-accordion-panel:not(.fc-is-open) .fc-attribute-selector [tabindex="0"]`)
    .forEach((element) => {
      element.setAttribute('tabindex', '-1');
    });

  // Make focusable the in location isn't hidden
  makeFocusableAll(
    `.fc-accordion-panel.fc-is-open .fc-adi-hide-first-av .fc-swatch-ca:not([style="display:none"])`,
    configure
  );

  makeFocusable('.fc-accordion-panel.fc-is-open .fc-attribute-selector-info-icon', configure);

  // Make focusable arc input
  changeFocusable(
    '.fc-accordion-panel.fc-is-open .fc-adi-text-decoration-arc input',
    '.fc-accordion-panel.fc-is-open .fc-adi-text-decoration-arc',
    configure
  );

  // Make focusable outline input
  changeFocusable(
    '.fc-accordion-panel.fc-is-open .fc-adi-text-decoration-outline input',
    '.fc-accordion-panel.fc-is-open .fc-adi-text-decoration-outline',
    configure
  );
}

/**
 * Resets the subattributes (set all values to default) of the provided CA.
 */
function resetSubAttributes(configure: ConfigureUI, ca: ConfigureAttribute) {
  const scas = configure.getAttribute({ id: ca.id })?.subAttributes;
  if (scas) resetAttributes(configure, scas);
}

/**
 * Gets the localized label for the CA and the selected value
 */
function getToggleLocalizedText(ca: ConfigureAttribute, av?: AttributeValue): string {
  const caKey = ca.alias.replace(ALIAS_SUFFIXES.TOGGLE, '');
  const isOn = av && isTrueAV(av);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const key: any = 'pp_' + caKey + (isOn ? '_remove' : '_add');
  return t(key, undefined, (isOn ? removeWord : addWord) + ' ' + ca.name?.toLocaleLowerCase());
}

/**
 * Return the query for getting .fc-checkbox element for a CA
 *
 * @param ca
 * @returns
 */
function checkboxQuery(ca: ConfigureAttribute): string {
  return `.fc-attribute-selector-checkbox[data-id="${ca.id}"] .fc-checkbox`;
}
