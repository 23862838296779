export function isUndefined(value: unknown): value is undefined {
  return value === undefined;
}

export function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isNotNull(value: unknown): boolean {
  return value !== null;
}

export function capitalizeText(inputText: string): string {
  // Split the input text into an array of words
  const words = inputText.split(' ');

  // Iterate through each word and capitalize the first letter
  const capitalizedWords = words.map((word) => {
    // Ensure the word is not empty
    if (word.length === 0) {
      return '';
    }
    // Capitalize the first letter and make the rest lowercase
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the capitalized words back into a single string
  const capitalizedText = capitalizedWords.join(' ');

  return capitalizedText;
}
