import svg from '@/assets/icons/exclamation.svg?raw';
import { ConfigureUI } from '@/configure/ConfigureUI';
import { ConfigureAdidas } from '@/ConfigureAdidas';
import { getMenuGroupsConfig, getSectionsSettings } from '@/features/menu-groups';
import { createPager } from '@/features/pager';
import { t } from '@/i18n';
import { waitForDom } from '@/utils/dom';
import { mq } from '@/utils/MediaQuery';
import { buildInfoTooltip, buildSwatchTooltip } from './tooltip';

export async function createMenu(configure: ConfigureUI, wrapper: ConfigureAdidas): Promise<void> {
  // Always create the accordion
  const promises = [createAccordion(configure, wrapper, '.configure-nav-lg', mq.getQuery('greater-than-md'))];

  if (getSectionsSettings(wrapper).includeAll) {
    // If all sections are included, create the pager as well
    promises.push(createPager(configure, '.configure-nav-md', mq.getQuery('lower-than-md')));
  } else {
    // If some sections are not included, skip the pager and create the error message for mobile
    configure.dom.querySelector<HTMLElement>('.configure-desktop-only')!.innerHTML = DesktopOnlyMessage();
  }

  // Wait for all components to be created
  await Promise.all(promises);
}

async function createAccordion(
  configure: ConfigureUI,
  wrapper: ConfigureAdidas,
  container: string,
  mediaQuery?: string
) {
  // Get the nested groups configuration
  const { display: i18n, ignore } = getMenuGroupsConfig(configure, wrapper);

  /*const menu = */ await configure.createComponent({
    mediaQuery,
    type: 'accordion',
    nested: true,
    showGroupName: true,
    menuByIndex: false,
    container,
    uiSettings: {
      selectorLayout: {
        showName: true
      },
      attributeValueTooltip: {
        extended: true,
        content: (av) => buildSwatchTooltip(configure, av),
        position: 'left'
      },
      descriptionTooltip: {
        extended: true,
        content: buildInfoTooltip,
        position: 'left'
      },
      groups: { i18n, ignore }
    }
  });

  /**
   * Handler to fix the scroll when the accordion height gets shorter (https://jira.ontrq.com/browse/ADI-1477)
   */
  configure.on('ca:focus', async ({ caId }) => {
    // Get the panel associated to that CA
    const caPanel = configure.dom.querySelector(`${container} .fc-accordion-panel[data-ca="${caId}"]`);
    // This logic is required only for the accordion (ignore this event on the pager)
    if (!caPanel) return;

    // Get the window scroll position and document height before the accordion height is changed
    const oldScrollY = window.scrollY;
    const oldHeight = document.body.clientHeight;

    // Let the UI update (the accordion height may change)
    await waitForDom();
    if (
      document.body.clientHeight < oldHeight && // the document got shorter
      // oldScrollY > document.body.clientHeight - window.innerWidth && // the old scroll position goes beyond the new max scrollY
      caPanel.getBoundingClientRect().bottom <= 0 // the panel of that CA is above the viewport (not visible)
    ) {
      console.log('[Accordion] New height would make the scrolling position wrong, adjusting');
      // Move the scroll position up by the amount the document height was reduced
      window.scrollTo({ top: oldScrollY - (oldHeight - document.body.clientHeight) });
    }
  });

  // open ugc editor handler
  // handleOpenUgcEditor(menu);
}

/**
 * Generates the HTML for the Desktop only error message
 */
function DesktopOnlyMessage(): string {
  return `
    <div class="configure-product-message">
      <div class="fc-product-message-avatar">
      ${svg}
      </div>
      <span class="fc-product-message-text">${t('err_desktop_only')}</span>
    </div>
  `;
}
