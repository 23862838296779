import { hideTooltip, showTooltip } from '@/components/tooltip';
import { ConfigureUI } from '@/configure/ConfigureUI';
import { ConfigureAttribute, getSelectedValue } from '@/configure/model/ConfigureAttribute';
import { clearArray } from '@/configure/utils/array';
import { interpolate } from '@/configure/utils/text';
import { Callback } from '@/configure/utils/types';
import { I18nKeys, t } from '@/i18n';
import { isEnterOrSpace } from '@/utils/accessibility';
import { filterByProperty } from '@/utils/array';
import { createEventRemovalFn } from '@/utils/browser';
import { waitForDom } from '@/utils/dom';
import { isFalseAV } from '../boolean-avs';
import { resetAttributes } from '../custom-reset-ca';
import { ValueAssociationManager } from './ValueAssociationManager';

/** Key of the Metadata entry that specifies the ID of this AV in the association list */
const METADATA_ID_KEY = 'conflict_name';
/** Key of the Metadata entry that specifies the conflicts */
const METADATA_ASSOCIATIONS_KEY = 'conflicts_with';
/** Character used to separate the different AVs in the metadata entry */
const SEPARATOR = '|';

/* Location AV buttons config */
const LOCATION_AV = {
  TOOLTIP_KEY: 'pp_location_av_conflict' as I18nKeys,

  /* CSS Selector for the Button Div */
  SELECTOR: '.fc-button-selector, .fc-attribute-value-swatch',

  /* CSS Selector template to find a specific AV button (and exclude from certain CA) */
  SELECTOR_SPECIFIC_AV_TEMPLATE:
    '.fc-attribute-selector-button:not([data-id="{ca}"]) .fc-button-selector[data-value="{av}"], .fc-attribute-selector-swatch:not([data-id="{ca}"]) .fc-swatch-av-{av}',

  /** Class used to disable the button */
  DISABLED_CLASS: 'fc-adi-location-disabled'
};

/* Group CA panel config */
const GROUP_CA = {
  TOOTIP_KEY: 'pp_location_ca_conflict' as I18nKeys,

  /* The first is for desktop (accordion), the second for mobile (pager) */
  SELECTOR: '.fc-accordion-panel, .fc-pager .fc-ca-fieldset',

  /**
   * CSS Selector template to find a specific CA group panel.
   * The first is for desktop (accordion), the second for mobile (pager)
   */
  PANEL_SELECTOR_TEMPLATE: '.fc-accordion-panel[data-ca="{ca}"], #fc-ca-{ca}-fieldset',

  /** Class used to disable the CA group */
  DISABLED_CLASS: 'fc-accordion-panel-disabled'
};

/**
 * Fn that indicates whether the value is none, using the "Boolean" facet.
 * None has a Boolean value = False
 * */
const IS_NONE = isFalseAV;

/**
 * Implements the "location conflict" feature.
 *
 * This feature allows to disable certain UI elements according to the availability of selectable values:
 * - a) Disables location AVs buttons when they would overlap with others already selected.
 * - b) Disables complete CA group panels (incl all subattributes), when that groups' location has no available locations.
 *
 * An example for (a) would be the team name, logo or player number can be located in the center, but only one at a time.
 * An example for (b) would be when team name has center and lower chest and both are already occupied.
 *
 * Each AV contains a list of other AVs it conflicts with, so that list is processed and when that AV is selected, the other
 * AVs get disabled.
 *
 * The UI is disabled by using a class that reduces opacity and disables pointer interaction.
 *
 * Also, a tooltip is created for each disabled group or button, indicating why it is disabled
 *
 * @param configure ConfigureUI instance
 */
export function implementLocationConflicts(configure: ConfigureUI): void {
  const product = configure.getProduct();
  if (!product) throw new Error('Error while retrieving product');

  /** List of Event Removers that allow to remove the event listeners when not needed anymore */
  const caEventRemovers: Callback[] = [];
  const avEventRemovers: Callback[] = [];

  // Creates the Value association manager
  const manager = new ValueAssociationManager(configure, {
    idMetadataKey: METADATA_ID_KEY,
    associatedValuesMetadataKey: METADATA_ASSOCIATIONS_KEY,
    listSeparator: SEPARATOR
  });

  console.log(
    '[Location Conflict] Association Map (location id) -> [list of conflicting locations]: ',
    manager.associationMap
  );

  // Stores the currently active CA, so we can update its group and buttons on media query change
  let activeCAId: number = -1;

  // List of all disabled groups (ie: toggle CAs with a location subCA, such as team name)
  let disabledGroups: ConfigureAttribute[] = [];

  // List of all location CA Ids
  const locationCAIds = manager.getProductCAsWithAssociations(product).map((ca) => ca.id);

  // Creates a map that associates a CA Id (location) with the Parent CA name (Player name, Team name, Player number, etc)
  // Used to show which CA is disabling a location
  const parentCaMap = buildParentNameMap(locationCAIds);

  // Listen to when the list of selected AVs (with conflicts) is updated
  manager.addEventListener('update:selected', onLocationConflictUpdate);

  // When a CA becomes active in the UI (accordion or pager), the appropiate buttons need to be disabled according
  // to the current state
  configure.on('ca:focus', (data) => onCAFocus(data.caId));

  configure.on('mediaQuery:change', () => {
    // If there's an active CA, update its buttons
    if (activeCAId !== -1) void onCAFocus(activeCAId);
  });

  function buildParentNameMap(caIds: number[]) {
    const result: Record<number, string> = {};

    const cas = caIds.map((caId) => configure.getAttributeOrThrow({ id: caId }));

    // Associate the CA Id with its parent CA's name.
    for (const ca of cas) {
      const parentCA = ca.parentId ? configure.getAttribute({ id: ca.parentId }) : undefined;
      if (parentCA) result[ca.id] = parentCA.name;
    }
    return result;
  }

  /**
   * When a CA is focused on the UI, the DOM is regenerated by React.
   * So we need to wait until it's finished and update the UI to disable the components in conflict:
   * - For desktop, the location buttons of the focused CA may need to be disabled.
   * - For mobile, the pager is fully redrawn with the new CA, so if it's a disabled CA, we have to disable the whole panel.
   * @param caId id of the focused CA
   */
  async function onCAFocus(caId: number): Promise<void> {
    activeCAId = caId;

    // Get the CA
    const ca = configure.getAttribute({ id: caId });
    if (!ca) return;

    // Wait for React to render the UI so we can manipulate the resulting DOM
    await waitForDom();

    // Check if the new active CA requires a UI update for both its group and location AVs
    checkFocusedCA(ca);
    checkFocusedAVs(ca);
  }

  /** Check if the CA is part of the disabled CAs. If it is, a UI update is required */
  function checkFocusedCA(ca: ConfigureAttribute) {
    // This is  required for mobile (pager), where a change in ca:focus re renders the whole panel
    if (disabledGroups.some((dca) => dca.id === ca.id)) {
      console.log('[Location Conflict] Disabled CA focused %s', ca.alias);

      // The event listeners for the focused CAs will be recreated, so remove the ones we already have
      removeEventListeners(caEventRemovers);

      // Update all the UI, not just the selected CA (in desktop view, most the CAs are visible at the same time)
      updateCAsUI();
    }
  }

  /** Check if the location buttons for this CA must be disabled */
  function checkFocusedAVs(ca: ConfigureAttribute) {
    // The event listeners for the location AV buttons will be recreated, so remove the ones we already have
    removeEventListeners(avEventRemovers);

    // From the focused CA, get the list of CAs with conflicts, usually it will be only one (the location), but we allow for more
    const cas = manager.getCAsWithAssociations(ca);

    // For each value in the CAs (eg. the locations)
    for (const ca of cas) {
      for (const av of ca.values) {
        // If the value is not conflicting with the current selections, do nothing
        if (!manager.selectionAssociatedAVs.has(av.id)) continue;

        // The value is conflicting with a selected location, so we need to disable it UNLESS they both belong to the same CA
        // (AVs shouldn't disable other AVs in the same CA)
        // So we must iterate each selected {ca, av} to check
        for (const recipeItem of manager.selectedAttributeValuePairs) {
          // If the CA is the same, do not disable it
          if (recipeItem.ca === ca.id) continue;

          // At this point, the CA is different, so if the value is in conflict with one selected, disable it
          const avWithAssociationId = manager.associationMap[recipeItem.av]?.find(filterByProperty('id', av.id));
          if (avWithAssociationId) {
            updateLocationAVButton(avWithAssociationId, recipeItem.ca);
          }
        }
      }
    }
  }

  /**
   * Handles a change in the selected CA and AVs with conflicts
   */
  async function onLocationConflictUpdate() {
    console.log('[Location Conflict] Selected Locations with conflicts: ', manager.selectedAttributeValuePairs);
    console.log('[Location Conflict] Locations conflicting with current selections: ', manager.selectionAssociatedAVs);

    // Update the list of disabled CAs (CAs which location has no available values, ie. all disabled)
    updateDisabledCAs();

    // All the event listeners will be recreated, so remove the ones we already have
    removeEventListeners(caEventRemovers);
    removeEventListeners(avEventRemovers);

    // We need to wait for the buttons to be created by React
    await waitForDom();

    // Update all the disabled CA panels
    updateCAsUI();

    // Update all the location AV buttons
    void updateButtonsUI();
  }

  /**
   * Update the list of disabled CA groups (eg: team name, player number)
   */
  function updateDisabledCAs() {
    // Filter fn that indicates whether the ca has no available locations
    function hasNoAvailableLocations(ca: ConfigureAttribute) {
      const selectedAV = getSelectedValue(ca);

      // if the CA has a selected location (other than None), it must NOT be disabled
      if (selectedAV && !IS_NONE(selectedAV)) return false;

      // If all the CA's values are disabled (or the None AV), it must be disabled
      return ca.values.every((av) => IS_NONE(av) || manager.selectionAssociatedAVs.has(av.id));
    }

    // Update the list of disabled groups
    disabledGroups = locationCAIds
      .map((caId) => configure.getAttributeOrThrow({ id: caId })) // Get the CA
      .filter(hasNoAvailableLocations) // Retrieve the ones that must be disabled
      .map((ca) => (ca.parentId ? configure.getAttributeOrThrow({ id: ca.parentId }) : ca)); // Get the Parent CA (the toggle)

    // Resets the disabled CAs (to clear all selected/entered values)
    if (disabledGroups.length > 0) resetAttributes(configure, disabledGroups);

    console.log(
      '[Location Conflict] Updated disabled CAs: %s',
      disabledGroups.length === 0 ? 'None' : disabledGroups.map((ca) => ca.alias)
    );
  }

  /**
   * Updates the UI to disable the CAs with no location and show a tooltip to explain to the user.
   * It updates the DOM for both desktop (accordion) and mobile (pager)
   */
  function updateCAsUI() {
    // Clear "disabled" on all panels
    configure.dom
      .querySelectorAll(GROUP_CA.SELECTOR + '.' + GROUP_CA.DISABLED_CLASS)
      .forEach((el) => el.classList.remove(GROUP_CA.DISABLED_CLASS));

    disabledGroups.forEach((ca) => {
      // Find the DOM element for the CA group panel
      const el = configure.dom.querySelector(interpolate(GROUP_CA.PANEL_SELECTOR_TEMPLATE, { ca: ca.id }));
      if (!el) return;

      // Add the disable class
      el.classList.add(GROUP_CA.DISABLED_CLASS);

      // Cache content of the tooltip
      const content = ConflictTooltip(GROUP_CA.TOOTIP_KEY, ca.name);

      // Add tooltip
      addTooltip(el, content, caEventRemovers);
    });
  }

  /**
   * Updates all the buttons using the full recipe. Overwrites the current state.
   *
   * First it removes the "disabled" class and tooltip event listeners from all buttons
   * and then adds it to the ones that should be disabled
   */
  async function updateButtonsUI() {
    // Clear "disabled" on all buttons
    configure.dom
      .querySelectorAll(LOCATION_AV.SELECTOR + '.' + LOCATION_AV.DISABLED_CLASS)
      .forEach((el) => el.classList.remove(LOCATION_AV.DISABLED_CLASS));

    // Iterate the selected {ca,av} pairs and disable all conflicting values (excluding the ones from the same CA)
    manager.selectedAttributeValuePairs.forEach(({ ca, av }) => {
      manager.associationMap[av]?.forEach((value) => updateLocationAVButton(value, ca));
    });
  }

  /**
   * Add the "disabled" class to the buttons that belong to AVs with the specified vendorId,
   * **excluding** the ones from the provided CA.
   *
   * @param avVendorId vendor ID of the AVs to disable
   * @param excludeCAId id of the CA to exclude in the search
   */
  function updateLocationAVButton(av: { id: number; associationId: string }, excludeCAId: number): void {
    if (!av.id) {
      console.warn('No AV Id found for AV with "conflict_name" metadata %s', av.associationId);
      return;
    }

    const selector = interpolate(LOCATION_AV.SELECTOR_SPECIFIC_AV_TEMPLATE, {
      av: av.id,
      ca: excludeCAId.toString()
    });
    const elements = configure.dom.querySelectorAll(selector);

    // Elements not found, probably not created by React yet
    if (elements.length === 0) {
      console.log(
        '[Location Conflict] Tried disabling Location %s, but button was not found on the DOM',
        av.associationId
      );
      return;
    }

    console.log('[Location Conflict] Disabling Location %s (%d found)', av.associationId, elements.length);

    // Cache content of the tooltip
    const content = ConflictTooltip(LOCATION_AV.TOOLTIP_KEY, parentCaMap[excludeCAId] ?? '');

    elements.forEach((el) => {
      // Disable the button by adding the appropiate class
      el.classList.add(LOCATION_AV.DISABLED_CLASS);

      // Add tooltip
      addTooltip(el, content, avEventRemovers);
    });
  }

  function addTooltip(el: Element, content: string, eventRemovers: Callback[]) {
    function onStart({ target }: Event) {
      showTooltip({ target, content });
    }
    function onTouchEnd() {
      void waitForDom().then(hideTooltip);
    }
    function onKeyDown(e: Event) {
      if (!isEnterOrSpace(e)) return;
      e.stopImmediatePropagation();
      showTooltip({ target: e.target, content });
    }

    // Add tooltip on mouseover/touchstart
    ['mouseover', 'touchstart'].forEach((eventName) => {
      el.addEventListener(eventName, onStart);
      eventRemovers.push(createEventRemovalFn(el, eventName, onStart));
    });

    // When the button is disabled, on enter display the tooltip
    el.addEventListener('keydown', onKeyDown);
    eventRemovers.push(createEventRemovalFn(el, 'keydown', onKeyDown));

    // Remove tooltip on mouseleave/touchend (touchend waits for tooltip to be created)
    el.addEventListener('mouseleave', hideTooltip);
    el.addEventListener('touchend', onTouchEnd);

    eventRemovers.push(createEventRemovalFn(el, 'mouseleave', hideTooltip));
    eventRemovers.push(createEventRemovalFn(el, 'touchend', onTouchEnd));
  }

  /**
   * Removes all events listeners and clean the removers array
   */
  function removeEventListeners(eventRemovers: Callback[]) {
    for (const remove of eventRemovers) remove();
    clearArray(eventRemovers);
  }
}

/**
 * HTML for the Conflict tooltip
 */
function ConflictTooltip(i18nKey: I18nKeys, caName: string) {
  return /* html */ `
   <div class="fc-swatch-tooltip-inner">
    <div class="fc-swatch-tooltip-name">
      ${t(i18nKey, { ca: caName })}
    </div>
  </div>
  `;
}
