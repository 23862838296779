import { KeysOfType } from './types';

export function sum<T>(items: T[], prop: keyof T): number {
  return items.reduce((prev, current) => prev + Number(current[prop]), 0);
}

/**
 * Clears an array
 */
export function clearArray(array: unknown[]): void {
  array.splice(0, array.length);
}

/**
 *
 * Tests whether predicate(item) for item and for every children of property in item pass the test implemented by the provided function `predicate`.
 * This methods will be continue executing recursively for every property of nested item.
 *
 * Property must be an array of the same type as item.
 *
 * When mode='any', return true if at least one element pass the test implemented by the provided function predicate.
 * When mode='all', return true whether all elements pass the test implemented by the provided function predicate.
 *
 * @param item Element with a property of type array whose children are of the same type as the element
 * @param property property name of type array whose children are of the same type as the element
 * @param mode Filtering mode: any or all
 * @param predicate Test to be executed for every item recursively
 * @returns boolean
 *
 */
export function evaluateItemRecursively<T>(
  item: T,
  property: KeysOfType<T, unknown[]>,
  mode: 'all' | 'any',
  predicate: (item: T) => boolean
): boolean {
  const subItem = item[property];
  if (!Array.isArray(subItem)) {
    throw new Error(`Property ${property.toString()} must be array.`);
  }
  if (mode === 'all') {
    // For all, when an item is false, break the execution
    if (!predicate(item)) return false;

    // If not have children, and item is true, then return true
    if (!item[property]) return true;

    //Execute predicate for every item
    return subItem.every((item) => evaluateItemRecursively(item, property, mode, predicate));
  }
  if (mode === 'any') {
    // For all, when an item is true, break the execution and return true
    if (predicate(item)) return true;

    // If not have children, and item is false, then return false
    if (!item[property]) return false;

    //Execute predicate for every item
    return subItem.some((item) => evaluateItemRecursively(item, property, mode, predicate));
  }
  throw new Error('Mode values must be "all" or "any".');
}

/**
 *
 * Given an item with a `property` of type array whose children are of the same type as item, return an array of items that passes the predicate.
 *
 * This method is executed recursively over every element of property and its children.
 *
 * @param item Element with a property of type array whose children are of the same type as the element
 * @param property property name of type array whose children are of the same type as the element
 * @param predicate Test to be executed for every item recursively
 * @returns Array of items
 *
 */
export function filterItemRecursively<T>(
  item: T,
  property: KeysOfType<T, unknown[]>,
  predicate: (item: T) => boolean
): T[] {
  const result: T[] = [];
  const subItem = item[property];
  if (!Array.isArray(subItem)) {
    throw new Error(`Property ${property.toString()} must be array.'`);
  }
  // Add this item if predicate return true
  if (predicate(item)) result.push(item);

  if (!subItem) return result;
  // Add the subItems of item recursively when predicate return true
  result.push(...subItem.flatMap((item) => filterItemRecursively(item, property, predicate)));
  return result;
}

/**
 * Return true if array1 contain the same values than array2
 * @param array1 Array of primitive values
 * @param array2 Array of primitive values
 * @returns
 */
export function arraysHaveSameValues(array1: Array<unknown>, array2: Array<unknown>): boolean {
  return array1.length === array2.length && array1.every((el) => array2.includes(el));
}
