import { ConfigureUI } from '@/configure/ConfigureUI';
import { ConfigureEventDispatcher } from '@/ConfigureEventDispatcher';

/**
 * Implements the Analytics by dispatching events
 *
 * @param dispatcher event dispatcher
 * @param configure ConfigureUI Instance
 */
export default function initAnalytics(dispatcher: ConfigureEventDispatcher, configure: ConfigureUI): void {
  configure.on('analytics', (eventName: string, payload: unknown) => {
    dispatcher.dispatchEvent(new CustomEvent('analytics', { detail: { eventName, payload } }));
  });
}
