import { ConfigureAdidas } from '@/ConfigureAdidas';
import { ConfigureUI } from '@/configure/ConfigureUI';
import initAnalytics from './analytics';
import { displayUpchargeOnAttributeValue } from './av-upcharge';
import { addCustomAccordionAttributeHeaders, addCustomAccordionAttributeSelectors } from './custom-attribute-title';
import { addGroupOnSidePanel } from './custom-side-panel';
import { implementImageGallery } from './image-gallery';
import { applyLeagueRules } from './league-rules';
import { addAttributeAndValueMessages, addProductMessage } from './messaging';
import {
  implementAttributeSets,
  implementFontPreview,
  implementLocationConflicts,
  implementTextField,
  implementToggle
} from './personalization';
import { implementSnapshotFeature } from './snapshots/snapshots';
import { fitSwatchesText } from './text-swatches';

/**
 * Initializes the custom features.
 * It performs custom logic, registers event listeners, add elements to the DOM, etc
 * @param configure instance of ConfigureUI
 */
export function initCustomFeatures(wrapper: ConfigureAdidas, configure: ConfigureUI): void {
  // Custom Messaging
  addProductMessage(configure);
  addAttributeAndValueMessages(configure);

  // Attribute Headers
  addCustomAccordionAttributeHeaders(configure);
  addCustomAccordionAttributeSelectors(configure);

  // Side Panel
  addGroupOnSidePanel(configure, wrapper);
  // League Rules (compliance)
  void applyLeagueRules(wrapper, configure);

  // Image Gallery (UGC with external images)
  implementImageGallery(wrapper, configure);

  // Product Personalization
  implementAttributeSets(configure);
  implementToggle(configure);

  //Upcharge
  void displayUpchargeOnAttributeValue(configure);

  implementFontPreview(configure);
  implementLocationConflicts(configure);
  implementTextField(wrapper, configure);

  implementSnapshotFeature(configure);

  // custom "Get link" button to be able to share (more actions menu). Not Used. Adidas uses its own button
  // appendGetLinkToShareMenu(configure);

  // Adds the analytics event
  initAnalytics(wrapper, configure);

  // Reduces the text in square swatches (eg. Location) to make it fit
  fitSwatchesText(configure);
}
