import { ConfigureUI } from '@/configure/ConfigureUI';
import { AttributeValue } from '@/configure/model/AttributeValue';
import { ConfigureAttribute, isColorAttribute, isPatternAttribute } from '@/configure/model/ConfigureAttribute';
import { tDynamic } from '@/i18n';
import internetips from 'internetips';

interface TooltipOpts {
  target: EventTarget | null;
  content: string;
  classes?: string[];
}

function tc(suffix?: string) {
  const cls = 'fc-tooltip';
  return suffix ? `${cls}-${suffix}` : cls;
}

// Apply ConfigureUI config to internetips
// Will execute on initial load and work for all the ConfigureUI instances
internetips.setConfig({
  containerClass: tc('container'),
  tooltipClass: tc(),
  activeClass: tc('show'),
  placeClass: {
    top: tc('place-top'),
    right: tc('place-right'),
    bottom: tc('place-bottom'),
    left: tc('place-left')
  },
  typeClass: {
    dark: tc('type-dark'),
    light: tc('type-light')
  },
  defaults: {
    offsetX: 8,
    offsetY: 10
  }
});

export function showTooltip(opts: TooltipOpts): void {
  internetips.show({
    place: 'left',
    effect: 'solid',
    type: 'dark',
    ...opts
  });
}

export function hideTooltip(): void {
  internetips.hide();
}

/**
 * TODO: The second parameter is actually an AV
 * Generate custom content for attribute value tooltip
 * @param {object} ca Configurable attribute
 * @returns {string}
 */
export function buildSwatchTooltip(configure: ConfigureUI, ca: ConfigureAttribute): string {
  let tooltipContent = '<div class="fc-swatch-tooltip-inner">';

  if (isColorAttribute(ca)) {
    tooltipContent += `<div class="fc-swatch-tooltip-image" style="background-color: ${ca.color}"></div>`;
  } else if (isPatternAttribute(ca)) {
    tooltipContent += `<div class="fc-swatch-tooltip-image" style="background-image: url(${ca.tooltipImage})"></div>`;
  }
  tooltipContent += `<div class="fc-swatch-tooltip-name">${ca.name}</div>`;

  if (ca.upcharge)
    tooltipContent += `<div class="fc-swatch-tooltip-upcharge">${configure.formatPrice(ca.upcharge)}</div>`;
  if (ca.description) tooltipContent += `<div class="fc-swatch-tooltip-description">${ca.description}</div>`;

  return tooltipContent + '</div>';
}

/**
 * Tooltips shown on the information icon hover
 */
export function buildInfoTooltip(ca: ConfigureAttribute): string {
  // when attribute values have tooltip (stored in desc to workaround), concatenate its values, and return those
  const aVsTooltip = ca.values
    ?.filter((value) => Boolean(value.description))
    .map((value) => `<u>${value.name}</u> - ${getText(value.description, value)}`)
    .join('<br><br>');

  if (aVsTooltip?.length > 0) return aVsTooltip;

  // else, when the ca has a tooltip itself, check if it's a referenced value and return it
  return getText(ca.tooltip, ca);
}

/**
 * If value starts with `$`, returns the localized text using that value as key (without the $).
 * If value is `undefined`, returns '';
 * Otherwise, returns the value as it is.
 */
function getText(value: string | undefined, context: ConfigureAttribute | AttributeValue): string {
  const tooltip = value ?? '';
  return tooltip.startsWith('$') ? tDynamic(tooltip.substring(1), context) : tooltip;
}
