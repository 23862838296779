import { WithMetadata } from './Metadata';
import { ValueUsage } from './ValueUsage';

export interface AttributeValue extends WithMetadata {
  id: number;
  ns: string;
  name: string;
  vendorId: string;
  description: string;
  priority: number;
  active: boolean;
  color: string;
  vertexKey: string;
  upcharge: number;
  clipArt?: string;

  selected: boolean;

  // features/league-rules
  url?: string;
  selectable: boolean;
  valueUsage?: ValueUsage;

  facets?: Record<number, number[]>;
}

export interface TextAttributeValue {
  text: string;
}

export function isTextValue(av: AttributeValue | TextAttributeValue): av is TextAttributeValue {
  return (av as TextAttributeValue).text !== undefined;
}

/**
 * Determines whether the AV has the provided Facet with the specified value.
 */
export function hasFacetValue(av: AttributeValue, facetId: number, facetValueId: number): boolean {
  if (!av.facets) return false;
  return av.facets[facetId]?.includes(facetValueId) ?? false;
}
