import { showErrorDialog } from '@/components/dialog';
import { ConfigureUI } from '@/configure/ConfigureUI';
import { caHasValue, ConfigureAttribute, getSelectedValue } from '@/configure/model/ConfigureAttribute';
import { VALIDATION } from '@/constants';
import { t, tDynamic } from '@/i18n';
import { isTrueAV } from './boolean-avs';
import { isToggleCA } from './personalization';

export class ValidationError extends Error {
  constructor(public items: string[] = []) {
    super('There are validation errors');
  }
}

/**
 * Validates the current recipe.
 *
 * The recipe won't be valid if any of the text or image toggles are active but the
 * the user didn't complete the required CAs (location, text, image, size, font, etc)
 *
 * @returns a Validation Error with ALL the errors or `undefined` if there are no errors
 */
export function validateRecipe(configure: ConfigureUI): ValidationError | undefined {
  const product = configure.getProduct();
  if (!product) return undefined;

  // Find the active (ON) toggles
  const activeToggles = product.attributes.filter((ca) => {
    if (!isToggleCA(ca)) return false;
    const selectedValue = getSelectedValue(ca);
    return selectedValue && isTrueAV(selectedValue);
  });

  // Validate each toggle. For each toggle, an array with the errors is generated,
  // so flatMap creates a single array
  const errors = activeToggles.flatMap(validateToggleGroup);

  // Return the errors or undefined
  return errors.length > 0 ? new ValidationError(errors) : undefined;
}

/**
 * Shows a ConfigureUI Dialog with the validation errors specified
 */
export function showValidationDialog(configure: ConfigureUI, error: ValidationError): void {
  void showErrorDialog(configure, t('validation_error_dialog_title'), buildDialog(error));
}

function validateToggleGroup(toggleCA: ConfigureAttribute): string[] {
  if (!toggleCA.subAttributes) return [];
  const errors = [];
  for (const sca of toggleCA.subAttributes) {
    // Check if validation should ignore this CA
    const ignored = VALIDATION.IGNORED_CA_SUFFIXES.some((suffix) => sca.alias.endsWith(suffix));

    // Only user editable CAs are validated
    if (!ignored && sca.canBeRendered && !caHasValue(sca)) {
      const tPayload = { group: toggleCA.name, ca: sca.name };
      // Use the specific message for the CA. If not found, use the generic message
      const msg =
        tDynamic(`validation_error_missing_${getCASuffix(sca)}`, tPayload) ??
        t('validation_error_missing_generic', tPayload);

      errors.push(msg);
    }
  }
  return errors;
}

/**
 * Gets the subattribute name from the full CA Alias.
 *
 * (eg. p13n.team_name_1.size -> size)
 */
function getCASuffix(ca: ConfigureAttribute) {
  const index = ca.alias.lastIndexOf('.');
  if (index < 0) {
    console.warn('CA %s alias does not follow the naming convention', ca.alias);
    return undefined;
  }
  return ca.alias.substring(index + 1);
}

/** Generates the Validation dialog HTML */
function buildDialog(error: ValidationError) {
  return /* html */ `
  <div class="fc-adi-validation-dialog">
    <p class="fc-adi-validation-dialog-description">${t('validation_error_dialog_description')}</p>
    <ul>
      ${error.items.map((item) => `<li>${item}</li>`).join('\n')}
    </ul>
  </div>
  `;
}
