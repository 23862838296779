import { ConfigureUI } from '@/configure/ConfigureUI';

const ENABLE_ACCESSIBILITY = 'enable-accessibility';
/**
 * Enable keyboard accessibility navigation when tab key is press.
 *
 * Disable the keyboard accessibility after pointer event happens.
 *
 * @param configure
 */
export async function enableAccessibility(configure: ConfigureUI): Promise<void> {
  // Add event listener on keyup
  document.addEventListener('keyup', enable);

  configure.on('destroy:start', () => {
    document.removeEventListener('keyup', enable);
    document.removeEventListener('pointerdown', disable);
  });

  document.addEventListener('pointerdown', disable);
}

function enable(e: KeyboardEvent) {
  const body = document.body;
  if (e.key === 'Tab' && !body.classList.contains(ENABLE_ACCESSIBILITY)) {
    body.classList.add(ENABLE_ACCESSIBILITY);
  }
}

function disable() {
  document.body.classList.remove(ENABLE_ACCESSIBILITY);
}
