import { evaluateItemRecursively, filterItemRecursively } from '@/configure/utils/array';
import { filterByTruthy } from '@/utils/array';
import { AVRecipeValue } from '@/configure/types/configureui-types';
import { AttributeValue, TextAttributeValue } from './AttributeValue';
import { ValueUsage } from './ValueUsage';
import { isFalseAV } from '@/features/boolean-avs';

export interface ConfigureAttribute {
  id: number;
  name: string;
  alias: string;
  description?: string;
  value?: AttributeValue;
  values: AttributeValue[];
  selectorType: 'checkbox' | 'text' | 'buttongroup' | 'swatch' | 'ugc' | 'none';
  canBeRendered: boolean;
  allValues: unknown[];
  facets: unknown[];
  allFacets: unknown[];
  optionalFacets: unknown[];
  hiddenFacets: unknown[];
  upcharge?: number;
  subAttributes?: ConfigureAttribute[];
  isVisible: boolean;
  isDynamic: boolean;
  isPersonal: boolean;
  indexable: boolean;
  valueUsages: ValueUsage[];
  parentId?: number;
  selectorClass?: string;
  maxLength?: number;
  tooltip?: string;
}

export interface ColorConfigureAttribute extends ConfigureAttribute {
  selectorType: 'swatch';
  color?: string;
}
export interface PatternConfigureAttribute extends ConfigureAttribute {
  selectorType: 'swatch';
  tooltipImage?: string;
}
export interface TextConfigureAttribute extends Omit<ConfigureAttribute, 'value'> {
  offsetY?: number;
  offsetX?: number;
  value: TextAttributeValue;
  maxWidth?: number;
  dynamicMaterialResolverScriptURL?: string;
}
export interface UgcConfigureAttribute extends ConfigureAttribute {
  selectorType: 'ugc';
  tooltipImage?: string;
  ugcImageDensity: number;
  ugcImageHeight: number;
  ugcImagePrintHeight: number;
  ugcImagePrintWidth: number;
  ugcImageWidth: number;
}

/** Indicates whether the attribute is a Color Attribute */
export function isColorAttribute(ca: ConfigureAttribute): ca is ColorConfigureAttribute {
  return (ca as ColorConfigureAttribute).color !== undefined;
}

/** Indicates whether the attribute is a Pattern Attribute */
export function isPatternAttribute(ca: ConfigureAttribute): ca is PatternConfigureAttribute {
  return (ca as PatternConfigureAttribute).tooltipImage !== undefined;
}
/** Indicates whether the attribute is a Text Attribute */
export function isTextAttribute(ca: ConfigureAttribute | TextConfigureAttribute): ca is TextConfigureAttribute {
  return typeof (ca as TextConfigureAttribute).value?.text === 'string';
}

export function isUgcAttribute(ca: ConfigureAttribute | UgcConfigureAttribute): ca is UgcConfigureAttribute {
  return ca.selectorType === 'ugc';
}

/**
 * Retrieves the current selected AV for the specified CA.
 *
 * If no value is selected, `undefined` is returned.
 * It also allows for the ca param to be undefined for convenience, in that case `undefined` is returned.
 */
export function getSelectedValue(ca: ConfigureAttribute | undefined): AttributeValue | undefined {
  return ca?.values?.find(filterByTruthy('selected'));
}

/**
 * Checks if the provided CA has a value (aka it's not empty).
 *
 * It supports text, UGC and regular CAs.
 * It also checks that the selected AV doesn't have the `False`/`None` facet
 *
 * @param ca ca to check
 * @returns `true` if the CA has a value, `false` otherwise.
 */
export function caHasValue(ca: ConfigureAttribute): boolean {
  // If it's a text CA and the value contains text
  if (isTextAttribute(ca) && ca.value.text.trim().length > 0) return true;

  // If it's a UGC CA and the value contains the clipArt
  if (isUgcAttribute(ca) && ca.value?.clipArt && ca.value.clipArt.trim().length > 0) return true;

  const av = getSelectedValue(ca);

  // If it has a selected value and that value is not False or None
  return av !== undefined && !isFalseAV(av);
}

/**
 * Retrieves the default AV for the specified CA.
 *
 * - For Text CAs, it returns an `{text: ''}`.
 * - For any other, it returns the **first** AV in its `values` array that is "selectable"
 * - If the values array is empty or no value is selectable, it returns `null`.
 *
 * @param ca Attribute which default AV is required
 * @returns the CA's default value or `null` if not found
 */
export function getDefaultValueForCA(ca: ConfigureAttribute): AttributeValue | { text: string } | null {
  if (ca.selectorType === 'text') return { text: '' };
  if (!ca.values || ca.values.length === 0) return null;
  return ca.values.find((av) => av.selectable) ?? null;
}

/**
 *
 * Tests whether predicate(ca) for ca and for every children of subAttributes in ca pass the test implemented by the provided function `predicate`.
 * This methods will be continue executing recursively for every property of nested item.
 *
 *
 * When mode='any', return true if at least one ca pass the test implemented by the provided function predicate.
 * When mode='all', return true whether all ca pass the test implemented by the provided function predicate.
 *
 * @param ca ConfigureAttribute instance
 * @param mode Filtering mode: any or all
 * @param predicate Test to be executed for ca and dor every subAttribute recursively
 * @returns boolean
 *
 */
export function evaluateCARecursively(
  ca: ConfigureAttribute,
  predicate: (ca: ConfigureAttribute) => boolean,
  mode: 'all' | 'any'
): boolean {
  return evaluateItemRecursively(ca, 'subAttributes', mode, predicate);
}

/**
 *
 * Given an ConfigureAttribute with Sub Attributes, return an array of ConfigureAttribute that passes the predicate.
 *
 * This method is executed recursively over every Sub Attributes and its children.
 *
 * @param ca ConfigureAttribute instance
 * @param predicate Test to be executed for every CA recursively
 * @returns Array of items
 *
 */
export function filterCARecursively(
  ca: ConfigureAttribute,
  predicate: (ca: ConfigureAttribute) => boolean
): ConfigureAttribute[] {
  return filterItemRecursively(ca, 'subAttributes', predicate);
}

/**
 * Generates the items that can be passed to `setRecipe` to reset the value of the provided CA and its subattributes recursively
 * @param ca CA to reset, with its subCAs
 */
export function getInitialRecipeItemsForCA(ca: ConfigureAttribute): Array<[string, AVRecipeValue]> {
  const result: Array<[string, AVRecipeValue]> = [];

  // Get the items for the subattributes recursively
  for (const sca of ca.subAttributes ?? []) {
    result.push(...getInitialRecipeItemsForCA(sca));
  }
  // If this CA has a default attribute, add it to the result
  const defaultValue = getDefaultValueForCA(ca);
  if (defaultValue !== null) result.push([ca.alias, (defaultValue as AttributeValue).name ?? defaultValue]);

  return result;
}
