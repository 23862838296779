import { ConfigureAdidas } from '@/ConfigureAdidas';
import { ConfigureAdidasEvents } from '@/ConfigureEventDispatcher';
import loader from '@/components/loader';
import { ConfigureUI } from '@/configure/ConfigureUI';
import { ConfigureAttribute, isUgcAttribute } from '@/configure/model/ConfigureAttribute';
import { t } from '@/i18n';
import { waitForDom } from '@/utils/dom';
import { capitalizeText } from '@/utils/general';
import { AdidasImagePayload } from '.';
import { ImageGalleryResizeHelpers } from './image-gallery-size';
import { AdidasImagePayloadForUI } from './image-gallery-types';

/** CSS Classes */
const CONTAINER_CLASS = 'fc-ugc-custom-html';
const UPLOAD_BTN_CLASS = 'fc-custom-upload-ugc';
const CHANGE_IMAGE_CLASS = 'fc-ugc-change-image';
const REMOVE_IMAGE_CLASS = 'fc-ugc-remove-image';

export function implementImageGalleryUI(
  wrapper: ConfigureAdidas,
  configure: ConfigureUI,
  adidasPayloads: Map<string, AdidasImagePayload>,
  { getDimensionsForCA, getAlignmentForCA }: ImageGalleryResizeHelpers
): void {
  // Adds the hook to render the custom UI in the accordion
  configure.registerHook('component.attributeSelector.afterHtml', renderImageGalleryUI(wrapper));

  // When the UGC or its parent is focused, re render to prevent an old image to be shown
  // Without this, sometimes using a snapshot that overwrites the UGC Value will show the old one.
  configure.on('ca:focus', ({ caId: id }) => {
    // If the CA focused is or contains a UGC CA, re render the panel
    const focusedUGC = getUgcFromCA(configure.getAttributeOrThrow({ id }));
    if (focusedUGC) void updateGalleryForCA(wrapper, configure, focusedUGC.alias, adidasPayloads.get(focusedUGC.alias));
  });

  // Upload Button Event listener
  configure.dom.addEventListenerByData(`.${UPLOAD_BTN_CLASS}`, 'click', 'ca', function (e, caAlias) {
    e.preventDefault();
    wrapper.dispatchEvent(createCustomEvent('image-upload', caAlias));
  });

  // Image Library Button Event listener
  configure.dom.addEventListenerByData(`.${CHANGE_IMAGE_CLASS}`, 'click', 'ca', function (e, caAlias) {
    e.preventDefault();
    wrapper.dispatchEvent(createCustomEvent('image-library', caAlias));
  });

  // Remove button Event listener
  configure.dom.addEventListenerByData(`.${REMOVE_IMAGE_CLASS}`, 'click', 'ca', async function (e, alias) {
    e.preventDefault();
    await configure.removeUgcImage({ ca: { alias } });
    adidasPayloads.delete(alias);

    // When the UI was modified manually, "remove" won't update the UI, so we do it manually again
    void updateGalleryForCA(wrapper, configure, alias, adidasPayloads.get(alias));
  });

  function createCustomEvent(eventName: ConfigureAdidasEvents, caAlias?: string): CustomEvent {
    const ca = configure.getAttributeOrThrow({ alias: caAlias });
    return new CustomEvent(eventName, {
      detail: {
        caAlias,
        ...getDimensionsForCA(ca),
        ...getAlignmentForCA(ca)
      }
    });
  }

  /**
   * Given a root CA, go through the hierarchy and return the first UGC CA found.
   * This UGC may be the root CA itself or any descendant.
   */
  function getUgcFromCA(rootCA: ConfigureAttribute): ConfigureAttribute | undefined {
    // If it's the root CA, return it
    if (isUgcAttribute(rootCA)) return rootCA;

    // If no subattributes, we're done with this branch
    if (!rootCA.subAttributes) return undefined;

    // Iterate the subattribute and call this fn recursively
    for (const sca of rootCA.subAttributes) {
      const ugc = getUgcFromCA(sca);
      if (ugc) return ugc;
    }
    return undefined;
  }

  /**
   * ConfigureUI Hook.
   *
   * Only renders custom HTML if the CA is UGC
   */
  function renderImageGalleryUI(wrapper: ConfigureAdidas): (ca: ConfigureAttribute) => string | undefined {
    return (ca: ConfigureAttribute) => (isUgcAttribute(ca) ? ImageGallery(wrapper, ca) : undefined);
  }

  /**
   * Generates the Image Gallery Custom UI
   * @param ca Configure Attribute
   * @returns the HTML to display
   */
  function ImageGallery(wrapper: ConfigureAdidas, ca: ConfigureAttribute): string {
    return /* html */ `
    <div class="${CONTAINER_CLASS}" data-ca="${ca.alias}">
      ${ImageGalleryContent(adidasPayloads.get(ca.alias), wrapper.params.imageLibraryEnabled!)}
    </div>
  `;
  }
}

/**
 * Updates the Image Gallery UI.
 *
 * Required because "recipe:loaded" is sometimes executed after the hook, so we need to render it again after
 * "adidasPayloads" was loaded.
 * @param configure ConfigureUI instance
 * @param caAlias alias of the Attribute
 * @param adidasPayload payload that contains the filename and thumbnail URL
 */
export async function updateGalleryForCA(
  wrapper: ConfigureAdidas,
  configure: ConfigureUI,
  caAlias: string,
  adidasPayload: AdidasImagePayloadForUI | undefined
): Promise<void> {
  const ca = configure.getAttribute({ alias: caAlias });
  if (!ca) return;

  // Wait for the UI to update so we can find the container
  await waitForDom();
  const container = configure.dom.querySelector(`.${CONTAINER_CLASS}[data-ca='${caAlias}']`);
  if (!container) return;

  container.innerHTML = ImageGalleryContent(adidasPayload, wrapper.params.imageLibraryEnabled!);
}

/**
 * Generates the Image Gallery Custom UI except the main container. Useful when updating the DOM manually
 * @param adidasPayload payload that contains the filename and thumbnail URL
 * @param enableLibrary indicates if the image library feature is enabled
 * @returns the HTML to display
 */
function ImageGalleryContent(adidasPayload: AdidasImagePayloadForUI | undefined, enableLibrary: boolean): string {
  const hasPayload = !!adidasPayload;
  // Retrieve loading flag
  const loading = adidasPayload?.loading ?? false;

  // If a Payload is specified, show the image thumbnail and info, even while loading it as UGC in the product
  return /* html */ `
    <div class="fc-ugc-content${loading ? ' loading' : ''}">
      ${hasPayload ? ChangeImageSection() : ImageGallerySection()}
      ${hasPayload ? UploadedImageSection(adidasPayload) : UploadImageSection()}
    </div>
  `;

  // - - - - - - - - Components of ImageGalleryContent

  // When the Image is not loaded
  function ImageGallerySection() {
    if (!enableLibrary) return '';
    return /* html */ `
    <p>
      ${t('ig_previously')}
    </p>
    <button class="${CHANGE_IMAGE_CLASS} fc-outline-target" tabindex="0">
      ${t('ig_my_image_gallery')}
    </button>
  `;
  }

  function UploadImageSection() {
    // Don't show the label if only "upload" is available
    return /* html */ `
    <p>
      ${enableLibrary ? t('ig_rules') : ''}
    </p>
    <p>
      <a href="#" class="${UPLOAD_BTN_CLASS} fc-outline-target" tabindex="0">
        ${capitalizeText(t('ig_upload_image'))}
      </a>
    </p>
  `;
  }

  function ChangeImageSection() {
    // Remove the "change image from library" button when library is disabled
    const libraryBtn = enableLibrary
      ? /* html */ `
    <a class="${CHANGE_IMAGE_CLASS} fc-outline-target" tabindex="0" href="#">
      ${t('ig_different_image')}
    </a>`
      : '';

    return /* html */ `
    ${libraryBtn}
    ${UploadImageSection()}
  `;
  }

  function UploadedImageSection(adidasPayload: AdidasImagePayloadForUI) {
    return /* html */ `
    <div class="fc-ugc-uploaded-image">
      <p class="fc-ugc-image-title">${t('ig_selected_image')}</p>
      <div class="fc-ugc-uploaded-image-container">
        <img src="${adidasPayload.thumbnailUrl}" />
        ${loader.Small}
      </div>
      <div class="fc-ugc-image-name">
        <p class="fc-ugc-image-name-main-name">MAIN NAME</p>
        <p class="fc-ugc-image-name-filename">${adidasPayload.fileName}</p>
        <p class="fc-ugc-image-name-edit-link">
          <a href="#" class="${REMOVE_IMAGE_CLASS} fc-outline-target" tabindex="0">${t('ig_remove_image')}</a>
        </p>
      </div>
    </div>
  `;
  }
}
