/**
 * Given a number, calculates the next (nearest higher) power of two.
 * Eg. 5 -> 8
 *     250 -> 256
 *     260 -> 512
 *     700 -> 1024
 */
export function nextPowerOfTwo(n: number): number {
  let power = 1;
  while (power < n) power *= 2;

  return power;
}
