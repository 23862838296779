import { ConfigureEventMap, ConfigureUIEvent } from '.';
import { DisplayWebGlEventBus } from '../components/webgl';
import { ConfigureUI } from '../ConfigureUI';

/**
 * Class responsible for registering event handlers.
 *
 * It just forwards the request to configureUI API.
 *
 * The reason this class exists is to keep an array of eventName-handler, so they can be destroyed
 * during clean up by calling `off()` on each.
 */
export class EventManager {
  #configure: ConfigureUI;

  /** List of event names and its handler. Used to cleanup */
  #handlers: Array<{ eventName: ConfigureUIEvent; handler: unknown }> = [];

  constructor(configure: ConfigureUI) {
    this.#configure = configure;

    void this.addEventAliases();
  }

  on(eventName: ConfigureUIEvent, handler: unknown): void {
    // Add the event handler to the list
    this.#handlers.push({ eventName, handler });
    // Forward to Configure API
    this.#configure.getApi().on(eventName, handler);
  }
  once(eventName: ConfigureUIEvent, handler: unknown): void {
    // Add the event handler to the list
    this.#handlers.push({ eventName, handler });

    // Forward to Configure API
    this.#configure.getApi().once(eventName, handler);
  }
  off(eventName: ConfigureUIEvent, handler?: unknown): void {
    // Forward to Configure API
    this.#configure.getApi().off(eventName, handler);

    // Removed the handler from the list, as its already off
    this.#handlers = this.#handlers.filter((h) => h.eventName !== eventName || h.handler !== handler);
  }

  trigger<T extends ConfigureUIEvent>(eventName: T, ...args: Parameters<ConfigureEventMap[T]>): void {
    this.#configure.getApi().trigger(eventName, ...args);
  }

  private async addEventAliases() {
    await this.#configure.ready('api');
    // Add dom:ready event as alias of analytics:configureLoaded
    this.once('analytics:configureLoaded', () => this.trigger('dom:ready'));

    // Add destroy start as alias of "destroy"
    this.once('destroy', () => this.trigger('destroy:start'));
  }

  /**
   * Adds load, ready and destroy events, using the webGL display events.
   * @param display
   */
  addDisplayLifecycleEvents(display: DisplayWebGlEventBus): void {
    // Add model loading and ready
    display.on('display:webgl:load-progress', (progress) => {
      this.trigger('model:loading', progress);
      if (progress === 100) this.trigger('model:ready');
    });

    // Add WebGL ready
    display.once('display:webgl:webgl:initialized', () => this.trigger('webgl:ready'));

    // Add display ready
    display.once('display:webgl:customization:rendered', () => this.trigger('display:ready'));

    // Add destroy finish
    display.once('display:webgl:destroy', () => this.trigger('destroy:finish'));
  }

  /**
   * Removes all the registered event listeners
   */
  destroy(): void {
    for (const { eventName, handler } of this.#handlers) {
      this.off(eventName, handler);
    }
    console.log('[EventManager] Destroyed');
  }
}
